<template>
    <div class=" pt-3 pl-5 pr-5 overflow-x-hidden">
        <div class="mb-2">
            <breadCrumb :routesArray="routesArray"></breadCrumb>
        </div>
        <div class="row flex justify-between w-full">
            <h1 class="title">Risk - Mitigate</h1>
        </div>
        <div class="mt-5 bg-white border border-lightgrey ">
            <div class="w-full p-5 pb-0">
                <label class="controllabel" v-if="systemHead">Systems</label>
                <label class="controllabel" v-if="processHead">Process</label>
                <label class="controllabel" v-if="organizationHead">Organization</label>
                
                <div class="text-xs flex flex-wrap">
                    <div v-for="obj,index in systemsSelected" :key="index" class="mr-2 mb-2 bg-primary text-white px-2 py-1 rounded">
                        {{obj.label}}
                    </div>
                </div>
            </div>
            <div class="border-b border-solid border-lightgrey mx-5 mt-3 mb-2"></div>
            <div class="row flex justify-between items-center mx-5">
                <div class="text-base font-semibold">Mapping of Controls to Mitigate</div>
                <div class="buttonposition flex justify-end items-center">
                    <button :class="sameControls ? 'btnprimary opacity-100' : 'btndisabled pointer-events-none'" class="mr-1.5" @click="addToTable">Add</button>
                    <button class="filterButton flex" @click="filterOpen('box')">Filters<img src="@/assets/filter.svg" class="h-3 ml-2.5" /></button>
                </div>
            </div>
            <div class="border-b border-solid border-lightgrey mx-5 mt-2 mb-2"></div>
           
            <div class="flex flex-col px-5" :class="{ 'pb-5': sortedData.length == 0 }">
                <div class="row rounded flex text-xs pt-2">
                    <div class="w-2/5">
                        <div class="flex" @click="sort('framework')">
                            <h2 class="header cursor-pointer">Framework</h2>
                            <div class="sort cursor-pointer" :class="className == 'framework' ? classSort : 'opacity-50'"></div>
                        </div>
                        <div class="mt-3 border border-lightgrey option overflow-y-auto mr-1 rounded">
                            <div v-if="showBox" class="p-2">
                                <input type="text" class="inputboxstyling" v-on:keyup="filteredFunction($event.target.value, 'framework')" placeholder="Search Frameworks" />
                            </div>
                            <div v-for="(framework, index) in sortedFrameworkData" :key="index" class="rounded" ref="framework">
                                <div class="p-3 pl-5 cursor-pointer" :class="[framework == systemIndex ? 'bg-primary text-white font-bold' : 'bg-white hover:bg-lightgrey',index==0?'rounded-t':'']" @click="systemIndex = framework">
                                    <p class="text-xs">{{ framework }}</p>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="w-3/5">
                        <div class="flex" @click="sort('controls')">
                            <div class="flex">
                                <h2 class="header cursor-pointer">Controls</h2>
                                <div class="sort cursor-pointer" :class="className == 'controls' ? classSort : 'opacity-50'"></div>
                            </div>
                        </div>

                        <div class="w-full pt-0">
                            <div class="mt-3 border flex-col text-sm border-lightgrey option overflow-y-auto ml-1 rounded" ref="control">
                                <div v-if="showBox" class="p-2">
                                    <input type="text" class="inputboxstyling" v-on:keyup="filteredFunction($event.target.value, 'controls')" placeholder="Search Controls" />
                                </div>
                                
                                <div v-for="(control, key, index) in sortedControlData" :key="index" class="mt-2">
                                    <div v-if="key == systemIndex">
                                        <div class="h-7 flex justify-normal items-center cursor-pointer pl-5 hover:bg-lightgrey" v-for="ctrl in control" v-bind:key="ctrl._id">
                                            <div class="flex items-center whitespace-nowrap">
                                                <input v-model="checkedChild" :value="ctrl._id" :id="ctrl._id" type="checkbox" class="checkbox" @change="chooseControls(ctrl._id)" ref="child" />
                                                <label :for="ctrl._id" class="pl-3 cursor-pointer">{{ ctrl.controlTitle }}</label>
                                            </div>
                                            <div class="h-7 w-full" @click="childSelect(ctrl._id)"></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div v-if="v$.checkedChild.$error" class="text-red-500">
                                <div class="error-text text-xs" v-if="v$.checkedChild.required.$invalid">Required</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div v-if="tableRow.length != 0">
                <div class="border-b border-solid border-lightgrey mx-5 mt-3 mb-2"></div>
                <div class="row flex justify-between items-center">
                    <div class="mx-5 text-base font-semibold">Selected Controls</div>

                    <button class="mr-5 filterButton flex" @click="filterOpen('table')">
                        Filters
                        <img src="@/assets/filter.svg" class="h-3 ml-2.5" />
                    </button>
                </div>
                <div class="mx-5 pb-5">
                    <newTable :tableRowData="tableRow" :headersData="headers"></newTable>
                    <div v-if="toolTipActive">
                        <tool-tip :tipText="toolTipText"></tool-tip>
                    </div>
                </div>
                
            </div>
        </div>
    </div>
    <div class="fixed bottom-7 bg-white h-16 flex justify-end items-center" :class="{ 'w-40': isSidebarToggle, 'w-10': !isSidebarToggle }" v-if="tableRow.length > 0">
        <div class="buttonposition flex justify-end items-center px-5 mx-5">
            <button class="btnprimary mr-1.5" :class="existedControls ? 'btnprimary' : 'btndisabled pointer-events-none'" @click="rollBack">Cancel</button>
            <button :class="existedControls ? 'btnprimary' : 'btndisabled pointer-events-none'" @click="saveSystemsControls">Next</button>
        </div>
    </div>
</template>
<style scoped>
.card2:hover {
    background: #e9e9e9;
}
.option {
    height: 200px;
}
.rotate-icon {
    transition: transform 0.6s;
    transform: rotate(180deg);
}
.multiselect-tag {
    font-size: 12px;
    font-weight: 600;
}
::-webkit-scrollbar {
    width: 2px;
}
.multiselect-tag.is-disabled {
    background-color: #059669 !important;
}
.multiselect.is-disabled {
    background-color: white !important;
}
.w-40 {
    width: calc(100% - 170px);
}
.w-10 {
    width: calc(100% - 2.5rem);
}
</style>

<script lang="ts">
import { defineComponent } from 'vue';
import { mapGetters } from 'vuex';
import _ from 'lodash';
import { toast } from '@/main';
import { required, maxLength, minLength, email, helpers, requiredIf } from '@vuelidate/validators';
import useVuelidate from '@vuelidate/core';

import toolTip from '@/components/toolTip.vue';
import Multiselect from 'vue-multiselect';
import pagination from '@/components/pagination.vue';
import { emitter } from '@/main';
import breadCrumb from '@/components/breadCrumb.vue';
import newTable from '@/components/newTable.vue';

export default defineComponent({
    data(): any {
        return {
            allControlsForSystem: [],
            controls: [],
            riskDataObj:[],
            risks: [],
            selectedControls: [],
            checkedChild: [],
            dataBaseCheckedChild: [],
            filters: {},
            entitiesSelect: [],
            allSystems:[],
            businessProcessList: [],
            framework: [],
            dummyFramework: [],
            selectedSystems: [],
            systemIndex: '',
            systemId: '',
            systems: [],
            organizationHead: false,
            processHead: false,
            systemHead: false,

            pop: false,
            tipText: '',
            tipColumn: '',
            tipIndex: '',
            styling: { left: '', bottom: '' },

            finalSystemsNControls: [],
            dataBaseFinalSystemsNControls: [],
            sameControls: false,
            existedControls: false,
            objectToBeSaved: [],

            currentPage: '',
            pageSize: '',
            dynamicPagination: '',

            frameworkSort: 'name',
            frameworkSortDir: 'asc',

            controlSort: 'name',
            controlSortDir: 'asc',

            classSort: '',
            className: '',

            toolTipActive : false,
            toolTipText : '',

            currentSort: 'name',
            currentSortDir: 'asc',
            systemsSelected:[],
            showFilters: false,
            showBox: false,
            dummyControls: [],
            objToBeModified: {},
            tableRow: [],
            headers: [ 'framework', 'title','deleteIcon' ],
            tableRowControlIds: [],
            isSidebarToggle: true,
            cancelState: false,
            v$: useVuelidate(),
            riskData: {
                business: {},
                operations: {},
                legal: {},
                financial: {},
                likelihood: {}
            },
            routesArray:[],
            tasksData:[],
            dummyData:[],
        };
    },
    validations() {
        return {
            riskData: {
                business: { required },
                operations: { required },
                legal: { required },
                financial: { required },
                likelihood: { required }
            },
            checkedChild: { required }
        };
    },
    components: {
        Multiselect,
        toolTip,
        // pagination,
        breadCrumb,
        newTable
    },
    async created() {
        await this.getSystems();
        await this.getBusinessProcess();
        await this.getBusinessEntities();
        await this.getControls();
        await this.getRiskDataList();
        await this.getAllTasks()

        if(this.$route.path.includes('risks')){
            this.routesArray = [{name:'Risks',routeName:'risks'},{name:'Risk-Mitigate',routeName:'riskMitigate'}];
        }else if(Object.keys(this.$route.query).includes('Risks')){
            this.routesArray = [{name:'Risks',routeName:'risks'},{ name:'Tier-Risks',routeName:'systemRisk', id:this.riskDataObj.mappedRisk },{name:'Risk-Mitigate',routeName:'riskMitigate'}];
        }else if(Object.keys(this.$route.query).includes('systems')){
            this.routesArray = [
                { name: 'Systems', routeName: 'systems' },
                { name: 'System-Information', routeName: 'systemInformation', id: this.systemsSelected[0].value},
                {name:'Risk-Mitigate',routeName:'riskMitigate'}
            ];
        }else if(Object.keys(this.$route.query).includes('process')){
            this.routesArray = [
                { name: 'Business Process', routeName: 'businessProcess' },
                { name: 'Process-Information', routeName: 'processInformation', id: `${ this.systemsSelected[0].value }` },
                {name:'Risk-Mitigate',routeName:'riskMitigate'}
            ];
        } else if(Object.keys(this.$route.query).includes('organization')){
            this.routesArray = [
                { name: 'Organization', routeName: 'organization' },
                { name: 'Organization-Information', routeName: 'worklogFromOrganization', id: `${ this.systemsSelected[0].value }` },
                {name:'Risk-Mitigate',routeName:'riskMitigate'}
            ];
        }
        
        await this.displayDefault();
        
    },
    mounted(){
        
        emitter.on('toggle-sidebar', (isToggle: any) => {
			this.isSidebarToggle = isToggle;
		});

        emitter.off('activiteToolTip');
        emitter.on('activiteToolTip', (data: any) => {
            this.toolTipActive = true;
            this.toolTipText = data.tipText
        });
 
        emitter.off('deActiviteToolTip');
        emitter.on('deActiviteToolTip', (data: any) => {
            this.toolTipActive = false;
            this.toolTipText = ''
        });

        emitter.off('forDelete');
        emitter.on('forDelete', (isData: any) => {
            let comingId = isData.entity.id;
            this.onDelete(isData.index, comingId)
            
        });
    },
    methods: {
        paginationUpdations(currentPage: any, pageSize: any) {
            this.currentPage = currentPage;
            this.pageSize = pageSize;
        },
        chooseControls() {
            if (this.finalSystemsNControls.length == 0) {
                console.log("this.selectedSystems",this.selectedSystems)
                this.selectedSystems.map((item: any) => {
                    let obj:any;
                    if(Object.keys(this.$route.query).includes('organization')){
                        obj = { org: [item], controls: this.checkedChild.sort() }
                    }else if(Object.keys(this.$route.query).includes('process')){
                        obj = { process: [item], controls: this.checkedChild.sort() }
                        console.log("obj",obj)
                    }else if(Object.keys(this.$route.query).includes('systems') ){
                        obj = { systems: [item], controls: this.checkedChild.sort() }
                    }
                     
                    this.finalSystemsNControls.push(obj);
                });
            } else {
                
                this.finalSystemsNControls.map((item: any) => {

                    if(Object.keys(item).includes('orgId')){
                        item.org = [item.orgId];
                        delete item.orgId;

                    }else if(Object.keys(item).includes('processId') && !Object.keys(item).includes('process')){
                        item.process = [item.processId];
                        delete item.processId;

                    }else if(Object.keys(item).includes('systemId') && !Object.keys(item).includes('systems')){
                        item.systems = [item.systemId];
                        delete item.systemId;

                    }

                    item.controls = this.checkedChild.sort();
                    
                });
                
            }
            this.disableButtons();
        },
        clearSelectedControls() {
            this.framework.find((item: any) => {
                this.controls[item] = this.controls[item].filter((item: any) => {
                    return !this.checkedChild.includes(item._id);
                });
            });
        },

        disableButtons() {
            if(this.checkedChild.length == 0 || (_.isEqual(this.checkedChild.sort(), this.dataBaseCheckedChild.sort()) && _.isEqual(this.checkedChild.sort(), this.tableRowControlIds.sort()))){
                this.cancelState = false;
                this.sameControls = false
            }else{
                this.sameControls = true;
                this.cancelState = true;
            }
            
            let disableCondition = ( this.sameControls == true && _.isEqual(this.checkedChild.sort(), this.tableRowControlIds.sort()) ) || !_.isEqual(this.tableRowControlIds.sort(), this.dataBaseCheckedChild.sort())
            disableCondition ? (this.existedControls = true) : (this.existedControls = false);
           
        },
        addToTable() {
            var switchControls: any = {};

            this.dataBaseCheckedChild.length != 0 ? (switchControls = this.allControlsForSystem) : (switchControls = this.dummyControls);

            this.tableRow = [];
            this.tableRowControlIds = [];

            this.framework.find((item: any) => {
                switchControls[item].map((itemOne: any) => {
                    if (this.checkedChild.includes(itemOne._id)) {
                        this.tableRowControlIds.push(itemOne._id);
                        this.tableRow.push({ framework: item,  title: itemOne.controlTitle, id: itemOne._id });
                    }
                });
            });
            this.clearSelectedControls();
            this.disableButtons();

        },
         async getRiskDataList() {
            await this.$http.get(`${process.env.VUE_APP_RISK_API_URL}/api/root/risk/${this.$route.params.riskId}/get`).then((result: any) => {
                this.riskDataObj = result.data.find((risk:any)=>{
                    if (Object.keys(risk).includes('systemId')) {
                        return risk.systemId == this.systemsSelected[0].value
                    } else if (Object.keys(risk).includes('processId')) {
                        console.log("this.systemsSelected[0].value",this.systemsSelected)
                        return risk.processId == this.systemsSelected[0].value
                    } else if ( Object.keys(risk).includes('orgId')) {
                        return risk.orgId == this.systemsSelected[0].value
                    }
                })
            });
        },
        async getSystems() {
            let entityId = '20005';
            await this.$http.get(`${process.env.VUE_APP_ORG_API_URL}/api/org/getall/${entityId}`).then((result: any) => {
                this.allSystems = result.data.map((sys: any) => {
                    return { value: sys._id, label: sys.systemName };
                });
            });
        },
        async getBusinessProcess() {
            await this.$http.get(`${process.env.VUE_APP_ORG_API_URL}/api/org/getall/${20006}`).then((result: any) => {
                this.businessProcessList = result.data.map((sys: any) => {
                    return { value: sys._id, label: sys.businessProcessName };
                });
            });
        },
        async getBusinessEntities(){
            await this.$http.get(`${process.env.VUE_APP_ORG_API_URL}/api/org/businessEntities/getall`).then((result: any) => {
                this.entitiesSelect = result.data.map((org: any) => {
                    return { value: org._id, label: org.entityName };
                });
            })
        },
        cancelSelectionState() {
            this.finalSystemsNControls = [...this.dataBaseFinalSystemsNControls];
            this.controls = { ...this.dummyControls };
            this.checkedChild = [...this.dataBaseCheckedChild];
            this.chooseControls();
            this.addToTable();
            this.systemIndex = this.sortedFrameworkData[0];
        },
        rollBack() {
            this.tableRow = [];
            this.cancelSelectionState();
            localStorage.removeItem('MITIGATE_DATA')
        },
        async getAllTasks() {
            await this.$http.get(`${process.env.VUE_APP_RISK_API_URL}/api/org/tasks/getall`).then((result: any) => {
                this.tasksData = result.data;
            });
        },
        onDelete(index: any,ctrlId:any) {
            let details:any
            let presentTaskIdObj:any
            let taskStatusPresent:any

            if(this.dataBaseFinalSystemsNControls.length >0){
                details = this.dataBaseFinalSystemsNControls[0].tasks
                presentTaskIdObj = (details.find((obj:any)=>{
                        return obj.controlId == ctrlId;
                }))
                taskStatusPresent = 0;
                if(presentTaskIdObj){
                    let presentTask = this.tasksData.find((task:any)=>{return task._id == presentTaskIdObj.taskId})
                    
                    taskStatusPresent = presentTask.statusCode;
                }
            }
            
            if(presentTaskIdObj == undefined || taskStatusPresent == 10403 || this.dataBaseFinalSystemsNControls.length==0){
                var switchControls: any = {};
                this.dataBaseCheckedChild.length != 0 ? (switchControls = this.allControlsForSystem) : (switchControls = this.dummyControls);
                
                switchControls[this.tableRow[index].framework].map((item: any) => {
                    if (this.tableRow[index].id == item._id) {
                        this.checkedChild = this.checkedChild.filter((itemOne: any) => {
                            return itemOne != item._id;
                        });
                        this.controls[this.tableRow[index].framework].push(item);
                    }
                });

                this.tableRowControlIds = [...this.checkedChild];

                this.tableRow.splice(index, 1);
                this.tableRow.length == 0 ? (this.disableButtons(), (this.systemIndex = this.framework[0])) : '';
                this.chooseControls();
            }else{
                toast.error(`Respective Task is in pending`, {
						timeout: 1000,
						closeOnClick: true,
						closeButton: 'button',
						icon: true
					});
            }
            
        },
        toolTip(type: any, e: any, key:any, index:any, row:any) {
            if (type == 'text') {
                this.handleTextTooltip(e, key, index, row);
            }
        },
        handleTextTooltip(e: any,key:any, index:any, row:any) {
            const container = e.currentTarget.closest('.ellipsis-container');
            if (container && this.isTextOverflowed(container)) {
                this.pop = true;
                this.tipText = row[key];
                this.tipIndex = index;
                this.tipColumn = key;
                this.styling.left = e.offsetX + 38 + 'px';
                this.styling.bottom = -45 + 'px';
            } else {
                this.pop = false;
            }
        },
        isTextOverflowed(element: any) {
            return element.scrollWidth > element.clientWidth;
        },
        doFalse() {
            this.pop = false;
        },
        childSelect(ctrl: any) {
            this.checkedChild.includes(ctrl)
                ? (this.checkedChild = this.checkedChild.filter((item: any) => {
                      return item !== ctrl;
                  }))
                : this.checkedChild.push(ctrl);
                this.chooseControls()
        },
        async updateRisk(risk: any) {
            try {
                let mappedriskId = risk.mappedRisk;
                delete risk._id;
                await this.$http.post(`${process.env.VUE_APP_RISK_API_URL}/api/root/${mappedriskId}/update`, risk).then((result: any) => {
                    toast.info(`Uploaded succesfully`, {
                        closeOnClick: true,
                        closeButton: 'button'
                    });
                });
            } catch (e) {
                toast.error('error', {
                    timeout: 1000,
                    closeOnClick: true,
                    closeButton: 'button',
                    icon: true
                });
            }
        },
        async saveSystemsControls() {

            this.dataBaseFinalSystemsNControls = [...this.finalSystemsNControls];
            this.sameControls = false;
            this.existedControls = false;
            this.dataBaseCheckedChild = [...this.checkedChild];
            
            this.finalSystemsNControls.map((obj:any)=>{
                obj._id ? delete obj._id :'';
                obj.riskId ? delete obj.riskId:'';
                obj.tasks ? delete obj.tasks : ''

            })

            let payload;
            if(this.dummyData.length == 0){
                payload = [
                        { 
                            mappedControls: this.finalSystemsNControls, 
                            action: 10405, 
                            riskId: this.$route.params.riskId, 
                            createdAt: new Date(),
                            createdBy: this.userInfo._id,
                            previousStatus: this.riskDataObj.statusCode
                        }
                    ];
            }else{
                let OldControls:any = this.dummyData[0].controls;
                let newControls:any = this.finalSystemsNControls[0].controls;
                
                let a = newControls.filter((num:any)=>{return !(OldControls.includes(num))});
                this.finalSystemsNControls[0].controls = a;
                payload = [
                    
                        { 
                            mappedControls: this.finalSystemsNControls, 
                            action: 10405, 
                            riskId: this.$route.params.riskId, 
                            createdAt: new Date(),
                            createdBy: this.userInfo._id,
                            previousStatus: this.riskDataObj.statusCode
                        }
                    ];
            }
            // console.log("payloadddddddddddddddddddddd", payload)
            console.log("this.systemsSelected",this.systemsSelected)
            localStorage.setItem('MITIGATE_DATA', JSON.stringify(payload));
            console.log("this.$routeeeee",this.$route)


            if((Object.keys(this.$route.query).includes('systems') && Object.keys(this.$route.query).includes('Risks')) || (this.$route.path.includes('/ControlsMap/risks') && Object.keys(this.$route.query).includes('systems'))){          
                this.$router.push({ name: 'residualRisk', params:{riskId:this.$route.params.riskId, type:'system', id:this.selectedSystems[0]}, query:{systems:true,risks:true}});
            }else if(Object.keys(this.$route.query).includes('process') && Object.keys(this.$route.query).includes('Risks')  || (this.$route.path.includes('/ControlsMap/risks') && Object.keys(this.$route.query).includes('process'))){          
                this.$router.push({ name: 'residualRisk', params:{riskId:this.$route.params.riskId, type:'process', id:this.selectedSystems[0]}, query:{process:true,risks:true}});
            }else if(Object.keys(this.$route.query).includes('organization') && Object.keys(this.$route.query).includes('Risks')  || (this.$route.path.includes('/ControlsMap/risks') && Object.keys(this.$route.query).includes('organization'))){          
                this.$router.push({ name: 'residualRisk', params:{riskId:this.$route.params.riskId, type:'organization', id:this.selectedSystems[0]}, query:{organization:true,risks:true}});
            }else if(Object.keys(this.$route.query).includes('systems')){
                this.$router.push({ name: 'residualRisk', params:{riskId:this.$route.params.riskId, type:'system', id:this.selectedSystems[0]}, query:{systems:true}});
            }else if(Object.keys(this.$route.query).includes('process')){
                this.$router.push({ name: 'residualRisk', params:{riskId:this.$route.params.riskId, type:'process', id:this.systemsSelected[0].value}, query:{process:true}});
            }else if(Object.keys(this.$route.query).includes('organization')){
                this.$router.push({ name: 'residualRisk', params:{riskId:this.$route.params.riskId, type:'organization', id:this.selectedSystems[0]}, query:{organization:true}});
            }
            
        },
        async displayDefault() {
            this.dummyData = [];
            await this.$http.get(`${process.env.VUE_APP_RISK_API_URL}/api/org/systemControls/mapping/getall`).then((result: any) => {
                this.dataBaseFinalSystemsNControls = JSON.parse(JSON.stringify(result.data));
                // console.log('mongoData', this.dataBaseFinalSystemsNControls);
            });

            const savedMitigateSystems:any = localStorage.getItem(this.$route.params.riskId);
            // console.log("savedMitigateSystems", savedMitigateSystems)

            if (this.dataBaseFinalSystemsNControls.length != 0) {
                // console.log("Ifffffffffff")
                this.dataBaseFinalSystemsNControls = this.dataBaseFinalSystemsNControls.filter((item: any) => {
                    if (Object.keys(this.$route.query).includes('systems') && item.riskId == this.$route.params.riskId && savedMitigateSystems.includes(item.systemId)) {
                        this.selectedSystems.push(item.systemId);
                        return true;
                    } else if (Object.keys(this.$route.query).includes('process') && item.riskId == this.$route.params.riskId && savedMitigateSystems.includes(item.processId)) {
                        this.selectedSystems.push(item.processId);
                        return true;
                    } else if (Object.keys(this.$route.query).includes('organization') && item.riskId == this.$route.params.riskId && savedMitigateSystems.includes(item.orgId)) {
                        this.selectedSystems.push(item.orgId);
                        return true;
                    }
                });

                // console.log('this.dataBaseFinalSystemsNControls',this.dataBaseFinalSystemsNControls)

                //followed
                if(this.selectedSystems.length !== 0){
                    // console.log('hjjhjhjhjhjhjh',this.dataBaseFinalSystemsNControls)
                    this.finalSystemsNControls = [...this.dataBaseFinalSystemsNControls];

                    // this.dummyData = [...this.dataBaseFinalSystemsNControls];
                    this.dataBaseFinalSystemsNControls.map((obj:any)=>{this.dummyData.push({...obj})})

                    this.dataBaseCheckedChild = this.dataBaseFinalSystemsNControls[0].controls;
                    this.checkedChild = [...this.dataBaseCheckedChild];
                    this.addToTable();
                    this.dummyControls = { ...this.controls };
                }    
                else{
                    this.selectedSystems = this.systems.map((item:any)=>{return item.value});
                }
            }
            else{
                console.log("esssssssss")
                this.selectedSystems = this.systems.map((item:any)=>{return item.value});
            }
                this.systemIndex = this.sortedFrameworkData[0];
        },
        async getControls() {
        const savedMitigateSystems = localStorage.getItem(this.$route.params.riskId);
            console.log("savedMitigateSystems", savedMitigateSystems)
            if(savedMitigateSystems){
                let localdata = JSON.parse(savedMitigateSystems)
                localdata.map((dataId:any)=>{
                    if(this.$route.query.systems == 'true'){
                        let systemFindObj = this.allSystems.find((system:any)=>{return system.value == dataId});
                        this.systemHead = true;
                        this.systemsSelected.push(systemFindObj);
                    }else if(this.$route.query.process == 'true'){
                        let businessProcessFindObj = this.businessProcessList.find((process:any)=>{return process.value == dataId});
                        this.processHead = true;
                        console.log("this.businessProcessList", this.businessProcessList);
                        console.log("businessProcessFindObj", businessProcessFindObj);
                        console.log("dataId", dataId)
                        this.systemsSelected.push(businessProcessFindObj);
                    }else if(this.$route.query.organization == 'true'){
                        let tenantsFindObj = this.entitiesSelect.find((entity:any)=>{return entity.value == dataId});
                        this.organizationHead = true;
                        this.systemsSelected.push(tenantsFindObj);
                    }
                    
                })
            }
            this.systems = this.systemsSelected;
            await this.$http
                .get(`${process.env.VUE_APP_CONTROLS_API_URL}/api/org/allControls`)
                .then((result: { data: any }) => {

                    let internalControls:any = [];
                    let  remainingControls:any = [];
                    let orgControls:any = [];

                    result.data.map((obj:any)=>{
                        if(Object.keys(obj).includes('clientUniqueId') && Object.keys(obj).includes('linkedControlID')){
                            if(obj.clientUniqueId != ''){
                                orgControls.push(obj);
                            }
                        }else if((Object.keys(obj).includes('INT_ONLY') && obj['INT_ONLY'] == "Y" && !Object.keys(obj).includes('clientUniqueId')) || (Object.keys(obj).includes('isInternalControl') && obj['isInternalControl'] == "true" && !Object.keys(obj).includes('clientUniqueId'))){
                            internalControls.push(obj);
                        } else{
                            remainingControls.push(obj);
                        }
                    })

                    this.controls = this.groupBy(remainingControls,'controlFrameWork');

                    if(internalControls.length > 0){
                        this.controls['INTERNAL CONTROLS'] = internalControls;
                    }

                    if(orgControls.length > 0){
                        this.controls['ACTIVE CONTROLS'] = orgControls;
                    }

                    this.dummyControls = {...this.controls}
                    this.allControlsForSystem = {...this.dummyControls} ;

                    // console.log('HIHIHIHIHI',this.controls)
                    this.framework = Object.keys(this.controls);
                    this.dummyFramework = [...this.framework];
                })
                .catch((error: any) => {
                    toast.error(` ${error}`, {
                        timeout: 1000,
                        closeOnClick: true,
                        closeButton: 'button',
                        icon: true
                    });
                });
        },
        groupBy(arr: any, keyName: any) {
            const grouped = arr.reduce((group: any, product: any) => {
                const keyValue = product[keyName];
                    group[keyValue] = group[keyValue] ?? [];
                    group[keyValue].push(product);
                
                return group;
            }, {});

            if (grouped['undefined']) {
                grouped['custom'] = grouped['undefined'];
                delete grouped['undefined'];
            }

            if (grouped['']) {
                grouped['custom'] = [...grouped['']];
                delete grouped[''];
            }

            return grouped;
        },
        filterOpen(status: any) {
            if (status == 'table') {
                this.showFilters = !this.showFilters;
                emitter.emit('visible', this.showFilters);
            } else if (status == 'box') {
                this.showBox = !this.showBox;
                if (this.showBox == false) {
                    this.filters = {};
                    this.framework = [...this.dummyFramework];
                    this.systemIndex = this.framework[0];
                }
            }
        },
        sort: function (name: any, status: any) {
            if (status == 'table') {
                this.className = name;
                if (name === this.currentSort) {
                    this.currentSortDir = this.currentSortDir === 'asc' ? 'desc' : 'asc';
                }
                this.currentSort = name;
                this.classSort = this.currentSortDir === 'asc' ? 'sort_asc' : 'sort_desc';
            } else {
                if (name == 'framework') {
                    this.className = name;
                    if (name === this.frameworkSort) {
                        this.frameworkSortDir = this.frameworkSortDir === 'asc' ? 'desc' : 'asc';
                    }
                    this.frameworkSort = name;
                    this.classSort = this.frameworkSortDir === 'asc' ? 'sort_asc' : 'sort_desc';
                } else if (name == 'controls') {
                    this.className = name;
                    if (name === this.controlSort) {
                        this.controlSortDir = this.controlSortDir === 'asc' ? 'desc' : 'asc';
                    }
                    this.controlSort = name;
                    this.classSort = this.controlSortDir === 'asc' ? 'sort_asc' : 'sort_desc';
                }
            }
        },
        filteredFunction(filterColumn: any, columnName: any) {
            if (columnName == 'framework') {
                this.framework = this.dummyFramework.filter((item: any) => {
                    return item.toLowerCase().includes(filterColumn.toLowerCase());
                });
                this.framework.length > 0 ? (this.systemIndex = this.framework[0]) : (this.systemIndex = -1);
            } else if (columnName == 'controls') {
                Object.keys(this.dummyControls).forEach((item) => {
                    const filteredControls = this.dummyControls[item].filter((itemOne: any) => {
                        return itemOne.controlTitle.toLowerCase().includes(filterColumn.toLowerCase());
                    });
                    this.controls[item] = filteredControls;
                });
            }
        }
    },
    computed: {
        ...mapGetters({ userInfo: 'userInfo' }),
        sortedData: function (): any {
            return [...this.tableRow]
                .filter((row: any) => {
                    for (const key in this.filters) {
                        const inputValue = this.filters[key];
                        if (inputValue !== '') {
                            if (!row[key].toLowerCase().includes(inputValue.toLowerCase())) {
                                return false;
                            }
                        }
                    }
                    return true;
                })
                .sort((a, b) => {
                    let modifier = 1;
                    if (this.currentSortDir === 'desc') modifier = -1;
                    if (a[this.currentSort] < b[this.currentSort]) return -1 * modifier;
                    if (a[this.currentSort] > b[this.currentSort]) return 1 * modifier;
                    return 0;
                })
                .filter((row, index) => {
                    this.dynamicPagination = index + 1;
                    let start = (this.currentPage - 1) * this.pageSize;
                    let end = this.currentPage * this.pageSize;
                    if (index >= start && index < end) return true;
                });
        },
        sortedFrameworkData: function (): any {
            return [...this.framework].sort((a, b) => {
                let modifier = 1;
                if (this.frameworkSortDir === 'desc') modifier = -1;
                if (a < b) return -1 * modifier;
                if (a > b) return 1 * modifier;
                return 0;
            });
        },
        sortedControlData: function (): any {
            var sortedControls: any = {};
            for (const key in this.controls) {
                if (this.controls[key]) {
                    var subArray = [...this.controls[key]];
                    subArray.sort((a, b) => {
                        const modifier = this.controlSortDir === 'desc' ? -1 : 1;
                        const ctrlNoA = a.controlTitle.toLowerCase();
                        const ctrlNoB = b.controlTitle.toLowerCase();
                        if (ctrlNoA < ctrlNoB) return -1 * modifier;
                        if (ctrlNoA > ctrlNoB) return 1 * modifier;
                        return 0;
                    });
                    sortedControls[key] = subArray;
                }
            }
            return sortedControls;
        }
    }
});
</script>
       
