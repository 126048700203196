<template>
    <div id="body-container">
        <div>
            <NavBar />
            <div class="flex" @click="closeApps()">
                <div :class="{ 'w-40': isSidebarToggle, 'w-10': !isSidebarToggle }">
                    <div class="fixed bg-primary sidemenubarScroll sidenav flex flex-col justify-between h-full z-50" :class="{ 'w-40': isSidebarToggle, 'w-10': !isSidebarToggle }">
                        <ul class="sideLabels">
                            <li @click="navigate('information', 'information')" class="cursor-pointer h-8 hover:bg-white svg-icon px-2.5" 
                            @mouseover="displayToolTipMethod('information', 'Information')"
                            @mouseout="hideToolTip()" 
                            :class="selectedOptionName === 'information' ? 'bg-white text-black font-bold a svg-icon123' : 'text-lightgrey font-normal'">
                                <div>
                                    <a class="flex navchild items-center hover:text-black py-1">
                                        <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <g id="Information">
                                            <g id="Group">
                                            <path id="Vector" d="M12.3359 1.14453V4.44563H15.637L12.3359 1.14453Z" fill="#E9E9E9"/>
                                            <path id="Vector_2" d="M12.0898 4.9375C11.9538 4.9375 11.8438 4.8275 11.8438 4.69141V1H2.98438C2.44026 1 2 1.44026 2 1.98438V15.7656C2 16.3097 2.44026 16.75 2.98438 16.75H14.7969C15.341 16.75 15.7812 16.3097 15.7812 15.7656V4.9375H12.0898ZM4.70703 5.42969H8.64453C8.78062 5.42969 8.89062 5.53969 8.89062 5.67578C8.89062 5.81187 8.78062 5.92188 8.64453 5.92188H4.70703C4.57094 5.92188 4.46094 5.81187 4.46094 5.67578C4.46094 5.53969 4.57094 5.42969 4.70703 5.42969ZM13.0742 13.5508H4.70703C4.57094 13.5508 4.46094 13.4408 4.46094 13.3047C4.46094 13.1686 4.57094 13.0586 4.70703 13.0586H13.0742C13.2103 13.0586 13.3203 13.1686 13.3203 13.3047C13.3203 13.4408 13.2103 13.5508 13.0742 13.5508ZM13.0742 12.0742H4.70703C4.57094 12.0742 4.46094 11.9642 4.46094 11.8281C4.46094 11.692 4.57094 11.582 4.70703 11.582H13.0742C13.2103 11.582 13.3203 11.692 13.3203 11.8281C13.3203 11.9642 13.2103 12.0742 13.0742 12.0742ZM13.0742 10.5977H4.70703C4.57094 10.5977 4.46094 10.4877 4.46094 10.3516C4.46094 10.2155 4.57094 10.1055 4.70703 10.1055H13.0742C13.2103 10.1055 13.3203 10.2155 13.3203 10.3516C13.3203 10.4877 13.2103 10.5977 13.0742 10.5977ZM13.0742 9.12109H4.70703C4.57094 9.12109 4.46094 9.01109 4.46094 8.875C4.46094 8.73891 4.57094 8.62891 4.70703 8.62891H13.0742C13.2103 8.62891 13.3203 8.73891 13.3203 8.875C13.3203 9.01109 13.2103 9.12109 13.0742 9.12109ZM13.0742 7.64453H4.70703C4.57094 7.64453 4.46094 7.53453 4.46094 7.39844C4.46094 7.26235 4.57094 7.15234 4.70703 7.15234H13.0742C13.2103 7.15234 13.3203 7.26235 13.3203 7.39844C13.3203 7.53453 13.2103 7.64453 13.0742 7.64453Z" fill="#E9E9E9"/>
                                            </g>
                                            </g>
                                            </svg>
                                        <div class="item-name leading-5 text-sm pl-2" :class="selectedOptionName == 'information' ? 'text-black font-bold' : 'text-listIconColor font-normal'" v-if="isSidebarToggle">Information</div>
                                    </a>
                                </div>
                            </li>

                            <li @click="navigate('tvmapping', 'tvmapping')" 
                            @mouseover="displayToolTipMethod('riskMaster', 'RiskMaster')"
                            @mouseout="hideToolTip()" 
                            class="cursor-pointer h-8 hover:bg-white svg-icon px-2.5" :class="selectedOptionName === 'tvmapping' ? 'bg-white text-black font-bold a svg-icon123' : 'text-lightgrey font-normal'">
                                <div>
                                    <a class="flex navchild items-center hover:text-black py-1">
                                        <svg width="16" height="15" viewBox="0 0 16 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M15.526 12.0468L9.51056 0.967068C9.3513 0.674458 9.1161 0.4302 8.8297 0.260011C8.54331 0.0898225 8.21633 0 7.88319 0C7.55005 0 7.22307 0.0898225 6.93668 0.260011C6.65028 0.4302 6.41508 0.674458 6.25582 0.967068L0.234237 12.0468C0.0766292 12.3297 -0.0040786 12.6489 0.000158647 12.9728C0.00439589 13.2966 0.0934301 13.6136 0.258387 13.8923C0.423343 14.171 0.65846 14.4015 0.940302 14.561C1.22214 14.7205 1.54086 14.8033 1.8647 14.8013H13.8955C14.2167 14.8016 14.5324 14.7184 14.8117 14.5598C15.0911 14.4013 15.3244 14.1729 15.4888 13.897C15.6532 13.6211 15.7431 13.3072 15.7496 12.9861C15.7561 12.6649 15.6791 12.3476 15.526 12.0653V12.0468ZM6.95988 4.14152C6.95988 3.89583 7.05748 3.66019 7.23122 3.48646C7.40495 3.31273 7.64058 3.21513 7.88628 3.21513C8.13197 3.21513 8.36761 3.31273 8.54134 3.48646C8.71507 3.66019 8.81267 3.89583 8.81267 4.14152V8.39677C8.81267 8.64247 8.71507 8.8781 8.54134 9.05184C8.36761 9.22557 8.13197 9.32317 7.88628 9.32317C7.64058 9.32317 7.40495 9.22557 7.23122 9.05184C7.05748 8.8781 6.95988 8.64247 6.95988 8.39677V4.14152ZM7.91098 12.4853C7.70088 12.4853 7.49551 12.423 7.32082 12.3062C7.14613 12.1895 7.00997 12.0236 6.92957 11.8295C6.84917 11.6354 6.82814 11.4218 6.86912 11.2158C6.91011 11.0097 7.01128 10.8204 7.15984 10.6719C7.30841 10.5233 7.49768 10.4221 7.70374 10.3811C7.9098 10.3402 8.12339 10.3612 8.31749 10.4416C8.5116 10.522 8.6775 10.6581 8.79423 10.8328C8.91095 11.0075 8.97325 11.2129 8.97325 11.423C8.97325 11.7047 8.86133 11.9749 8.66212 12.1741C8.4629 12.3734 8.19271 12.4853 7.91098 12.4853Z" fill="#E9E9E9"/>
                                        </svg>
                                        <div class="item-name leading-5 text-sm pl-2" :class="selectedOptionName == 'tvmapping' ? 'text-black font-bold' : 'text-listIconColor font-normal'" v-if="isSidebarToggle">Risk Master</div>
                                    </a>
                                </div>
                            </li>

                            <li @click="navigate('threat', 'threat')"
                             @mouseover="displayToolTipMethod('threat', 'Threat')"
                            @mouseout="hideToolTip()" 
                             class="cursor-pointer h-8 hover:bg-white svg-icon px-2.5" :class="selectedOptionName === 'threat' ? 'bg-white text-black font-bold a svg-icon123' : 'text-lightgrey font-normal'">
                                <div>
                                    <a class="flex navchild items-center hover:text-black py-1">
                                        <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <g id="Threat" clip-path="url(#clip0_2395_30652)">
                                            <g id="SVGRepo_iconCarrier">
                                            <path id="Vector" d="M15.0057 3.81591C13.9242 3.52726 12.8676 3.15219 11.8461 2.69432C10.8412 2.25838 9.87111 1.74621 8.94432 1.16227L8.68182 1L8.42409 1.16705C7.4973 1.75098 6.5272 2.26316 5.52227 2.69909C4.49914 3.15557 3.44096 3.52905 2.35795 3.81591L2 3.90659V7.88705C2 14.2777 8.4575 16.6689 8.51955 16.6927L8.68182 16.75L8.84409 16.6927C8.91091 16.6927 15.3636 14.2825 15.3636 7.88705V3.90659L15.0057 3.81591ZM12.8245 6.98977L7.49341 12.1682L4.47705 9.14705C4.35046 9.02046 4.27935 8.84878 4.27935 8.66977C4.27935 8.49076 4.35046 8.31908 4.47705 8.1925C4.60363 8.06592 4.77531 7.99481 4.95432 7.99481C5.13333 7.99481 5.30501 8.06592 5.43159 8.1925L7.50773 10.3068L11.8939 6.01136C11.9565 5.94869 12.0309 5.89897 12.1128 5.86505C12.1947 5.83113 12.2825 5.81367 12.3711 5.81367C12.4598 5.81367 12.5475 5.83113 12.6294 5.86505C12.7113 5.89897 12.7857 5.94869 12.8484 6.01136C12.9111 6.07404 12.9608 6.14845 12.9947 6.23034C13.0286 6.31223 13.0461 6.4 13.0461 6.48864C13.0461 6.57727 13.0286 6.66504 12.9947 6.74693C12.9608 6.82882 12.9111 6.90323 12.8484 6.96591L12.8245 6.98977Z" fill="#E9E9E9"/>
                                            </g>
                                            </g>
                                            <defs>
                                            <clipPath id="clip0_2395_30652">
                                            <rect width="18" height="18" fill="white"/>
                                            </clipPath>
                                            </defs>
                                            </svg>
                                        <div class="item-name leading-5 text-sm pl-2" :class="selectedOptionName == 'threat' ? 'text-black font-bold' : 'text-listIconColor font-normal'" v-if="isSidebarToggle">Threat</div>
                                    </a>
                                </div>
                            </li>

                            <li @click="navigate('vulnerabilities', 'vulnerabilities')"
                                @mouseover="displayToolTipMethod('vulnerabilities', 'Vulnerabilities')"
                                @mouseout="hideToolTip()"
                                class="cursor-pointer h-8 hover:bg-white svg-icon px-2.5" :class="selectedOptionName === 'vulnerabilities' ? 'bg-white text-black font-bold a svg-icon123' : 'text-lightgrey font-normal'">
                                <div>
                                    <a class="flex navchild items-center hover:text-black py-1">
                                        <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M9.36074 12.0406V10.2688H11.217C11.0201 11.1687 10.2607 11.9 9.36074 12.0406ZM9.92324 9.48125H11.217C11.1607 9.14375 11.0482 8.83437 10.8514 8.55312L9.92324 9.48125ZM8.54512 12.0406V10.2969H6.77324C6.97012 11.1688 7.70137 11.8719 8.54512 12.0406ZM9.36074 2V4.10938C10.6545 4.19375 11.8357 4.7 12.7639 5.51562L14.2826 3.99688C12.9326 2.84375 11.217 2.08438 9.36074 2ZM14.817 4.5875L13.2982 6.10625C14.0857 7.03438 14.592 8.1875 14.6764 9.48125H16.8701C16.7576 7.59688 16.0264 5.88125 14.817 4.5875ZM6.77324 9.48125H8.54512V7.625C7.64512 7.79375 6.91387 8.525 6.77324 9.48125ZM9.36074 4.925V6.78125C9.95137 6.8375 10.4295 7.0625 10.8795 7.4L12.2014 6.07812C11.3857 5.43125 10.4295 5.00937 9.36074 4.925ZM9.36074 7.59687V8.89062L10.2889 7.9625C10.0076 7.79375 9.72637 7.65312 9.36074 7.59687ZM3.28574 9.48125C3.45449 6.64062 5.70449 4.33438 8.54512 4.1375V2C4.52324 2.225 1.31699 5.45938 1.12012 9.48125H3.28574ZM8.54512 15.5281C5.76074 15.3313 3.51074 13.0813 3.28574 10.2969H1.12012C1.31699 14.2906 4.52324 17.525 8.54512 17.75V15.5281ZM14.6764 10.2688C14.4514 13.0813 12.1732 15.3313 9.33262 15.5V17.75C13.4107 17.5531 16.6451 14.3188 16.842 10.2688H14.6764ZM8.54512 14.7125V12.8562C7.19512 12.6594 6.18262 11.6188 5.95762 10.2969H4.10137C4.32637 12.6313 6.18262 14.5156 8.54512 14.7125ZM4.10137 9.48125H5.95762C6.12637 8.10313 7.19512 7.00625 8.54512 6.80938V4.95312C6.18262 5.15 4.27012 7.0625 4.10137 9.48125ZM13.8889 10.2688H12.0326C11.8357 11.6188 10.767 12.6875 9.36074 12.8562V14.7125C11.7514 14.5438 13.6639 12.6594 13.8889 10.2688ZM11.442 7.99062C11.7514 8.4125 11.9764 8.91875 12.0326 9.48125H13.8889C13.8045 8.4125 13.4107 7.45625 12.7357 6.66875L11.442 7.99062Z" fill="#E9E9E9"/>
                                            </svg>

                                        <div class="item-name leading-5 text-sm pl-2" :class="selectedOptionName == 'vulnerabilities' ? 'text-black font-bold' : 'text-listIconColor font-normal'" v-if="isSidebarToggle">Vulnerabilities</div>
                                    </a>
                                </div>
                            </li>

                            <li @click="navigate('processRisksRoot', 'processRisksRoot')" 
                            @mouseover="displayToolTipMethod('processRiskMaster', 'ProcessRiskMaster')"
                            @mouseout="hideToolTip()"
                            class="cursor-pointer h-8 hover:bg-white svg-icon px-2.5" :class="selectedOptionName === 'processRisksRoot' ? 'bg-white text-black font-bold a svg-icon123' : 'text-lightgrey font-normal'">
                                <div>
                                    <a class="flex navchild items-center hover:text-black py-1">
                                        <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M9.36074 12.0406V10.2688H11.217C11.0201 11.1687 10.2607 11.9 9.36074 12.0406ZM9.92324 9.48125H11.217C11.1607 9.14375 11.0482 8.83437 10.8514 8.55312L9.92324 9.48125ZM8.54512 12.0406V10.2969H6.77324C6.97012 11.1688 7.70137 11.8719 8.54512 12.0406ZM9.36074 2V4.10938C10.6545 4.19375 11.8357 4.7 12.7639 5.51562L14.2826 3.99688C12.9326 2.84375 11.217 2.08438 9.36074 2ZM14.817 4.5875L13.2982 6.10625C14.0857 7.03438 14.592 8.1875 14.6764 9.48125H16.8701C16.7576 7.59688 16.0264 5.88125 14.817 4.5875ZM6.77324 9.48125H8.54512V7.625C7.64512 7.79375 6.91387 8.525 6.77324 9.48125ZM9.36074 4.925V6.78125C9.95137 6.8375 10.4295 7.0625 10.8795 7.4L12.2014 6.07812C11.3857 5.43125 10.4295 5.00937 9.36074 4.925ZM9.36074 7.59687V8.89062L10.2889 7.9625C10.0076 7.79375 9.72637 7.65312 9.36074 7.59687ZM3.28574 9.48125C3.45449 6.64062 5.70449 4.33438 8.54512 4.1375V2C4.52324 2.225 1.31699 5.45938 1.12012 9.48125H3.28574ZM8.54512 15.5281C5.76074 15.3313 3.51074 13.0813 3.28574 10.2969H1.12012C1.31699 14.2906 4.52324 17.525 8.54512 17.75V15.5281ZM14.6764 10.2688C14.4514 13.0813 12.1732 15.3313 9.33262 15.5V17.75C13.4107 17.5531 16.6451 14.3188 16.842 10.2688H14.6764ZM8.54512 14.7125V12.8562C7.19512 12.6594 6.18262 11.6188 5.95762 10.2969H4.10137C4.32637 12.6313 6.18262 14.5156 8.54512 14.7125ZM4.10137 9.48125H5.95762C6.12637 8.10313 7.19512 7.00625 8.54512 6.80938V4.95312C6.18262 5.15 4.27012 7.0625 4.10137 9.48125ZM13.8889 10.2688H12.0326C11.8357 11.6188 10.767 12.6875 9.36074 12.8562V14.7125C11.7514 14.5438 13.6639 12.6594 13.8889 10.2688ZM11.442 7.99062C11.7514 8.4125 11.9764 8.91875 12.0326 9.48125H13.8889C13.8045 8.4125 13.4107 7.45625 12.7357 6.66875L11.442 7.99062Z" fill="#E9E9E9"/>
                                            </svg>

                                        <div class="item-name leading-5 text-sm pl-2" :class="selectedOptionName == 'processRisksRoot' ? 'text-black font-bold' : 'text-listIconColor font-normal'" v-if="isSidebarToggle">Process-Risks</div>
                                    </a>
                                </div>
                            </li>

                            <li @click="navigate('orgRiskMaster', 'orgRiskMaster')" 
                            @mouseover="displayToolTipMethod('orgRiskMaster', 'OrgRiskMaster')"
                            @mouseout="hideToolTip()"
                            class="cursor-pointer h-8 hover:bg-white svg-icon px-2.5" :class="selectedOptionName === 'orgRiskMaster' ? 'bg-white text-black font-bold a svg-icon123' : 'text-lightgrey font-normal'">
                                <div>
                                    <a class="flex navchild items-center hover:text-black py-1">
                                        <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M9.36074 12.0406V10.2688H11.217C11.0201 11.1687 10.2607 11.9 9.36074 12.0406ZM9.92324 9.48125H11.217C11.1607 9.14375 11.0482 8.83437 10.8514 8.55312L9.92324 9.48125ZM8.54512 12.0406V10.2969H6.77324C6.97012 11.1688 7.70137 11.8719 8.54512 12.0406ZM9.36074 2V4.10938C10.6545 4.19375 11.8357 4.7 12.7639 5.51562L14.2826 3.99688C12.9326 2.84375 11.217 2.08438 9.36074 2ZM14.817 4.5875L13.2982 6.10625C14.0857 7.03438 14.592 8.1875 14.6764 9.48125H16.8701C16.7576 7.59688 16.0264 5.88125 14.817 4.5875ZM6.77324 9.48125H8.54512V7.625C7.64512 7.79375 6.91387 8.525 6.77324 9.48125ZM9.36074 4.925V6.78125C9.95137 6.8375 10.4295 7.0625 10.8795 7.4L12.2014 6.07812C11.3857 5.43125 10.4295 5.00937 9.36074 4.925ZM9.36074 7.59687V8.89062L10.2889 7.9625C10.0076 7.79375 9.72637 7.65312 9.36074 7.59687ZM3.28574 9.48125C3.45449 6.64062 5.70449 4.33438 8.54512 4.1375V2C4.52324 2.225 1.31699 5.45938 1.12012 9.48125H3.28574ZM8.54512 15.5281C5.76074 15.3313 3.51074 13.0813 3.28574 10.2969H1.12012C1.31699 14.2906 4.52324 17.525 8.54512 17.75V15.5281ZM14.6764 10.2688C14.4514 13.0813 12.1732 15.3313 9.33262 15.5V17.75C13.4107 17.5531 16.6451 14.3188 16.842 10.2688H14.6764ZM8.54512 14.7125V12.8562C7.19512 12.6594 6.18262 11.6188 5.95762 10.2969H4.10137C4.32637 12.6313 6.18262 14.5156 8.54512 14.7125ZM4.10137 9.48125H5.95762C6.12637 8.10313 7.19512 7.00625 8.54512 6.80938V4.95312C6.18262 5.15 4.27012 7.0625 4.10137 9.48125ZM13.8889 10.2688H12.0326C11.8357 11.6188 10.767 12.6875 9.36074 12.8562V14.7125C11.7514 14.5438 13.6639 12.6594 13.8889 10.2688ZM11.442 7.99062C11.7514 8.4125 11.9764 8.91875 12.0326 9.48125H13.8889C13.8045 8.4125 13.4107 7.45625 12.7357 6.66875L11.442 7.99062Z" fill="#E9E9E9"/>
                                            </svg>

                                        <div class="item-name leading-5 text-sm pl-2" :class="selectedOptionName == 'orgRiskMaster' ? 'text-black font-bold' : 'text-listIconColor font-normal'" v-if="isSidebarToggle">Entity Risk Master</div>
                                    </a>
                                </div>
                            </li>
                        </ul>

                        <ul class="bottomUl mb-16 sideBox">
							<li class="relative">
                                <hr class="mb-4" />
                                <img src="@/assets/leftarrow.svg" class=" icon   arrowIcon leftarrow absolute cursor-pointer" alt="" @click="toggleSideMenuBar()" v-if="isSidebarToggle" />
                                <img src="@/assets/rightarrow.svg" class="arrowiconRight  icon  rightarrow absolute cursor-pointer" alt="" @click="toggleSideMenuBar()" v-else />
                            </li>
                            <li class="mt-2">
                                <a @click="triggerNavToAdminSettings()" class="flex navchild items-center" :class="{ 'pl-1 py-1': isSidebarToggle, 'px-2.5 py-1': !isSidebarToggle }">
                                    <img src="@/assets/settings.svg" class="icon" :class="{ 'px-1.5': isSidebarToggle, '': !isSidebarToggle }" />
                                    <div class="item-name leading-5 text-sm text-lightgrey" v-if="isSidebarToggle">Settings</div>
                                </a>
                            </li>
						</ul>
                        <div class="fixed shadow z-50 bg-white w-40 px-2 py-1 min-h-30 flex flex-col justify-center" v-if="appsVisible">
                            <div class="flex flex-row items-center cursor-pointer">
                                <div class="flex flex-row w-2/4 items-center cursor-pointer" @click="triggerNavigation()">
                                    <img src="../assets/training.svg" alt="" class="icn h-8 w-8" />
                                    <div class="nav-item-label-container ml-4">
                                        <div class="text-sm font-semibold">Training</div>
                                        <div class="active-border"></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div v-if="toolTipActive">
                        <tool-tip :tipText="toolTipText" :iconName="selectedOptionName" :combinations="combinations"></tool-tip>
                    </div>
                </div>
                <div class="content flex-1 w-full h-screen overflow-y-scroll" :class="{ toggledstate: isSidebarToggle }">
                    <div class="page">
                        <slot />
                    </div>
                    <Footer />
                </div>
            </div>
        </div>
    </div>
</template>
<style scoped>
.sideLabels{
    height: 92%;
    overflow-y: auto;
}
.sideBox{
    height: 8%;
}
.sidemenubarScroll ::-webkit-scrollbar {
    width: 3px !important;
}
.sidemenubarScroll ::-webkit-scrollbar-thumb {
  background: #119BB9 !important; 
  border-radius: 5px;
}
.h-8{
    height: 34px;
    display: flex;
    align-items: center;
}
.sideToolTip {
    background: #152a43;
    color: white;
    left: 110%;
    padding: 5px;
    border-radius: 4px;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 28px !important;
    font-size: 14px;
    transform:translateY(-30px);
}
.leftarrow,
.rightarrow {
    /* height: 1.25rem;
    width: 1.25rem; */
    margin-top: -27px;
}
.arrowIcon{
	height:22px;
	width:22px;
	margin-left:157px;
}
.arrowiconRight{
	height:22px;
	width:22px;
	margin-left:29px;
}
.a:hover{
	color:black;
	font-weight: 400;
}
.svg-icon:hover {
    color:black;
}
.svg-icon123 div a svg path {
    fill: #152A43;
}
.svg-icon:hover path {
    fill: #152A43; /* Change the color to your desired hover color */
}
.svg-Image:hover svg path{
    fill: #152A43;
}
</style>
<script lang="ts">
import { mapGetters } from 'vuex';
import NavBar from '../components/navBar.vue';
import Footer from '../components/footer.vue';
import { emitter } from '../main';
import toolTip from '@/components/menuBarToolTip.vue'

import { defineComponent } from 'vue';
import informationVue from '@/pages/root-admin/information.vue';

export default defineComponent({
    data(): any {
        return {
            resizeTimeout:0,
            isSidebarToggle: true,
            manage: false,
            rootControls: false,
            appsVisible: false,
            sideNavSelected: '',
            selectedOptionName: '',
            passingRouteName:'',
            menus: [
                {
                    optionName: 'information',
                    optionRoute: 'information',
                    defaultoptionImage: require('@/assets/Informationpage.svg'),
                    colouredoptionImage: require('@/assets/InformationColored.svg'),
                    displayOptionName: 'Information'
                },
                {
                    optionName: 'tvmapping',
                    optionRoute: 'tvmapping',
                    defaultoptionImage: require('@/assets/riskMaster.svg'),
                    colouredoptionImage: require('@/assets/RiskMasterColored.svg'),
                    displayOptionName: 'Risk Master'
                },
                {
                    optionName: 'threat',
                    optionRoute: 'threat',
                    defaultoptionImage: require('@/assets/Threat.svg'),
                    colouredoptionImage: require('@/assets/ThreatColored.svg'),
                    displayOptionName: 'Threat'
                },
                 {
                    optionName: 'vulnerabilities',
                    optionRoute: 'vulnerabilities',
                    defaultoptionImage: require('@/assets/Vulnerability.svg'),
                    colouredoptionImage: require('@/assets/VulnerabilityColored.svg'),
                    displayOptionName: 'Vulnerabilities'
                },
               
               
                //  {
                //     optionName: 'systems',
                //     optionRoute: 'systems',
                //     defaultoptionImage: require('../assets/kpiDefault.svg'),
                //     colouredoptionImage: require('../assets/kpiColoured.svg'),
                //     displayOptionName: 'System'
                // },

               
               
            ],
            displayToolTip: false,
            tiptext: '',
            iconName: '',

            toolTipActive : false,
            toolTipText : '',

            combinations:[
                {tiptext:'Information',option:'information'},
                {tiptext:'RiskMaster',option:'tvmapping'},
                {tiptext:'Threat',option:'threat'},
                {tiptext:'Vulnerabilities',option:'vulnerabilities'},
                {tiptext:'ProcessRiskMaster',option:'processRisksRoot'},
                {tiptext:'OrgRiskMaster',option:'orgRiskMaster'},
            ]
        };
    },

    components: {
        NavBar,
        Footer,
        toolTip
    },
    computed: {
        currentRouteName() {
            return this.$route.name;
        },
		...mapGetters({ toggleState:'toggle' })
	},
    methods: {
         displayToolTipMethod(name: any, tiptext: any) {
            // if (this.isSidebarToggle == false) {
            //     this.displayToolTip = true;
            //     this.tipText = tiptext;
            //     this.iconName = name;
            // }
            if (this.isSidebarToggle == false) {
                // this.displayToolTip = true;
                // this.tipText = tiptext;
                // this.iconName = name;
                this.toolTipActive = true;
                this.toolTipText = tiptext;
                this.iconName = name;
                
                
                // let obj:any = {
                //     tipText : this.tipText
                // }
                // emitter.emit('activiteToolTip',obj)
            }
        },
        hideToolTip() {
            this.toolTipActive = false;
            this.toolTipText = '';
            this.iconName = ''
        },
        toggleInfo(arg1: any) {
            this.toggleIndicator = arg1;
        },
        logout() {
            this.$http.post(`${process.env.VUE_APP_AUTH_API_URL}/api/auth/logout`).then(() => {
                this.$router.push({ name: 'login' });
                localStorage.removeItem('AUTH_ID');
            });
        },
        toggleSideMenuBar() {
            this.isSidebarToggle = !this.isSidebarToggle;
            this.$store.dispatch('selectedToggle', this.isSidebarToggle);
            emitter.emit('toggle-sidebar', this.isSidebarToggle);
        },
        triggerNavToAdminSettings() {
            window.location.href = `${process.env.VUE_APP_AUTH_WEBAPP_URL}/session?returnTo=${process.env.VUE_APP_ADMIN_WEBAPP_URL}`;
        },
        navigate(name: any, routerOption: any) {
            this.$store.dispatch('selectedToggle', this.isSidebarToggle);
            this.$store.dispatch('selectedRoute', name);
            this.$router.push({ name: routerOption });
        },
        triggerNavigation() {
            window.location.href = `${process.env.VUE_APP_AUTH_WEBAPP_URL}/session?returnTo=${process.env.VUE_APP_TRAINING_WEBAPP_URL}`;
        },
        closeApps(){
			this.appsVisible=false;
			emitter.emit('apps', this.appsVisible);
		},
        debouncedHandleResize() {
            clearTimeout(this.resizeTimeout);
            this.resizeTimeout = setTimeout(this.handleResize, 100); // Adjust debounce time as needed
        },
       handleResize() {
            if(window.innerWidth<1164)
            {
                this.$store.dispatch('selectedToggle', false);
                this.isSidebarToggle = false;
                emitter.emit('toggle-sidebar', this.isSidebarToggle);
            }
            else if(this.isSidebarToggle == false){
                this.$store.dispatch('selectedToggle', true);
                this.isSidebarToggle = true;
                emitter.emit('toggle-sidebar', this.isSidebarToggle);
            }
        },
    },
    mounted() {
        window.addEventListener('resize', this.debouncedHandleResize);
        if(this.$route.path.includes('information')){
            this.passingRouteName = 'information';
        }else if(this.$route.path.includes('tvmapping')){
            this.passingRouteName = 'tvmapping'
        }else if(this.$route.path.includes('threat')){
            this.passingRouteName = 'threat'
        } else if(this.$route.path.includes('vulnerabilities')){
            this.passingRouteName = 'vulnerabilities';
        }else{
            this.passingRouteName = this.currentRouteName;
        }
        console.log(this.passingRouteName, 'this.passingRouteName');
        this.selectedOptionName = this.passingRouteName;
        console.log('selectedOptionName', this.selectedOptionName);
        // emitter.on('toggle-sidebar', (isToggle) => {
		// 	this.isSidebarToggle = isToggle;
		// });
        emitter.on('apps-sidemenubar', (isToggle) => {
            this.appsVisible = isToggle;
        });
        this.isSidebarToggle = this.toggleState;
        // emitter.on('toggle-sidebar', (isToggle: any) => {
        //     this.isSidebarToggle = isToggle;
        // });
    },
     beforeUnmount() {
        window.removeEventListener('resize', this.debouncedHandleResize);
    },
     created() {
        this.handleResize();
    },
});

</script>

  