<template>
    <div class="pt-3 pl-5 pr-5">
        <div class="row w-full justify-between flex">
            <div class="title flex items-center">
                <h1>Information Classification</h1>
            </div>
            <div class="flex items-center">
                <button class="ml-3 flex" :class="informationClassification.length == 0 ? 'btndisabled pointer-events-none' : 'filterButton'" @click="filterOpen">
                    Filters
                    <img src="@/assets/filter.svg" class="h-3 ml-2.5" />
                </button>
            </div>
        </div>
        <div class="mt-3 text-xs">
            <div>
                Data organization is the practice of categorizing and classifying data to make it more usable. Similar to a filie folder, where we keep important documents, you'll need to arrange your data in the most logical and orderly fashion, so you and anyone else who accesses it can easily
                find what they're looking for. Data organization is the practice of categorizing and classifying data to make mone usable. Similar to a file folder, where we keep important documents, you'll need to arrange your data in the most logical and orderly fashion.
            </div>
        </div>
        
            <newTable :tableRowData="informationClassification" :headersData="Headers" :openPopup="false" :navigate="true"></newTable>
        
    </div>
</template>
<style scoped>
</style>
<script lang="ts">
import newTable from '@/components/newTable.vue';
import { defineComponent } from 'vue';
import { emitter } from '@/main';
import { mapGetters } from 'vuex';
 
export default defineComponent({
    data():any {
        return {
            showFilters: false,
            Headers: ['label', 'infopreview', 'description'],
            informationClassification: [],
        };
    },
    components: {
        newTable
    },
    computed:{
        ...mapGetters({ dbInformationClassification:'dbInformationClassification'})
    },
    async created(){
        await this.getLabelEntityData(20414)
    },
    async mounted(){
        emitter.off('rowData')
        emitter.on('rowData', (rowData:any)=>{
            this.gotoInformationsClassification(rowData.label);
        });
    },
    methods: {
        filterOpen() {
            this.showFilters = !this.showFilters;
            emitter.emit('visible', this.showFilters);
        },
        gotoInformationsClassification(classificationTypee: any) {
            this.$router.push({ name: 'infoClassificationProperties', params: { classificationType: classificationTypee } });
        },
        async getLabelEntityData(labelEntityId:any){
            
            let finalArray:any = [];

            await this.$http.get(`${process.env.VUE_APP_ORG_API_URL}/api/org/label/${labelEntityId}/getall`).then((result: any) => {
                console.log('res.dataaaa',result.data)
                result.data.map((dbObj:any)=>{
                    let sendObj:any = {};
                    sendObj = {label:dbObj.key ,infopreview:dbObj.value ,description:dbObj.description};
                    
                    finalArray.push(sendObj)
                })
                this.informationClassification =  finalArray;
            })
        }
    },
});
</script>
 
 
 