

<template>
    <div class="maincontainer pt-3 pl-5 pr-5">
        <h1 class="h-8 flex items-center">Dashboard</h1>
        <div class="lg:flex justify-between mt-5">
            <div class="bg-white cursor-pointer sm:mb-2 lg:mb-0 mr-3 flex justify-center items-center h-17 rounded w-full border border-primary">
                <div>
                    <p class="text-sm font-normal px-2 text-primary">Total Risks</p>
                    <p class="text-center mt-1 text-primary font-semibold text-lg">{{TotalRisksCount}}</p>
                </div>
            </div>
            <div v-for="pair in riskStatusValue" :key="pair" @click="getRisksFromLabel(pair.id)" class="mr-3 sm:mb-2 lg:mb-0 cursor-pointer flex relative justify-center items-center indicateBoxes h-17 rounded w-full border" :class="colorRiskText(pair.id)">
                <div class="w-full h-full absolute rounded" :class="colorRiskBg(pair.id)"></div>
                <div class="flex  rounded items-center regular text-sm">
                    <div v-if="!isNaN(pair.id)">
                        <p class="text-sm font-normal text-center">{{pair.value}}</p>
                        <p class="mt-1 text-center font-semibold text-lg">{{getCount(pair.id)}}</p>
                    </div>
                </div>
            </div>
        </div>
        
        <div class="heatMapContainer relative lg:flex p-4 rounded bg-white border border-lightgrey mt-10">
            <div class="heatMapOrganisation ml-2 absolute bg-white rounded border border-lightgrey">
                <p class="text-base font-semibold text-center">Heat Map - Organization</p>
            </div>

            <div class="riskHeatMapContainer w-auto mt-4 pl-5 lg:pl-0 max-2xl:w-1/2">
                <div class="flex pl-20 mb-5">
                    <div class="px-2" v-for="obj in colorData" :key="obj.value">
                        <div class="flex justify-center items-center cursor-pointer" @mouseover="hoverColor(obj.id)" @mouseleave="leaveColor()">
                            <div class="h-3 w-3 rounded-full" :class="obj.color"></div>
                            <div class="text-xs pl-1">{{obj.value}}</div>
                        </div>
                    </div>
                </div>
                <div class="flex">
                    <div class="flex flex-col w-3 justify-center likelihoodLabel pr-8">
                        <p class="font-bold text-lg">Likelihood</p>
                    </div>
                    <div class="flex flex-col justify-between">
                        <div><p class="axisLabel pt-4">VeryHigh</p></div>
                        <div><p class="axisLabel pt-1">High</p></div>
                        <div><p class="axisLabel">Moderate</p></div>
                        <div><p class="axisLabel">Low</p></div>
                        <div><p class="axisLabel pb-10">VeryLow</p></div>
                    </div>
                    <div>
                        <div v-for="(row, rowIndex) in grid" :key="rowIndex" class="flex">
                            <div v-for="(cell, colIndex) in row" :key="colIndex" :class="getColor(cell.x, cell.y)" @click="getZonalRisks(cell.x,cell.y)" class="flex items-center cursor-default justify-center heatmapBox">
                                <div class="text-black bg-lightgrey rounded-full h-5 w-5 flex justify-center items-center text-xs font-bold">
                                    {{ cell.value }}
                                </div>
                            </div>
                        </div>
                        
                        <div class="flex mt-2 justify-between">
                            <div class="pl-5"><p class="axisLabel">VeryLow</p></div>
                            <div class="pl-2"><p class="axisLabel">Low</p></div>
                            <div><p class="axisLabel">Moderate</p></div>
                            <div><p class="axisLabel">High</p></div>
                            <div class="pr-5"><p class="axisLabel">VeryHigh</p></div>
                        </div>
                    </div>
                </div>
                <div class="mt-3 max-lg:ml-14 lg:ml-28 flex justify-center"><p class="font-bold text-lg">Impact</p></div>
            </div>
            <div class="lg:ml-4 w-full">
                <div class="row">
                    <newTable :tableRowData="displayChartRisksListInTable" :headersData="headers1" :openPopup="false" :loadData1="dataLoading1"></newTable>
                    <div v-if="toolTipActive">
                        <tool-tip :tipText="toolTipText"></tool-tip>
                    </div>
                </div>
            </div>
        </div>

        <div class="max-lg:h-full relative lg:flex p-4 rounded bg-white border border-lightgrey mt-10">
            <div>
                <div class="tierMapOrganisation ml-2 absolute bg-white rounded border border-lightgrey">
                    <p class="text-base font-semibold text-center">Tier Level Graph / Chart</p>
                </div>

                <div class="tierTriangleMapContainer w-auto mt-4 pl-5 lg:pl-0 max-2xl:w-1/2">
                    <div class="strategicRisk"><h1 class="text-base font-bold text-center">STRATEGIC RISK</h1></div>
                    <div class="container my-3">
                        <div class="triangleShape">
                            <div class="backgroundDiv ">
                                <div class="tierMapBackgroundBorder1">
                                </div>
                                <div class="tierMapBackgroundBorder">
                                    <div class="bgLayer1 font-extrabold pr-3 flex justify-end items-center"> {{ getTierRisks(1) }} Risks</div>
                                    <div class="bgLayer2 font-extrabold pr-3 flex justify-end items-center"> {{ getTierRisks(2) }} Risks</div>
                                    <div class="bgLayer3 font-extrabold pr-3 flex justify-end items-center"> {{ getTierRisks(3) }} Risks</div>
                                </div>
                            </div>
                            <div class="custom-shape bg-black flex justify-center items-center text-white">
                                <span class="border-span">
                                    <div @click="getTierLevelRisks(1)" class="layer layer1 bg-blue-350 text-center cursor-pointer flex justify-center font-semibold items-center border-b-2 border-black z-10">
                                        <div class="orgBorder">Tier 1<br />Organisation</div>
                                    </div>

                                    <div @click="getTierLevelRisks(2)" class="layer layer2 cursor-pointer text-center flex justify-center items-center font-semibold border-b-2 border-black z-0">Tier 2<br />Business Process</div>

                                    <div @click="getTierLevelRisks(3)" class="layer layer3 cursor-pointer text-center flex justify-center items-center font-semibold">
                                        Tier 3 <br />
                                        System Level
                                    </div>
                                </span>
                            </div>
                        </div>
                    </div>
                    <div class="tacticalRisk"><h1 class="text-base font-bold text-center">TACTICAL RISK</h1></div>
                </div>
            </div>

            <div class="lg:ml-4 w-full">
                <p class="text-base font-semibold text-center mt-2">{{getTierHeading(selectedTier)}}</p>
                <div class="row">
                    <newTable :tableRowData="tierLevelRiskData" :headersData="tierHeaders" :openPopup="false" :loadData2="dataLoading2"></newTable>
                    <div v-if="toolTipActive">
                        <tool-tip :tipText="toolTipText"></tool-tip>
                    </div>
                </div>
            </div>
        </div>

        <div id="DonutContainer" class="relative lg:flex p-3 rounded bg-white border border-lightgrey mt-10">
            <div class="tierMapOrganisation ml-2 absolute bg-white rounded border border-lightgrey">
                <p class="text-base font-semibold text-center">Priority Level Graph / Chart</p>
            </div>

            <div class="sm:w-full lg:w-2/5 sm:justify-center lg:justify-start flex items-center">
                <div id="chart">
                    <apexchart width="400" type="donut" :options="donutChartOptions" :series="series" @dataPointSelection="clickOnDonutSlice"></apexchart>
                </div>
            </div>
            
            <div class="sm:w-full lg:w-3/5 mr-2">
                <p class="text-base font-semibold text-center mt-2">{{'Priority Based Risk - '}}{{selectedPriority}}</p>
                <newTable :tableRowData="displayPriorityRiskList" :headersData="headers2" :openPopup="false" :loadData3="dataLoading3"></newTable>
                <div v-if="toolTipActive">
                    <tool-tip :tipText="toolTipText"></tool-tip>
                </div>
            </div>
        </div>

        <div id="severityTable" class="filterRisksContainer relative flex p-3 rounded bg-white border border-lightgrey mt-4">
            
            <div class="ml-1 w-full">
                <div class="flex justify-between mt-3">
                    <p class="text-base font-semibold text-center ml-5">Risks - By Severity</p>
                    <div class="w-30 mr-5 h-7">
                        <select class="inputboxstyling ml-3" @change="getTopRisksData(1,severityFilter)" v-model="severityFilter">
                            <option v-for="pair in dbSeverity" :key="pair" :value="pair.value">{{ pair.key }}</option>
                        </select>
                    </div>
                    
                </div>
                <div class="row">
                    <newTable :tableRowData="displaySeverityRiskList" :headersData="headers2" :openPopup="false" :loadData3="dataLoading3"></newTable>
                    <div v-if="toolTipActive">
                        <tool-tip :tipText="toolTipText"></tool-tip>
                    </div>
                </div>
            </div>
        </div>

        <div id="statusTable" class="filterRisksContainer relative flex p-3 rounded bg-white border border-lightgrey mt-4">          
            <div class="ml-1 w-full">
                <div class="flex justify-between mt-3">
                    <p class="text-base font-semibold ml-5 text-center">Risks - By Status</p>
                    <div class="w-30 h-7 mr-5">
                        <select class="inputboxstyling"  @change="getTopRisksData(2,riskStatus)" v-model="riskStatus">
                            <option hidden value="">Select</option>
                            <option v-for="option in riskStatusValue" :key="option" :value="option.id">{{option.value}}</option>
                        </select>
                    </div>
                </div>
                <div class="row">
                    <newTable :tableRowData="displayStatusesRiskList" :headersData="headers2" :openPopup="false" :loadData3="dataLoading3"></newTable>
                    <div v-if="toolTipActive">
                        <tool-tip :tipText="toolTipText"></tool-tip>
                    </div>
                </div>
            </div>
        </div>

        <div id="deptTable" class="filterRisksContainer relative flex p-3 rounded bg-white border border-lightgrey mt-4">
            <div class="ml-1 w-full">
                <div class="flex justify-between mt-3">
                    <p class="text-base font-semibold ml-5">Risks - By Department</p>
                    <div class="w-30 h-7 mr-5">
                        <select class="inputboxstyling" @change="getTopRisksData(3,deptName)" v-model="deptName">
                            <option hidden value="">Select</option>
                            <option v-for="option in departmentList" :key="option" :value="option.id">{{option.value}}</option>
                        </select>
                    </div>
                    
                </div>
                <div class="row">
                    <newTable :tableRowData="displayDeptRiskList" :headersData="headers2" :openPopup="false" :loadData3="dataLoading3"></newTable>
                    <div v-if="toolTipActive">
                        <tool-tip :tipText="toolTipText"></tool-tip>
                    </div>
                </div>
            </div>
        </div>

        <div id="ageTable" class="filterRisksContainer relative flex p-3 rounded bg-white border border-lightgrey mt-4">
            <div class="ml-1 w-full">
                <div class="flex justify-between mt-3">
                    <p class="text-base font-semibold ml-5">{{"Risks - By Status & Age"}}</p>
                    <div class="mr-5 w-2/3 flex justify-end ">
                        <div class="w-30 h-7 mr-2">
                            <select class="inputboxstyling ml-3" @change="getTopRisksData(4,ageFilter)" v-model="statusForAge">
                                <option value="" hidden>Select</option>
                                <option v-for="pair in riskStatusValue" :key="pair" :value="pair.id">{{ pair.value }}</option>
                            </select>
                        </div>
                        <div class="w-30 h-7 mr-5">
                            <select class="ml-3" :disabled="statusForAge == ''" @change="getTopRisksData(4,ageFilter)" v-model="ageFilter" :class="{'disabledinputboxstyling disabledselect py-1.5':statusForAge == '' ,'inputboxstyling px-1.5':statusForAge != '' }">
                                <option v-for="pair in AgeObject" :key="pair" :value="pair.id">{{ pair.value }}</option>
                            </select>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <newTable :tableRowData="displayAgeRiskList" :headersData="headers3" :openPopup="false" :loadData3="dataLoading3"></newTable>
                    <div v-if="toolTipActive">
                        <tool-tip :tipText="toolTipText"></tool-tip>
                    </div>
                </div>
            </div>
        </div>

        <div id="timePeriodTable" class="filterRisksContainer relative flex p-3 rounded bg-white border border-lightgrey mt-4">
            <div class="ml-1 w-full">
                <div class="flex justify-between mt-3">
                    <p class="text-base font-semibold w-1/3 ml-5">Risks - By Time</p>
                    <div class="mr-5 w-2/3 flex justify-end">
                        <div class="w-30 h-7 mr-3">
                            <input type="date" @click="openCalendar" @blur="handleBlur" class="inputboxstyling" v-model="startDate"  :max="maxDate" @change="checkDates(startDate,endDate)" :class="{'focused': isFocused}"/>
                        </div>
                        <div class="w-30 h-7">
                            <input type="date" @click="openCalendar" @blur="handleBlur" :disabled="startDate==''" :max="maxDate" v-model="endDate" @change="getTopRisksData(5,[startDate,endDate])" :class="{'disabledinputboxstyling py-1.5':startDate=='','inputboxstyling':startDate!='', 'focused': isFocused}"/>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <newTable :tableRowData="displayTimePeriodRisks" :headersData="headers2" :openPopup="false" :loadData3="dataLoading3"></newTable>
                    <div v-if="toolTipActive">
                        <tool-tip :tipText="toolTipText"></tool-tip>
                    </div>
                </div>
            </div>
        </div>

        <div v-if="showBackToTop" @click="scrollToTop" class="back-to-top flex items-center justify-center">
            <img src="@/assets/leftarrow.svg" class="h-12 leftarrow absolute cursor-pointer"/>
        </div>
    </div>
</template>
<style scoped>
.vue-apexcharts{
	padding-right: 10px !important;
	padding-left: 10px !important;
}
.filterRisksContainer{
    height: 502px !important;
}
tr:last-child {
  border-bottom: 1px solid #e9e9e9 !important;
}
.TierMapContainer{
    height: 468px ;
}
.backgroundDiv {
    width: 485px;
    height: 300px;
    display: flex;
}
.tierMapBackgroundBorder1 {
    width: 210px;
    height: 300px;
}
.tierMapBackgroundBorder {
    border: 1px solid #e9e9e9;
    width: 275px;
    height: 300px;
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
}
.strategicRisk,.tacticalRisk{
    width: 420px;
    height: 24px;
}
.orgBorder{
    padding-right: 3px;
    padding-top: 10px;
}
.custom-shape {
    position: absolute;
    top: 68px; 
    left: 16px; 
    width: 420px;
    height: 300px;
    clip-path: polygon(50% 0, 100% 100%, 0 100%);
    color: white;
    font-size: 9px;
}

.border-span {
    width: 414px;
    height: 297px;
    clip-path: polygon(50% 0, 100% 100%, 0 100%);
}

.layer {
    position: absolute;
    width: 100%;
    height: 99px;
}

.layer1 {
    background-color: #40b0ff;
    top: 0;
}

.layer2 {
    background-color: #327cb4;
    top: 99px;
}

.layer3 {
    background-color: #01548f;
    top: 198px;
}
.bgLayer1 {
    width: 420;
    height: 97px;
    box-sizing: border-box;
    position: relative;
    font-size: 9px;
}
.bgLayer2 {
    width: 420;
    height: 99px;
    border-top: 1px solid #e9e9e9;
    border-bottom: 1px solid #e9e9e9;
    box-sizing: border-box;
    position: relative;
     font-size: 9px;
}
.bgLayer3{
    width: 420;
    height: 86px;
    font-size: 9px;
}
</style>
<script lang="ts">
import { defineComponent } from 'vue';
import VueApexCharts from 'vue3-apexcharts';
import newTable from '@/components/dashBoardTable.vue';
import { create } from 'lodash';
import { emitter, toast } from '@/main';
import moment from 'moment';
import { mapGetters } from 'vuex';
import toolTip from '@/components/toolTip.vue'

export default defineComponent({
    components: {
        newTable,
        apexchart: VueApexCharts,
        toolTip
    },
    data(): any {
        return {
            toolTipActive : false,
            toolTipText : '',
            dataLoading1:true,
            dataLoading2:true,
            dataLoading3:true,
            isFocused: false,
            dashBoardData: [],
            grid:[],
            risksInformation:[],
            hoverState: false,
            hoveredId:0,
            headers1:['entityId','severity','risk','riskCategory','tier'],
            tierHeaders:['_id','severity','risk','riskCategory'],
            headers2:['entityId','severity','risk','riskCategory','tier'],
            headers3:['entityId','severity','risk','riskCategory','age','tier'],
            colorData: [
                {
                    id: 1,
                    value: 'VeryLow',
                    color: 'bg-lightgreen',
                    hover: 'bg-lightgreen opacity-30 transition-all',
                    coordinates: [{ x:1, y:1 }, { x:2, y:1 }, { x:1, y:2 }, { x:3, y:1 }, { x:2, y:2 }, { x:1, y:3 }],
                },
                {
                    id: 2,
                    value: 'Low',
                    color: 'bg-green',
                    hover: 'bg-green opacity-30 transition-all',
                    coordinates: [{ x:4, y:1 }, { x:5, y:1 }, { x:3, y:2 }, { x:4, y:2 }, { x:2, y:3 }, { x:1, y:4 }, { x:2, y:4 }, { x:1, y:5 }],
                },
                {
                    id: 3,
                    color: 'bg-yellow',
                    hover: 'bg-yellow opacity-30 transition-all',
                    value: 'Moderate',
                    coordinates: [{ x:5, y:2 }, { x:3, y:3 }, { x:4, y:3 }, { x:3, y:4 }, { x:1, y:2 }, { x:2, y:5 }],
                },
                {
                    id: 4,
                    color: 'bg-error',
                    hover: 'bg-error opacity-30 transition-all',
                    value: 'High',
                    coordinates: [{ x:3, y:5 }, { x:4, y:4 }, { x:5, y:3 }],
                },
                {
                    id: 5,
                    color: 'bg-darkred',
                    hover: 'bg-darkred opacity-30 transition-all',
                    value: 'VeryHigh',
                    coordinates: [{ x:4, y:5 }, { x:5, y:5 }, { x:5, y:4 }],
                },
            ],
            riskStatusValue: [
                {
                    id: 10401,
                    value: 'Open'
                },
                {
                    id: 10402,
                    value: 'In Progress'
                },
                {
                    id: 10403,
                    value: 'Accepted'
                },
                {
                    id: 10405,
                    value: 'Mitigated'
                },
                {
                    id: 10406,
                    value: 'Partial'
                }
            ],
            AgeObject: [
                {
                    id: 0,
                    value: 'Older than 30 Days'
                },
                {
                    id: 1,
                    value: 'Older than 60 Days'
                },
                {
                    id: 2,
                    value: 'Older than 90 Days'
                },
                {
                    id: 3,
                    value: 'Older than 180 Days'
                },
                {
                    id: 4,
                    value: 'Older than 1 year'
                },
            ],
            openCount:0,
            inProgressCount:0,
            AcceptedCount:0,
            MitigatedCount:0,
            PartialCount:0,
            TotalRisksCount:0,
            zonefilters:{
                severity:'',
            },
            showBackToTop: false,
            primaryFilter:'',
            severityFilter:5,
            ageFilter:0,
            statusForAge:'',
            systems:[],
            displaySeverityRiskList:[],
            displayPriorityRiskList:[],
            displayStatusesRiskList:[],
            displayDeptRiskList:[],
            displayAgeRiskList:[],
            displayTimePeriodRisks:[],
            startDate:'',
            endDate:'',
            displayChartRisksListInTable: [{entityId:20010}],
            zonalRiskList:[],
            topRisks:[],
            departmentList:[],
            riskStatus:10401,
            deptName:'',
            tierLevelDataObj:[],
            tierLevelRiskData:[{entityId:20010}],
            selectedTier:3,
            allRiskList:[],

            series: [],
            selectedPriority: 'P1',
            donutChartOptions: {
				colors: ['#C00000', '#FF0000', '#FF9900', '#92d050', '#00B050'],
				labels: ['P1', 'P2', 'P3', 'P4','P5'],
                dataLabels: {
                    enabled: false
                },
				legend: {
					fontSize: '14px',
					position: 'bottom'
				},
                plotOptions: {
					pie: {
                        expandOnClick: false,
						donut: {
							labels: {
								show: true,
                                borderRadius: 10,
                                name: { 
                                    show: true,
                                    fontSize: '22px',
                                    fontFamily: 'Rubik',
                                    color: '#000000',
                                    fontWeight:600,
                                    offsetY: -10
                                },
                                value: {
                                    show: true,
                                    fontSize: '16px',
                                    fontFamily: 'Helvetica, Arial, sans-serif',
                                    color: '#000000',
                                    fontWeight:600,
                                    offsetY: 16,
                                    formatter: function (val:any) {
                                        return val
                                    }
                                },
								total: {
									show: true,
									label: 'Total Risks',
									color: '#000000',
                                    fontWeight:600,
									formatter: function (w:any) {
										return w.globals.series.reduce((a:any, b:any) => {
                                            return a + b
                                        }, 0)
									}
								}
							},
						}
					}
				},
			},
            
        }
    },
    computed: {
        ...mapGetters({dbSeverity:'dbSeverity',dbPriority:'dbPriority'}),
        maxDate() {
            const today = new Date();
            const year:any = today.getFullYear();
            let month:any = today.getMonth() + 1;
            let day:any = today.getDate();
            
            if (month < 10) {
            month = '0' + month;
            }
            if (day < 10) {
            day = '0' + day;
            }
            
            return `${year}-${month}-${day}`;
        }
    },
    async created(){
        await this.getHeatMapData();
        console.log('kugediu',window)
    },
    async mounted() {
        // this.dataLoading = true;

        await this.getSystems();
        await this.getAllRisksList()
        await this.getAllTeams();
        await this.getHeatMapData();
        await this.getTierLevelData();
        await this.getDashboardInformation();

        this.displaySeverityRiskList.push({entityId:20011});
        this.displayStatusesRiskList.push({entityId:20011});
        this.displayDeptRiskList.push({entityId:20011});
        this.displayTimePeriodRisks.push({entityId:20011});
        this.displayPriorityRiskList.push({entityId:20011});
        this.displayAgeRiskList.push({entityId:20011});

        // this.dataLoading = true;

        await this.getTierLevelRisks(3);
        await this.getZonalRisks(1,1);
        await this.getTopRisksData(1,this.severityFilter);
        await this.getTopRisksData(2,this.riskStatus);
        await this.getTopRisksData(3,this.deptName);
        await this.getTopRisksData(4,0);

        const today = new Date();
        const yesterday = new Date(today);
        yesterday.setDate(today.getDate() - 1);

        const d1 = yesterday; // yesterday's date
        const d2 = today;     // today's date
        await this.getTopRisksData(5,[d1,d2]);

        this.displayPriorityRiskList = [];
        let filterPriorRisk:any = this.allRiskList.filter((obj:any)=>{return obj.priority == 5})
        filterPriorRisk.map((risk:any)=>{
            let tableobj:any = { entityId:20011,severity: risk.severity,risk: risk.risk, riskCategory:risk.riskCategory,tier:risk.tier}
            this.displayPriorityRiskList.push(tableobj)
        })
        
        
        
        window.addEventListener('scroll', this.handleScroll);

        emitter.off('activiteToolTip');
        emitter.on('activiteToolTip', (data: any) => {
            this.toolTipActive = true;
            this.toolTipText = data.tipText
        });
 
        emitter.off('deActiviteToolTip');
        emitter.on('deActiviteToolTip', (data: any) => {
            this.toolTipActive = false;
            this.toolTipText = ''
        });
    },
    beforeUnmount() {
        window.removeEventListener('scroll', this.handleScroll);
    },
    methods: {
        openCalendar(event:any) {
            if(!this.isFocused){
                this.isFocused = true;
			    event.target.showPicker();
            }else{
                this.isFocused = false;
            }
		},
        handleBlur() {
            this.isFocused = false;
        },
        getTierHeading(tierId:any){
            if(tierId == 1){
                return 'Risks in Organization ( Tier-1 )'
            }else if(tierId==2){
                return 'Risks in Process ( Tier-2 )'
            }else if(tierId==3){
                return 'Risks in Systems ( Tier-3 )'
            }
        },
        getDateFormat(date: any) {
            if (date == '') {
                return 'None';
            } else {
                let now = moment(date);
			    return now.format('YYYY-MM-DD');
            }
        },
        handleScroll() {
            this.showBackToTop = window.scrollY > 100; 
        },
        scrollToTop() {
            window.scrollTo({
                top: 0,
                behavior: 'smooth'
            });
        },
        clickOnDonutSlice(event:any, chartContext:any, config:any){
            // console.log('eventevent', event)
            // console.log('chartContext',chartContext)
            // console.log('configconfig', config)
            // alert (chartContext.w.globals.labels[config.dataPointIndex] )
            this.changePriorityTable(config.dataPointIndex + 1)
        },
        changePriorityTable(pType:any){
            this.selectedPriority = `P${pType}`;
            this.displayPriorityRiskList = [];

            let filterPriorRisk:any = this.allRiskList.filter((obj:any)=>{return obj.priority == (6 - pType)});

            filterPriorRisk.map((risk:any)=>{
                let tableobj:any = { entityId:20011,severity: risk.severity,risk: risk.risk, riskCategory:risk.riskCategory,tier:risk.tier}
                this.displayPriorityRiskList.push(tableobj)
            })
        },
        getRisksFromLabel(id:any){
            this.riskStatus = id;
            this.getTopRisksData(2,id);
            this.scrollToStatusTable();
        },
        async checkDates(d1:any,d2:any){
            if(d1!='' && d2!=''){
                let present = new Date();
                let presentdate = this.getDateFormat(present)
                if(d1 > d2){
                    toast.error(`Start Date Should not be greater End Date`, {
                        timeout: 1000,
                        closeOnClick: true,
                        closeButton: 'button',
                        icon: true
                    });
                    this.startDate = '';
                    this.endDate = '';
                    this.displayTimePeriodRisks = [];
                    return ''
                }else{
                    await this.getTopRisksData(5,[this.startDate,this.endDate])
                }

            }else{
                return ''
            }
        },
        scrollToStatusTable() {
            const statusTableElement = document.getElementById('statusTable');
            
            if (statusTableElement) {
                // "start": Aligns the element to the top of the viewport.
                // "center": Aligns the element to the center of the viewport.
                // "end": Aligns the element to the bottom of the viewport.
                // "nearest": Scrolls the element to the nearest edge (top or bottom) of the viewport.
                statusTableElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
            }
           
        },
        getColor(x:any,y:any){
            let colorObj = this.colorData.find((obj:any)=>{return obj.coordinates.find((coord:any) => {return coord.x == x && coord.y == y})});
            if(colorObj==undefined){
                return ''
            }else if(colorObj!=undefined && this.hoveredId==0){
                return colorObj.color;
            }else if(colorObj!=undefined && this.hoveredId == colorObj.id){
                return colorObj.color;
            }else{
                return colorObj.hover;
            }
        },
        hoverColor(id:any) {
            this.hoveredId = id;
            this.hoverState = true;
        },
        leaveColor() {
            this.hoveredId = 0;
            this.hoverState = false;
        },
        getTierRisks(id:any){
            let findObj = this.tierLevelDataObj.find((obj:any)=>{return obj._id == id});
            if(findObj != undefined){
                return findObj.count;
            }else{
                return 0
            }
        },
        colorRiskBg(Status: any) {
            if (Status == 10401) {
                return ['bg-darkred', 'opacity-20'];
            } else if (Status == 10402) {
                return ['bg-electricGreen', 'opacity-20'];
            } else if (Status == 10403) {
                return ['bg-primary', 'opacity-20'];
            } else if (Status == 10405) {
                return ['bg-lightgreen', 'opacity-20'];
            } else if (Status == 10406) {
                return ['bg-yellow', 'opacity-20'];
            }
        },
        colorRiskText(Status: any) {
            if (Status == 10401) {
                return ['text-darkred', 'flex justify-center px-2.5 py-1 font-semibold border-solid border border-darkred'];
            } else if (Status == 10402) {
                return ['text-electricGreen', 'flex justify-center px-2.5 py-1 font-semibold border-solid border border-electricGreen'];
            } else if (Status == 10403) {
                return ['text-primary', 'px-2.5 py-1 flex justify-center font-semibold border-solid border border-primary'];
            } else if (Status == 10405) {
                return ['text-lightgreen', 'flex justify-center px-2.5 py-1 font-semibold border-solid border border-lightgreen'];
            } else if (Status == 10406) {
                return ['text-yellow', 'px-2.5 py-1 flex justify-center font-semibold border-solid border border-yellow'];
            }
        },
        colorRiskSeverity(status: any) {
            if (status == 1) {
                return ['font-semibold text-lightgreen'];
            } else if (status == 2) {
                return ['font-semibold text-green'];
            } else if (status == 3) {
                return ['font-semibold text-yellow'];
            } else if (status == 4) {
                return ['font-semibold text-error'];
            } else if (status == 5) {
                return ['font-semibold text-darkred'];
            }
        },
        colorPropBg(scaleScore: any) {
            if (scaleScore == 5) {
                return ['bg-darkred'];
            } else if (scaleScore == 4) {
                return ['bg-error'];
            } else if (scaleScore == 3) {
                return ['bg-yellow'];
            } else if (scaleScore == 2) {
                return ['bg-green'];
            } else if (scaleScore == 1) {
                return ['bg-lightgreen'];
            }
        },
        getCount(Status:any){
            if (Status == 10401) {
                return this.openCount
            } else if (Status == 10402) {
                return this.inProgressCount
            } else if (Status == 10403) {
                return this.AcceptedCount
            } else if (Status == 10405) {
                return this.MitigatedCount
            } else if (Status == 10406) {
                return this.PartialCount
            }
        },
        riskSeverity(value: number) {
            if (value <= 3) {
                return 1;
            } else if (value > 3 && value <= 8) {
                return 2;
            } else if (value > 8 && value <= 12) {
                return 3;
            } else if (value > 12 && value <= 16) {
                return 4;
            } else if (value > 16 && value <= 25) {
                return 5;
            }
        },
        average(arr: any) {
            return arr.reduce((p: any, c: any) => p + c, 0) / arr.length;
        },
        async getSystems() {
            let entityId = '20005';
            await this.$http.get(`${process.env.VUE_APP_ORG_API_URL}/api/org/getall/${entityId}`).then((result: any) => {
                this.systems = result.data;
            });
        },
        async getHeatMapData() {
            await this.$http.get(`${process.env.VUE_APP_RISK_API_URL}/api/org/risk/dashboard/heatmap`).then((result: any) => {
                this.grid = result.data;
            });
        },
        async getTierLevelData(){
            await this.$http.get(`${process.env.VUE_APP_RISK_API_URL}/api/org/tierlevel/dashboard`).then((result: any) => {
                this.tierLevelDataObj = result.data;
            });
        },
        async getTierLevelRisks(tierId:any){
            this.dataLoading2 = true;

            this.tierLevelRiskData = [];
            this.selectedTier = tierId;

            await this.$http.get(`${process.env.VUE_APP_RISK_API_URL}/api/org/tier/${tierId}/risks`).then((result: any) => {            
                result.data.map((risk:any)=>{
                    this.openList = risk;

                    let condition1 = (Object.keys(risk).includes('residual') && risk['residual'].length>0)
                    let averageImpact;
                    let impact;
                    let severity;

                    if(condition1 == true){
                        let lengthOfArray = risk['residual'].length
                        let indexObj = risk['residual'][lengthOfArray-1];
                        
                        if(indexObj.isActive == true){
                            averageImpact = this.average(indexObj['business'], indexObj['operations'], indexObj['legal'], indexObj['financial']);
                            impact = Math.round(averageImpact);
                            severity = this.riskSeverity(impact * indexObj['likelihood']);

                            risk.business = indexObj['business'];
                            risk.operations = indexObj['operations'];
                            risk.legal = indexObj['legal'];
                            risk.financial = indexObj['financial'];
                            risk.likelihood = indexObj['likelihood']
                        }else{
                            averageImpact = this.average([this.openList['business'], this.openList['operations'], this.openList['legal'], this.openList['financial']]);
                            impact = Math.round(averageImpact);
                            severity = this.riskSeverity(impact * this.openList['likelihood']);
                        }
                    }else if(condition1 == false){
                        averageImpact = this.average([this.openList['business'], this.openList['operations'], this.openList['legal'], this.openList['financial']]);
                        impact = Math.round(averageImpact);
                        severity = this.riskSeverity(impact * this.openList['likelihood']);
                    }
                    risk.overallImpact = impact;
                    risk.severity = severity;

                    let tableobj = { entityId:20010,severity: risk.severity,risk: risk.risk, riskCategory:risk.riskCategory,tier:risk.tier }

                    this.tierLevelRiskData.push(tableobj);
                })
            })

            this.dataLoading2 = false
        },
        async getAllTeams() {
			await this.$http.get(`${process.env.VUE_APP_ORG_API_URL}/departments/get`).then((res: any) => {
			for (var i = 0; i < res.data.length; i++) {
				this.departmentList.push({id:res.data[i]._id,value:res.data[i].teamName});
			}
			});
            console.log("this.departmentList",this.departmentList)
		},
        async getTopRisksData(primaryFilter:any,secondaryFilter:any) {
            this.dataLoading3 = true;

            let payload:any = {type:'',subTypeValue:''}

            payload.type = primaryFilter;
            payload.subTypeValue = secondaryFilter;

            console.log('cameeeeeeeeeeeeee',payload)
            if(primaryFilter == 1){
                this.displaySeverityRiskList = []
            }else if(primaryFilter == 2){
                this.displayStatusesRiskList = []
            }else if(primaryFilter == 3){
                this.displayDeptRiskList = []
            }else if(primaryFilter == 4){
                this.displayAgeRiskList = []
            }else if(primaryFilter == 5){
                this.displayTimePeriodRisks = [];
                if(secondaryFilter[0] > secondaryFilter[1]){
                    toast.error(`Start Date Should not be greater End Date`, {
                        timeout: 1000,
                        closeOnClick: true,
                        closeButton: 'button',
                        icon: true
                    });
                    this.startDate = '';
                    this.endDate = '';
                    return ''
                }
            }
            this.topRisks = []

            await this.$http.post(`${process.env.VUE_APP_RISK_API_URL}/api/org/dashboard/risks/filter`,payload).then((result: any) => {
                this.topRisks = result.data;
                this.topRisks.map((risk:any)=>{
                    this.openList = risk;
                    let condition1 = (Object.keys(risk).includes('residual') && risk['residual'].length>0)
                    let averageImpact;
                    let impact;
                    let severity;

                    if(condition1 == true){
                        let lengthOfArray = risk['residual'].length
                        let indexObj = risk['residual'][lengthOfArray-1];
                        
                        if(indexObj.isActive == true){
                            averageImpact = this.average([ indexObj['business'], indexObj['operations'], indexObj['legal'], indexObj['financial']]);
                            impact = Math.round(averageImpact);
                            severity = this.riskSeverity(impact * indexObj['likelihood']);
                        }else{
                            averageImpact = this.average([this.openList['business'], this.openList['operations'], this.openList['legal'], this.openList['financial']]);
                            impact = Math.round(averageImpact);
                            severity = this.riskSeverity(impact * this.openList['likelihood']);
                        }
                    }else if(condition1 == false){
                        averageImpact = this.average([this.openList['business'], this.openList['operations'], this.openList['legal'], this.openList['financial']]);
                        impact = Math.round(averageImpact);
                        severity = this.riskSeverity(impact * this.openList['likelihood']);
                    }
                    risk.overallImpact = impact;
                    risk.severity = severity;

                    // let systemObj = this.systems.find((sys:any)=>{return sys._id == risk.systemId})
                    
                    let tableobj:any = { entityId:20011,severity: risk.severity,risk: risk.risk, riskCategory:risk.riskCategory,tier:risk.tier}

                    if(primaryFilter == 1){
                        this.displaySeverityRiskList.push(tableobj)
                    }else if(primaryFilter == 2){
                        this.displayStatusesRiskList.push(tableobj)
                    }else if(primaryFilter == 3){
                        this.displayDeptRiskList.push(tableobj)
                    }else if(primaryFilter == 4){
                        tableobj.age = risk.daysDifference;
                        this.displayAgeRiskList.push(tableobj)
                    }else if(primaryFilter == 5){
                        this.displayTimePeriodRisks.push(tableobj)
                    }
                })
            });

            this.dataLoading3 = false
        },
        async getDashboardInformation() {
            await this.$http.get(`${process.env.VUE_APP_RISK_API_URL}/api/org/dashboard/risks/allStatus`).then((result: any) => {
                this.risksInformation = result.data;
                if(this.risksInformation.length >0){
                    this.openCount = this.risksInformation[0].open;
                    this.inProgressCount = this.risksInformation[0].inprogress;
                    this.AcceptedCount = this.risksInformation[0].completed;
                    this.MitigatedCount = this.risksInformation[0].mitigated;
                    this.PartialCount = this.risksInformation[0].partial;
                    this.TotalRisksCount = this.openCount + this.inProgressCount + this.AcceptedCount + this.MitigatedCount + this.PartialCount;
                }

            });
        },
        async getZonalRisks(impact:any,likelihood:any){
            this.dataLoading1 = true;

            this.zonefilters.severity = '';
            this.displayChartRisksListInTable = []
            let payload = {impact:impact,likelihood:likelihood}
            await this.$http.post(`${process.env.VUE_APP_RISK_API_URL}/api/org/dashboard/risk`,payload).then((result: any) => {
                this.zonalRiskList = result.data;
                this.zonalRiskList.map((risk:any)=>{
                    this.openList = risk;

                    let condition1 = (Object.keys(risk).includes('residual') && risk['residual'].length>0)
                    let averageImpact;
                    let impact;
                    let severity;

                    if(condition1 == true){
                        let lengthOfArray = risk['residual'].length
                        let indexObj = risk['residual'][lengthOfArray-1];
                        
                        if(indexObj.isActive == true){
                            averageImpact = this.average([ indexObj['business'], indexObj['operations'], indexObj['legal'], indexObj['financial']]);
                            impact = Math.round(averageImpact);
                            severity = this.riskSeverity(impact * indexObj['likelihood']);

                            risk.business = indexObj['business'];
                            risk.operations = indexObj['operations'];
                            risk.legal = indexObj['legal'];
                            risk.financial = indexObj['financial'];
                            risk.likelihood = indexObj['likelihood']
                        }else{
                            averageImpact = this.average([this.openList['business'], this.openList['operations'], this.openList['legal'], this.openList['financial']]);
                            impact = Math.round(averageImpact);
                            severity = this.riskSeverity(impact * this.openList['likelihood']);
                        }
                    }else if(condition1 == false){
                        averageImpact = this.average([this.openList['business'], this.openList['operations'], this.openList['legal'], this.openList['financial']]);
                        impact = Math.round(averageImpact);
                        severity = this.riskSeverity(impact * this.openList['likelihood']);
                    }
                    risk.overallImpact = impact;
                    risk.severity = severity;

                    let systemObj = this.systems.find((sys:any)=>{return sys._id == risk.systemId})

                    let tableobj = { entityId:20010,severity: risk.severity,risk: risk.risk, riskCategory:risk.riskCategory,tier:risk.tier }

                    this.displayChartRisksListInTable.push(tableobj);
                })
            });

            this.dataLoading1 = false;
        },
        async getAllRisksList() {
            this.allRiskList = [];
            this.series = [];
            await this.$http.get(`${process.env.VUE_APP_RISK_API_URL}/api/org/risks/getall`).then((result: any) => {
                
                this.allRiskList = result.data.filter((obj:any)=>{return obj.statusCode != 10404 && Object.keys(obj).includes('priority')})

                let priorityObject:any = { 'P1':0,'P2':0, 'P3':0, 'P4':0, 'P5':0 };

                this.allRiskList.map((risk:any)=>{
                    this.openList = risk;

                    let key = `P${6 - risk.priority}`;
                    priorityObject[key] = priorityObject[key] + 1;

                    let condition1 = (Object.keys(risk).includes('residual') && risk['residual'].length>0)
                    let averageImpact;
                    let impact;
                    let severity;

                    if(condition1 == true){
                        let lengthOfArray = risk['residual'].length
                        let indexObj = risk['residual'][lengthOfArray-1];
                        
                        if(indexObj.isActive == true){
                            averageImpact = this.average([ indexObj['business'], indexObj['operations'], indexObj['legal'], indexObj['financial']]);
                            impact = Math.round(averageImpact);
                            severity = this.riskSeverity(impact * indexObj['likelihood']);

                            risk.business = indexObj['business'];
                            risk.operations = indexObj['operations'];
                            risk.legal = indexObj['legal'];
                            risk.financial = indexObj['financial'];
                            risk.likelihood = indexObj['likelihood']
                        }else{
                            averageImpact = this.average([this.openList['business'], this.openList['operations'], this.openList['legal'], this.openList['financial']]);
                            impact = Math.round(averageImpact);
                            severity = this.riskSeverity(impact * this.openList['likelihood']);
                        }
                    }else if(condition1 == false){
                        averageImpact = this.average([this.openList['business'], this.openList['operations'], this.openList['legal'], this.openList['financial']]);
                        impact = Math.round(averageImpact);
                        severity = this.riskSeverity(impact * this.openList['likelihood']);
                    }
                    risk.overallImpact = impact;
                    risk.severity = severity;
                })

                this.series = Object.values(priorityObject)

            })
        }

    },
});
</script>

