<template>
    <div class="navbar bg-white p-0 sticky z-50 top-0 left-0 flex shadow">
        <div class="w-40 flex items-center">
            <div class="lg:flex pr-2 pl-2.5 cursor-pointer" @click="ontoggle()">
                <img src="@/assets/menu.svg" class="menuIcon cursor-pointer" />
            </div>
            <div class="lg:flex cursor-pointer">
                <span class="text-lg font-bold cursor-pointer">
                    <img src="@/assets/dynematrix.svg" alt="" class="w-40" />
                </span>
            </div>
        </div>
        <div class="flex-1 px-2 mx-2 h-full" @click="closeApps"></div>
        <div class="flex-none">
            <div @click="popupLogout()"  ref="logoutIconContainer">
                <img :src="userInfo.picture" class="rounded-full m-1 mr-4 w-8 h-8 cursor-pointer" v-if="userInfo.picture" />
                <div class="m-1 mr-2 w-8 h-8 rounded-full bg-gray text-2xl cursor-pointer" v-else>
                    <div class="flex justify-center items-center h-full text-white cursor-pointer">
                        {{ getUserName() }}
                    </div>
                </div>
                <!-- <img src="@/assets/tooltip.svg" alt="" class="w-8 h-8"> -->
                <div v-if="showBlock" class="py-1 -mt-1 bg-white absolute right-4 shadow card w-80" ref="logoutCardContainer">
                    <div class="py-2 text-gray cursor-pointer flex flex-col items-center">
                        <div v-if="userInfo.picture">
                            <img :src="userInfo.picture" class="rounded-full m-1 mr-2 w-12 h-12 cursor-pointer" />
                        </div>
                        <div class="m-1 mr-2 w-12 h-12 rounded-full bg-gray text-2xl cursor-pointer" v-else>
                            <div class="flex justify-center items-center h-full text-white cursor-pointer">
                                {{ getUserName() }}
                            </div>
                        </div>
                        <div class="px-4 text-base mt-1">{{ userInfo.name }}</div>

                        <div class="px-4 text-xs mt-1">
                            {{ userInfo.email }}
                        </div>
                        <div class="border-b border-solid border-lightgrey w-full mt-2"></div>
                        <p v-if="displayName" class="font-bold py-2.5">{{ displayName }}</p>
                        <p v-else class="inline-block invisible">DisplayName</p>
                        <div class="border-b border-solid border-lightgrey w-full"></div>
                        <div class="flex justify-center items-center mt-2">
                            <button class="filterButton flex mt-1" @click="logout()">Log Out</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<style  scoped>
.menuIcon{
	width: 22px !important;
	height: 18px !important;
}
.rounded-full:hover {
    box-shadow: 0px 0px 3px 4px rgba(0, 0, 0, 0.1);
}
.navbar {
    min-height: 2.5rem;
    height: 2.5rem;
}

.right-4 {
    box-shadow: 1px 2px 6px rgba(0, 0, 0, 0.08);
}
.w-40 {
    width: 170px;
}
</style>
<script lang="ts">
import { mapGetters } from 'vuex';
import { defineComponent } from 'vue';
import { emitter, toast } from '../main';
export default defineComponent({
    data(): any {
        return {
            showBlock: false,
            isToggle: false,
            displayName: ''
        };
    },
    computed: {
        ...mapGetters({
            userInfo: 'userInfo'
        })
    },
    methods: {
        getUserName() {
            return this.userInfo.name.charAt(0).toUpperCase();
        },
		popupLogout() {
            this.showBlock = !this.showBlock;
            emitter.emit('apps-sidemenubar', false);
        },
        ontoggle() {
            this.isToggle = !this.isToggle;
            this.showBlock = false;
            emitter.emit('apps-sidemenubar', this.isToggle);
        },
        closeApps() {
            this.isToggle = false;
            this.showBlock = false;
            emitter.emit('apps-sidemenubar', this.isToggle);
        },
        async logout() {
            var payload = {
                headers: {
                    Authorization: 'Bearer ' + localStorage.getItem('AUTH_ID')
                }
            };
            await this.$http
                .post(`${process.env.VUE_APP_API_URL}/${process.env.VUE_APP_AUTH_API_PATH}/logout`, payload)
                .then((response: any) => {
                    if (response.status == 200) {
                        toast.info(`Uploaded succesfully`, {
                            closeOnClick: true,
                            closeButton: 'button'
                        });
                    }
                })
                .catch((error: any) => {
                    toast.error(`Save failed with ${error}`, {
                        timeout: 1000,
                        closeOnClick: true,
                        closeButton: 'button',
                        icon: true
                    });
                });

            localStorage.removeItem('AUTH_ID');
            window.location.replace(`${process.env.VUE_APP_AUTH_WEBAPP_URL}/logout?returnTo=${process.env.VUE_APP_RISK_WEBAPP_URL}`);
        },
        triggerNavigation() {
            window.location.href = `${process.env.VUE_APP_AUTH_WEBAPP_URL}/session?returnTo=${process.env.VUE_APP_TRAINING_WEBAPP_URL}`;
        },
        async getAllTenants() {
            this.$http.get(`${process.env.VUE_APP_AUTH_API_URL}/tenants/getAll`).then((res: any) => {
                this.allTenants = res.data;
                console.log('aaa', this.allTenants);
                this.allTenants.filter((tenant: any) => {
                    let userDomain = this.userInfo.email.substring(this.userInfo.email.lastIndexOf('@') + 1);
                    let userOrgDetails = userDomain.split('.')[0];

                    if (tenant.tenantName!= undefined && userOrgDetails.toLowerCase() == tenant.tenantName.toLowerCase()) {
                        this.displayName = tenant.displayName;
                    }
                });
            });
        }
    },
    mounted() {
        this.getAllTenants();
        emitter.on('apps', (isToggle: any) => {
            this.isToggle = !this.isToggle
            this.showBlock = isToggle
        });
        }
   
});
</script>


