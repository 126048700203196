<template>
	<div id="body-container">
		<NavBar />
		<div class="flex" @click="closeApps()">
			<div :class="{ 'w-40': isToggling, 'w-10': !isToggling }">
				<MenuBar />	
			</div>
			<div class="content flex-1 w-full h-screen overflow-y-scroll" :class="{ toggledstate: isToggling }"  @scroll="handleScroll($event)">
				<div class="page">
					<slot />
				</div>
				<Footer/>
			</div>
		</div>	
	</div>
</template>
<style>
.page{
	margin-bottom: 100px;
	
}

</style>
<script lang="ts">
import NavBar from '../components/navBar.vue';
import MenuBar from '../components/sidemenubar.vue';
import Footer from '../components/footer.vue'


import { defineComponent } from 'vue';
import { emitter } from '../main';
import { mapGetters } from 'vuex';
export default defineComponent({
	data():any {
		return {
			isToggling: true,
		};
	},
	 computed:{
        ...mapGetters({ selectedOptionName: 'val',toggleState:'toggle' })
    },
	components: {
		NavBar,
		MenuBar,
		Footer
	},
	methods:{
		closeApps(){
			this.appsVisible=false;
			emitter.emit('apps', this.appsVisible);
			},
		handleScroll(event:any) {
			if (this.$route.path.includes('/processInformation')||this.$route.path.includes('/organizationInformation')||this.$route.path.includes('/systemInformation')||this.$route.path.includes('/org/risks')||this.$route.path.includes('/org/threat')||this.$route.path.includes('/org/vulnerabilities')){
				emitter.emit('close-dropdown'); // Emit event to close dropdown on scroll
			}
		}
	},
	mounted() {
		this.isToggling = this.toggleState;
        emitter.on('toggle-sidebar', (isToggle: any) => {
            this.isToggling = isToggle;
        });
	}
});
</script>
