<template>
    <div id="tooltipContainer" :style="styling" class="bg-primary rounded text-white manualStyle absolute text-sm z-50 pointer-events-none">       
        <div class="py-1 px-1.5 fullText" :class="styleBold == true ? 'font-bold':''">
            {{ tipText }}
        </div>
    </div>
</template>

<style scoped>
.fullText{
    white-space: break-spaces;
    font-size: 14px;
}
.manualStyle {
    position: fixed;
    max-width: 400px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}
</style>

<script lang="ts">

import { defineComponent } from 'vue';

export default defineComponent({
    props: ['tipText','iconName','combinations'],
    data(): any {
        return {
            mouseX: 0,
            mouseY: 0,
            rightEdgeThreshold: false,
            bottomEdgeThreshold: false,
            viewportWidth:'',
            viewportHeight:'',
            bottomAlignment:false,
            tipWidth:'',
            tipHeight:''
        }
    },
    computed: {
		styling(): any {
            this.viewportHeight = window.innerHeight;
             this.viewportWidth = window.innerWidth;

            this.rightEdgeThreshold = this.mouseX > this.viewportWidth * 0.75;
            this.bottomEdgeThreshold = this.mouseY > this.viewportHeight * 0.55;

            let topPosition = this.mouseY + 10;
            let leftPosition = this.mouseX + 10;

            const container = document.getElementById('tooltipContainer');
            // let tipWidth: any;
            let tipHeight: any;

            if (container) {
                const tip = container.getBoundingClientRect();
                this.tipWidth = tip.width;
                tipHeight = tip.height;
                this.tipHeight = tipHeight
            }
            if (this.rightEdgeThreshold && this.tipWidth > 100) {                
                this.bottomAlignment = false
                leftPosition = this.mouseX - this.tipWidth - 10;
            }

            if (this.bottomEdgeThreshold && tipHeight > 60) {
                this.bottomAlignment= true
                topPosition = this.mouseY - tipHeight - 30;
            }

            return {
                top: `${topPosition}px`,
                left: `${leftPosition}px`
            };
        },

        styleBold: function (): any{

            let found = this.combinations.find((obj:any)=>{
                return obj.tiptext == this.tipText && obj.option == this.iconName
            })

            if(found != undefined){
                return true
            }else{
                return false
            }
        }
    },
    mounted() {
        // document.addEventListener('click', this.updateMousePosition);
        document.addEventListener('mouseover', this.updateMousePosition);
        document.addEventListener('mousemove', this.updateMousePosition);
    },
    beforeDestroy() {
        // document.removeEventListener('click', this.updateMousePosition);
        document.removeEventListener('mouseover', this.updateMousePosition);
        document.removeEventListener('mousemove', this.updateMousePosition);
    },
    methods: {
        updateMousePosition(event: any) {
            this.mouseX = event.clientX;
            this.mouseY = event.clientY;
        },
    },
})
</script>
