import { vModelText as _vModelText, withKeys as _withKeys, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, withDirectives as _withDirectives, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-36b2825d"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "row flex flex-row w-full" }
const _hoisted_2 = ["placeholder"]
const _hoisted_3 = {
  key: 0,
  class: "absolute mt-1 z-50 suggestionWidth"
}
const _hoisted_4 = { class: "w-full pl-1.5 bg-white border border-lightgrey rounded overflow-y-auto max-h-36" }
const _hoisted_5 = ["onClick", "onMousemove"]
const _hoisted_6 = { key: 0 }
const _hoisted_7 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      class: _normalizeClass(["relative w-full", {'editPage':this.$route.path.includes('edit')}])
    }, [
      _withDirectives(_createElementVNode("input", {
        type: "text",
        placeholder: _ctx.placeholderValue,
        class: _normalizeClass(["inputboxstyling", {
                    'is-invalid': _ctx.error
                }]),
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.modelData) = $event)),
        onInput: _cache[1] || (_cache[1] = ($event: any) => (_ctx.filteredCategories($event.target.value, $event))),
        onKeydown: [
          _cache[2] || (_cache[2] = _withKeys(
//@ts-ignore
(...args) => (_ctx.onArrowDown && _ctx.onArrowDown(...args)), ["down"])),
          _cache[3] || (_cache[3] = _withKeys(
//@ts-ignore
(...args) => (_ctx.onArrowUp && _ctx.onArrowUp(...args)), ["up"])),
          _cache[4] || (_cache[4] = _withKeys(($event: any) => (_ctx.setState(_ctx.selectedCategory[_ctx.counter])), ["enter"]))
        ]
      }, null, 42, _hoisted_2), [
        [_vModelText, _ctx.modelData]
      ]),
      (_ctx.autoSugestion)
        ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
            _createElementVNode("ul", _hoisted_4, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.selectedCategory, (category, i) => {
                return (_openBlock(), _createElementBlock("li", {
                  key: category,
                  onClick: ($event: any) => (_ctx.setState(category)),
                  onMousemove: ($event: any) => (_ctx.counter=i),
                  class: _normalizeClass(["p-1 text-sm over-y-auto max-h-60 cursor-pointer", {'bg-primary text-white': i === _ctx.counter }])
                }, [
                  (typeof(category)==`object`)
                    ? (_openBlock(), _createElementBlock("span", _hoisted_6, _toDisplayString(category.name), 1))
                    : (_openBlock(), _createElementBlock("span", _hoisted_7, _toDisplayString(category), 1))
                ], 42, _hoisted_5))
              }), 128))
            ])
          ]))
        : _createCommentVNode("", true)
    ], 2)
  ]))
}