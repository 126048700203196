<template>
    <div class="p-4">
        <div class="flex items-center justify-center h-10 space-x-2.5">
            <!-- <h1 class="text-xl">Loading</h1> -->
            <div class="ball"></div>
            <div class="ball"></div>
            <div class="ball"></div>
            <div class="ball"></div>
            <div class="ball"></div>
            <div class="ball"></div>
        </div>
    </div>
</template>

<style scoped>
.ball {
    height: 14px;
    width: 14px;
    background-color: #e9e9e9; /* Initial background color */
    border-radius: 50%;
    margin-right: 5px; /* Adjust as needed */
    animation: bounce-sequences 2s linear infinite; /* Total time for one complete sequence */
    transition: ease-in-out;
    transition: background-color 250ms;
}

/* Define the bouncing sequence */
@keyframes bounce-sequences {
    5% {
        background-color: #152a43; /* Blue color during bounce */
    }
    10% {
        transform: translateY(-14px);
        background-color: #152a43; /* Blue color during bounce */
    }
    20% {
        background-color: #152a43; /* Blue color during bounce */
    }
    30% {
        transform: translateY(0);
        background-color: #e9e9e9; /* Back to initial color */
    }
    0%,100% {
        background-color: #e9e9e9; /* Initial background color */
    }
}

/* Delay each ball's animation */
.ball:nth-child(1) {
    animation-delay: 0s; /* Delay for the first ball */
}

.ball:nth-child(2) {
    animation-delay: 0.3s; /* Adjusted delay for the second ball */
}

.ball:nth-child(3) {
    animation-delay: 0.6s; /* Adjusted delay for the third ball */
}

.ball:nth-child(4) {
    animation-delay: 0.9s; /* Adjusted delay for the fourth ball */
}

.ball:nth-child(5) {
    animation-delay: 1.2s; /* Adjusted delay for the fifth ball */
}

.ball:nth-child(6) {
    animation-delay: 1.5s; /* Adjusted delay for the sixth ball */
}
</style>
