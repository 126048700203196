<template>
    <div class="row flex flex-row w-full">
        <div class="relative w-full" :class="{'editPage':this.$route.path.includes('edit')}">
            <input
                type="text"
                :placeholder="placeholderValue"
                class="inputboxstyling"
                v-model="modelData"
                @input="filteredCategories($event.target.value, $event)"
                @keydown.down="onArrowDown"
                @keydown.up="onArrowUp"
                @keydown.enter="setState(selectedCategory[counter])"
                :class="{
                    'is-invalid': error
                }"
            />
            <div class="absolute mt-1 z-50 suggestionWidth" v-if="autoSugestion">
                <ul class="w-full pl-1.5 bg-white border border-lightgrey rounded overflow-y-auto max-h-36">
                    <li v-for="(category,i) in selectedCategory" :key="category" @click="setState(category)" @mousemove="counter=i" class="p-1 text-sm over-y-auto max-h-60 cursor-pointer" :class="{'bg-primary text-white': i === counter }">
                        <span v-if="typeof(category)==`object`">{{category.name}}</span>
                        <span v-else>{{category}}</span>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</template>
<style scoped>
.suggestionWidth {
    width: inherit;
    padding-right: 16px;
}
</style>
<script lang="ts">
import { defineComponent } from 'vue';
import _ from 'lodash';
import useVuelidate from '@vuelidate/core';

export default defineComponent({
    props: ['dataprops', 'inputValue', 'placeholderValue', 'error','id'],
    data(): any {
        return {
            v$: useVuelidate(),
            popUp: false,
            categories: [],
            autoSugestion: false,
            selectedCategory: [],
            modelData: '',
            counter:-1,
        };
    },
    watch: {
        inputValue:{
            handler(newVal, oldVal) {
            this.modelData = newVal;
        },
        deep:true
    },
    },
    created() {
        this.modelData = this.inputValue;
        window.addEventListener('click', (e) => {
            this.autoSugestion = false;
        });
    },

    methods: {
        async filteredCategories(filterColumn: any, x: any) {
            console.log("this.hiiiii",this.dataprops)
            await this.$emit('name', this.modelData, this.placeholderValue);
            this.counter = -1;
            this.autoSugestion = true;
            this.selectedCategory = this.dataprops;

            if (filterColumn == '' || this.selectedCategory.length == 0) {
                this.autoSugestion = false;
            }
        },
        setState(state: any) {
            console.log("this.stateeeeeeeeea",state)
            if(state==undefined){
                this.$emit('name', this.modelData, this.placeholderValue)
            }else{
                if(typeof(state)==`object`){
                    this.modelData = state.name;
                    this.$emit('name', state.id, this.placeholderValue)
                }else{
                    this.modelData = state;
                    this.$emit('name', state, this.placeholderValue)
                }
                
            }
            // state==undefined? :(,this.modelData = state)
            this.counter = -1;
            console.log('state123');
            this.autoSugestion = false;
            // this.$emit('name', state, this.placeholderValue);
        },
        onArrowDown() {
            this.counter == (this.selectedCategory.length-1) ? (this.counter=0 ):(this.counter = this.counter + 1);
        },
        onArrowUp() {
            this.counter == -1||this.counter == 0  ? (this.counter=this.selectedCategory.length-1) : (this.counter = this.counter - 1);
        },
        // async getInformationSetsforOrg() {
        //     await this.$http.get(`${process.env.VUE_APP_API_URL}/${process.env.VUE_APP_RISK_API_PATH}/api/root/information/getall?org=true`).then((result: any) => {
        //         this.savedInformationSets = result.data;
        //         console.log("sssss",this.savedInformationSets)
        //         this.savedInformationSets.forEach((information: any) => {
        //             this.categories.push(information.informationCategory);
        //         });
        //         this.categories = [...new Set(this.categories)];
        //         console.log('categoreies', this.categories);
        //     });
        // },
    }
});
</script>
