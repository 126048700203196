<template>
    <div class="pt-3 pl-5 pr-5">
        <div class="row w-full justify-between flex" style="align-items: center">
            <h1 class="title">Threat</h1>
            <div class="cardbody">
                <div class="flex flex-row-reverse items-center">
                    <div class="flex flex-row-reverse items-center">
                        <button class="flex ml-3" @click="filterOpen" :class="threatList.length == 0 ? 'btndisabled pointer-events-none' : 'filterButton'">
                            Filters
                            <img src="@/assets/filter.svg" class="h-3 ml-2.5" />
                        </button>
                        <button class="btnprimary ml-3" v-if="pageType == false" @click="showPopup()">Add</button>
                    </div>
                </div>
            </div>
        </div>
        <div></div>
        <div class="mt-3 text-xs">
            <div>
            Risk management is the process of identifying, assessing and controlling financial, legal, strategic and security risks to an organization’s capital and earnings. These threats, or risks, could stem from a wide variety of sources, including financial uncertainty, legal liabilities,
            strategic management errors, accidents and natural disasters.To reduce risk, an organization needs to apply resources to minimize, monitor and control the impact of negative events while maximizing positive events. A consistent, systemic and integrated approach to risk management can
            help determine how best to identify, manage and mitigate significant risks.
            </div>
        </div>

        <div>
            <newTable :tableRowData="threatList" :headersData="headers" :openPopup="handlePopUp" :loadData="dataLoading"></newTable>
            <div v-if="toolTipActive">
                <tool-tip :tipText="toolTipText"></tool-tip>
            </div>
        </div>

        <div>
            <popup v-if="isShow">
                <template v-slot:header>
                    <div class="flex items-center justify-between popupheadingcontainer rounded">
                        <h1>Threat</h1>
                        <button class="float-right text-3xl" @click="cancelBtn('close')">
                            <img src="@/assets/cancel.svg" class="h-5 w-5 cursor-pointer" />
                        </button>
                    </div>
                </template>
                <div class="statuscontainer popupbodycontainer">
                    <div class="formcontainer flex flex-col">
                        <div class="row flex flex-row w-full upperspacing">
                            <div class="w-full">
                                <label class="controllabel">Threat ID</label>
                                <input
                                    v-model.trim="selectedThreat.threatId"
                                    placeholder="Threat Id"
                                    id="id"
                                    class="inputboxstyling"
                                    @input ="restrictCharacters1('threatId')"
                                    :class="{
                                        'is-invalid': (v$.selectedThreat.threatId.$error||checkThreatId)
                                    }"
                                />
                                <div v-if="v$.selectedThreat.threatId.$error" class="text-red-500">
                                    <div class="error-text text-xs" v-if="v$.selectedThreat.threatId.required.$invalid">Required</div>
                                </div>
                                <div v-else-if="displayErrorMessageOfThreatId == true">
                                    <div class="text-red-500 text-xs">Character Limit is exceeded</div>
                                </div>
                                <div v-else-if="checkThreatId">
                                    <div class="text-red-500 text-xs">Threat Id already Exists</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="formcontainer mt-2 flex flex-col">
                        <div class="w-full">
                            <label class="controllabel">Threat</label>
                            <!-- <dropdown :dataprops="selectedCategory" :inputValue="selectedThreat.threatEvent" :placeholderValue="'Threat'" @name="getName" :error="v$.selectedThreat.threatEvent.$error"></dropdown> -->
                            <textarea class="inputboxstyling" placeholder="Threat" v-model.trim="selectedThreat.threatEvent"  @change="resetEventError"  @input="v$.selectedThreat.threatEvent.$touch()" :class="{'is-invalid':(v$.selectedThreat.threatEvent.$error || isDuplicateCombination == true || isDuplicateEvent == true)}" :error="(v$.selectedThreat.threatEvent.$error|| isDuplicateCombination == true || isDuplicateEvent == true)" />
                            <div v-if="v$.selectedThreat.threatEvent.$error" class="text-red-500">
                                <div class="error-text text-xs" v-if="v$.selectedThreat.threatEvent.required.$invalid">Required</div>
                                <div v-else-if="v$.selectedThreat.threatEvent.required && v$.selectedThreat.threatEvent.restrictCharacters.$invalid" class="error-text text-xs">Charcter Limit is exceeded</div>
                            </div>
                            <!-- <div v-else-if="displayErrorMessage == true">
                                <div class="text-red-500 text-xs">Character Limit is exceeded</div>
                            </div> -->
                            <div v-else-if="isDuplicateEvent == true">
                                <div class="text-red-500 text-xs">Threat already exists</div>
                            </div>
                            <div v-else-if="isDuplicateCombination == true">
                                <div class="text-red-500 text-xs">Threat already exists</div>
                            </div>
                        </div>
                    </div>
                    <div class="formcontainer flex flex-col">
                        <div class="row flex flex-row w-full upperspacing">
                            <div class="w-3/6">
                                <label class="controllabel">Threat Source</label><Multiselect
                                    v-model.trim="selectedThreat.threatSource"
                                    :options="searchThreatSourceList"
                                    @search-change="asyncthreatSourceDataFind"
                                    @select="selectedThreatSourceOption"
                                    :multiple="false"
                                    :searchable="true"
                                    @change="resetSourceError"
                                    :hide-selected="true"
                                    open-direction="bottom"
                                    :close-on-select="true"
                                    :clear-on-select="true"
                                    :hideArrow="false"
                                    placeholder="Threat Source"
                                    deselectLabel=""
                                    :showNoOptions="false"
                                    selectLabel=""
                                    class="custom-multiselect userSelect"
                                    :class="{
										'is-invalid redBorder': v$.selectedThreat.threatSource.$error||isDuplicateSource||isDuplicateCombination,
                                        'has-options': searchThreatSourceList && searchThreatSourceList.length > 0,
                                        'no-options': !searchThreatSourceList.length && searchValue !== '',
                                    }"
									>
                                    <template v-slot:noResult>
                                        <span class="text-xs">No Source Found</span>
                                    </template>
                                </Multiselect>
                                <!-- <dropdown :dataprops="selectedCategory" :inputValue="selectedThreat.threatSource" :placeholderValue="'Threat Source'" @name="getName"></dropdown> -->
                                <!-- <dropdown :dataprops="selectedCategory" :inputValue="selectedThreat.threatSource" :placeholderValue="'Threat Source'" @name="getName" :error="v$.selectedThreat.threatSource.$error"></dropdown> -->
                                <div v-if="v$.selectedThreat.threatSource.$error" class="text-red-500">
                                    <div class="error-text text-xs" v-if="v$.selectedThreat.threatSource.required.$invalid">Required</div>
                                </div>
                                <div v-if="isDuplicateSource"  class="text-red-500">
                                    <div class="error-text text-xs">Threat Source already exists</div>
                                </div>
                            <div v-else-if="isDuplicateCombination">
                                <div class="text-red-500 text-xs">Threat Source already exists</div>
                            </div>
                            </div>

                            <div class="w-3/6 leftspacing">
                                <div>
                                    <label class="controllabel">Threat Category</label><Multiselect
											v-model.trim="selectedThreat.threatCategory"
											:options="searchThreatCategoryList"
											@search-change="asyncthreatCategoryDataFind"
											@select="selectedOption"
											:multiple="false"
											:searchable="true" 
                                            @change="resetCategoryError"
											:hide-selected="true"
											open-direction="bottom"
											:close-on-select="true"
											:clear-on-select="true"
											:hideArrow="false"
											placeholder="Threat Category"
											deselectLabel=""
											:showNoOptions="false"
											selectLabel=""
											class="custom-multiselect userSelect"
											:class="{
										        'is-invalid redBorder': v$.selectedThreat.threatCategory.$error||isDuplicateCategory||isDuplicateCombination,
												'has-options': searchThreatCategoryList && searchThreatCategoryList.length > 0,
                                                'no-options': !searchThreatCategoryList.length && searchValue !== '',
											}"
										>
											<template v-slot:noResult>
												<span class="text-xs">No Categories Found</span>
											</template>
										</Multiselect>
                                    <!-- <dropdown :dataprops="selectedCategory" :inputValue="selectedThreat.threatCategory" :placeholderValue="'Threat Category'" @name="getName"></dropdown> -->
                                    <!-- <dropdown :dataprops="selectedCategory" :inputValue="selectedThreat.threatCategory" :placeholderValue="'Threat Category'" @name="getName" :error="v$.selectedThreat.threatCategory.$error"></dropdown> -->
                                    <div v-if="v$.selectedThreat.threatCategory.$error" class="text-red-500">
                                        <div class="error-text text-xs" v-if="v$.selectedThreat.threatCategory.required.$invalid">Required</div>
                                    </div>
                                    <div v-else-if="isDuplicateCategory" class="text-red-500">
                                        <div class="error-text text-xs">Threat Category already exists</div>
                                    </div>
                                    <div v-else-if="isDuplicateCombination">
                                        <div class="text-red-500 text-xs">Threat Category already exists</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="flex justify-center items-center mt-2">
                        <button :disabled="clickOnButton" class="btnprimary mr-1.5" :class="disableStatus ? 'btnprimary' : 'btndisabled pointer-events-none'" @click="cancelBtn(selectedThreat._id)">Cancel</button>
                        <button :disabled="clickOnButton" @click="addOrUpdatethreat(selectedThreat)" v-if="!selectedThreat._id" :class="disableStatus ? 'btnprimary' : 'btndisabled pointer-events-none'">Save</button>
                        <button :disabled="clickOnButton" class="btnprimary mr-1.5" v-else @click="addOrUpdatethreat()" :class="disableStatus ? 'btnprimary' : 'btndisabled pointer-events-none'">Update</button>
                    </div>
                </div>
            </popup>
        </div>
    </div>
</template>
<style scoped>
.positiontooltip,
.lasttooltip {
    font-size: 12px;
}
.tip {
    display: inline;
}
.z-index {
    z-index: 1000;
}
.redBorder {
	border: 1px solid red !important;
	border-radius: 5px !important;
}
</style>
<script lang="ts">
import { defineComponent } from 'vue';
import _ from 'lodash';
import * as XLSX from 'xlsx';
import { required } from '@vuelidate/validators';
import Multiselect from 'vue-multiselect';
import useVuelidate from '@vuelidate/core';
import popup from '@/components/popup.vue';
import { emitter, toast } from '@/main';
import Loader from '@/components/loader.vue';
import dropdown from '@/components/dropdown.vue';
import newTable from '@/components/newTable.vue';
import toolTip from '@/components/toolTip.vue';
import { helpers } from '@vuelidate/validators';
const restrictCharacters = helpers.regex(/^.{0,300}$/);

export default defineComponent({
    data(): any {
        return {
            suggestionSwitch: '',
            filter: {},
            pop: false,
            xPos: '',
            yPos: '',
            threatcopy: '',
            b: false,
            v$: useVuelidate(),
            threatList: [],
            disabled: 1,
            updatedThreatArray: [],
            showFilters: false,
            clickOnButton: false,
            currentSort: 'name',
            currentSortDir: 'asc',
            threatCategoryArray:[],
            threatSourceArray:[],
            classSort: '',
            selectedCategory: [],
            searchValue: '',
            c: false,
            className: '',
            selected: '',
            riskList: [],
            isShow: false,
            dummyList: [],
            headers: ['threatId', 'threatSource', 'threatCategory', 'threatEvent', '_id'],
            mandatoryKeys: ['ThreatCategory', 'ThreatSource', 'Threatevent', 'threatId'],
            fullKeys: ['ThreatCategory', 'ThreatSource', 'Threatevent', 'ThreatId'],
            dummydata: [],
            searchThreatSourceList:[],
            searchThreatCategoryList:[],
            selectedThreat: { threatId: '', threatCategory: '', threatSource: '', threatEvent: '' },
            result: false,
            objCopy: {},
            autoSuggestion: false,
            handlePopUp: true,
            toolTipActive : false,
            toolTipText : '',
            dataLoading:true,
			displayErrorMessage : false,
            displayErrorMessageOfThreatId: false,
            allThreatIds:[],
            threadIdExcludingSelectedThreatId:[],
            uniqueThreatId:false,
            editPopup:false,
            pageType:true,
            allThreatSourcesArray:[],
            allThreatCategoryArray:[],
            displayThreatCategoryErrorMessage:false,
            // isDuplicateSource: false,
            uniqueThreat: false,
            allThreatEventsExcludingSelectedThreat:[],
            allThreatCategoryExcludingSelectedThreat:[],
            allThreatSourcesExcludingSelectedThreat:[],
            allThreatEvents:[],
            isDuplicateEvent: false,
            isDuplicateSource: false,
            isDuplicateCategory: false,
            isDuplicateCombination: false,threatExcludingSelectedThreat:[]
        };
    },
    validations() {
        return {
            selectedThreat: {
                threatCategory: { required },
                threatSource: { required },
                threatEvent: { required,restrictCharacters },
                threatId: { required }
            }
        };
    },
    components: {
		Multiselect,
        popup,
        dropdown,
        newTable,
        toolTip
    },
    async mounted() {
        this.dataLoading = true;

        if (this.$route.path.includes('root')) {
            this.pageType = true
            await this.getRootThreatList();
        } else if (this.$route.path.includes('org')) {
            this.pageType = false
            await this.getOrgThreatList();
        }
        console.log("this.$route.path.includes('root')", this.$route.path.includes('root'));
        this.handleActionHeaders();

        this.dataLoading = false;

        emitter.off('activiteToolTip');
        emitter.on('activiteToolTip', (data: any) => {
            this.toolTipActive = true;
            this.toolTipText = data.tipText
        });
 
        emitter.off('deActiviteToolTip');
        emitter.on('deActiviteToolTip', (data: any) => {
            this.toolTipActive = false;
            this.toolTipText = ''
        });

        emitter.off('showPopupData');
        emitter.off('showData123');
        emitter.on('showData123', (isData: any) => {
            this.threadIdExcludingSelectedThreatId = []
            this.showEditPopUp(isData.entityData);
            console.log("kjfhdgjdsg",isData)
            this.threatList.forEach((threat: any) => {
                if(isData.entityData._id != threat._id){
                    this.threadIdExcludingSelectedThreatId.push(threat.threatId)
                }
            });
        });
        emitter.off('deleteData');
        emitter.on('deleteData', (isData: any) => {
            this.showDeletePopUp(isData.entityData);
        });
        emitter.on('showPopupData', (isData: any) => {
            this.threadIdExcludingSelectedThreatId = []
            this.threatExcludingSelectedThreat = []
            console.table(isData);
            this.showEditPopUp(isData.entityData);
            this.threatList.forEach((threat: any) => {
                if(isData.entityData._id != threat._id){
                    this.threadIdExcludingSelectedThreatId.push(threat.threatId)
                    this.threatExcludingSelectedThreat.push(threat)
                }
            });
        });
    },
    computed: {
        disableStatus: function (): any {
            let disableStatusCondition;
            if (this.b) {
                let objCopy = Object.assign({}, this.selectedThreat);
                if (JSON.stringify(objCopy) == JSON.stringify(this.obj)) {
                    disableStatusCondition = false;
                } else {
                    disableStatusCondition = true;
                }
            } else {
                let val: any = [];
                Object.values(this.selectedThreat).forEach((value: any) => {
                    val.push(value);
                });
                for (let n of val) {
                    if (n != '') {
                        disableStatusCondition = true;
                    }
                }
            }
            return disableStatusCondition;
        },
        e: function (): any {
            return false;
        },
        checkThreatId: function (): any {
            const trimmedThreatId = this.selectedThreat.threatId.trim().toLowerCase();
            if(this.allThreatIds == null || this.allThreatIds == undefined || this.allThreatIds.length == 0){
                return false;
            }
            if(this.editPopup == false){
                console.log("kjhgfddfghjkjhgfd2",this.editPopup == false);
                let a = false;
                a = this.allThreatIds.map((name: any) => name.toLowerCase()).includes(trimmedThreatId);
                return a;
            }else{
                console.log("kjhgfddfghjkjhgfd2",this.editPopup == false);
                let a = false;
                a = this.threadIdExcludingSelectedThreatId.map((name: any) => name.toLowerCase()).includes(trimmedThreatId);
                return a;
            }
        },
    },
    async created() {
        window.addEventListener('click', (e) => {
            this.autoSuggestion = false;
        });
        emitter.off('rowData')
    },
    methods: {
        restrictCharacters1(code: any){
            if(code === 'threatId'){
                if(this.selectedThreat.threatId.length > 8){
                    this.displayErrorMessageOfThreatId = true;
                }else if(this.selectedThreat.threatId.length < 8 || this.selectedThreat.threatId.length == 8){
                    // if((this.editPopup == false)&&this.allThreatIds.includes(this.selectedThreat.threatId)){console.log("djkshgfgif")
                    //     this.uniqueThreatId = true
                    // } 
                    // else if((this.editPopup == true)&&this.threadIdExcludingSelectedThreatId.includes(this.selectedThreat.threatId)){
                    //     console.log("djkshgfgif1")
                    //     this.uniqueThreatId = true
                    // } else{
                    //     this.uniqueThreatId = false
                    // }
                    this.displayErrorMessageOfThreatId = false;
                }
            }
            
        },
        resetCategoryError() {
            this.isDuplicateCategory=false
            this.isDuplicateCombination=false
           
        },
        resetSourceError() {
           this.isDuplicateSource=false
           this.isDuplicateCombination=false
        },
        resetEventError() {
            this.isDuplicateEvent=false
            this.isDuplicateCombination=false
           
        },
        validateThreat() {
            //   const { threatEvent, threatSource, threatCategory } = this.selectedThreat;
        
            // Reset all validation flags
            this.isDuplicateEvent = false;
            this.isDuplicateSource = false;
            this.isDuplicateCategory = false;
            this.isDuplicateCombination = false;
        
            // Validate individual uniqueness
            if(this.editPopup == false){
                // let ThreatEvent:any = this.threatList.some((threat:any) => threat.threatEvent === this.selectedThreat.threatEvent)
                // if (ThreatEvent) {
                //     this.isDuplicateEvent = true;
                // }
                //     let ThreatSource:any = this.threatList.some((threat:any) => threat.threatSource === this.selectedThreat.threatSource)
                // if (ThreatSource) {
                //     this.isDuplicateSource = true;
                // }
                // let ThreatCategory:any = this.threatList.some((threat:any) => threat.threatCategory === this.selectedThreat.threatCategory)
            
                // if (ThreatCategory) {
                //     this.isDuplicateCategory = true;
                // }
            
                // Validate combination uniqueness
                this.threatList.map((threat:any)=>{
                // if((threat.threatEvent===this.selectedThreat.threatEvent)&&(this.selectedThreat.threatSource===threat.threatSource)&&(this.selectedThreat.threatCategory=='')){
                //     this.isDuplicateEvent = true;
                //     this.isDuplicateSource = true;
                // }
                // if((threat.threatEvent===this.selectedThreat.threatEvent)&&(this.selectedThreat.threatCategory===threat.threatCategory)&&(this.selectedThreat.threatSource!=threat.threatSource)){
                //     this.isDuplicateEvent = true;
                //     this.isDuplicateCategory = true;
                // }
                // if((threat.threatCategory===this.selectedThreat.threatCategory)&&(this.selectedThreat.threatSource===threat.threatSource)){
                
                //     this.isDuplicateCategory = true;
                // this.isDuplicateSource = true;
                // }
                if((threat.threatEvent===this.selectedThreat.threatEvent)&&(this.selectedThreat.threatSource===threat.threatSource)&&(this.selectedThreat.threatCategory===threat.threatCategory)){ 
                    this.isDuplicateCombination = true;
                }
                })
            }else{
                console.log("kjhgjh")
                //     let ThreatEvent:any = this.threatExcludingSelectedThreat.some((threat:any) => threat.threatEvent === this.selectedThreat.threatEvent)
                //     if (ThreatEvent) {
                //         this.isDuplicateEvent = true;
                //     }
                //     let ThreatSource:any = this.threatExcludingSelectedThreat.some((threat:any) => threat.threatSource === this.selectedThreat.threatSource)
                // if (ThreatSource) {
                //     this.isDuplicateSource = true;
                // }
                // let ThreatCategory:any = this.threatExcludingSelectedThreat.some((threat:any) => threat.threatCategory === this.selectedThreat.threatCategory)
            
                // if (ThreatCategory) {
                //     this.isDuplicateCategory = true;
                // }
 
            // Validate combination uniqueness
                this.threatExcludingSelectedThreat.map((threat:any)=>{
                    // if((threat.threatEvent===this.selectedThreat.threatEvent)&&(this.selectedThreat.threatSource===threat.threatSource)&&(this.selectedThreat.threatCategory=='')){
                        
                    //     this.isDuplicateEvent = true;
                    //     this.isDuplicateSource = true;
                    // }
                    // if((threat.threatEvent===this.selectedThreat.threatEvent)&&(this.selectedThreat.threatCategory===threat.threatCategory)){
                        
                    //     this.isDuplicateEvent = true;
                    //     this.isDuplicateCategory = true;
                    // }
                //     if((threat.threatCategory===this.selectedThreat.threatCategory)&&(this.selectedThreat.threatSource===threat.threatSource)){
                        
                // console.log("kjhgjh1")
                //         this.isDuplicateCategory = true;
                //         this.isDuplicateSource = true;
                //     }
                if((threat.threatEvent===this.selectedThreat.threatEvent)&&(this.selectedThreat.threatSource===threat.threatSource)&&(this.selectedThreat.threatCategory===threat.threatCategory)){
                    
                    this.isDuplicateCombination = true;
                }
            })
            }
 
        },
 
        showEditPopUp(threat: any, index: any) {
            this.editPopup = true
            this.isShow = true;
            this.obj = threat;
            this.b = threat;
            this.selectedThreat = { ...threat };
            this.objCopy = this.selectedThreat;
        },
        handleActionHeaders() {
            if (this.$route.name == 'orgthreat') {
                this.headers.push('tableDataActions');
                console.log("tableDataActionstableDataActions",this.tableDataActions)
                this.handlePopUp = false;
                console.log('check routes .......', this.headers);
            } else {
                if (this.headers['tableDataActions']) {
                    this.headers.pop('tableDataActions');
                    this.handlePopUp = true;
                }
            }
        },
        selectedOption(opt:any){
			let isNewOption = !this.threatCategoryArray.includes(opt);
			isNewOption==true ? this.threatCategoryArray.push(opt) : ''
            
            // this.displayThreatCategoryErrorMessage = this.threatList.some((threat:any) => threat.threatCategory === this.selectedThreat.threatCategory);
		},
		asyncthreatCategoryDataFind(query:any) {
            this.searchValue = query
			query = query.trim();
			if (query == '') {
				this.searchThreatCategoryList = [];
			} else {
				console.log("this.threatCategoryArray",this.threatCategoryArray)
				this.searchThreatCategoryList = this.threatCategoryArray.filter((item:any) => 
					item?.toLowerCase().includes(query.toLowerCase())
				);
				let isNewOption = !this.threatCategoryArray.some((item:any) => 
					item?.toLowerCase() === query.toLowerCase()
				);
				if (isNewOption && query !== '') {
					this.searchThreatCategoryList.push(query);
				}
			}
		},
        selectedThreatSourceOption(opt:any){
			let isNewOption = !this.threatSourceArray.includes(opt);
			isNewOption==true ? this.threatSourceArray.push(opt) : ''
            
		},
		asyncthreatSourceDataFind(query:any) {
            this.searchValue = query
			query = query.trim();
			if (query == '') {
				this.searchThreatSourceList = [];
			} else {
				console.log("this.threatSourceArray",this.threatSourceArray)
				this.searchThreatSourceList = this.threatSourceArray.filter((item:any) => 
					item?.toLowerCase().includes(query.toLowerCase())
				);
				let isNewOption = !this.threatSourceArray.some((item:any) => 
					item?.toLowerCase() === query.toLowerCase()
				);
				if (isNewOption && query !== '') {
					this.searchThreatSourceList.push(query);
				}
			}
		},
        filteredCategories(filterColumn: any, status: any) {
            this.selectedCategory = [
                ...new Set(
                    Object.values(this.threatList).map((x: any) => {
                        x[status];
                        return x[status];
                    })
                )
            ].filter((category: any) => {
                return category.toLowerCase().includes(filterColumn.toLowerCase());
            });
        },
        getName(options: any, status: any) {
            let s = status.charAt(0).toLowerCase() + status.replace(/\s+/g, '').substring(1);
            this.filteredCategories(options, s);
            this.setState(options, s);
        },
        setState(options: any, status: any) {
            this.selectedThreat[status] = options;
            this.autoSuggestion = false;
        },
        async getRootThreatList() {
            this.threatList = [];
            this.dummyList = [];
            this.allThreatIds = [];
            await this.$http.get(`${process.env.VUE_APP_RISK_API_URL}/api/root/risks/getall`).then((result: any) => {
                let a = result.data.filter((obj:any)=>{
                    return obj._id != ''
                })
                a.forEach((threat: any) => {
                    if (threat.threatSource) {
                        this.threatList.push({ threatId: threat.threatId ? threat.threatId : 'N/A', threatSource: threat.threatSource, threatCategory: threat.threatCategory, threatEvent: threat.threatEvent, _id: threat._id });
                        this.dummyList.push({ threatId: threat.threatId ? threat.threatId : 'N/A', threatSource: threat.threatSource, threatCategory: threat.threatCategory, threatEvent: threat.threatEvent, _id: threat._id });
                        this.allThreatIds.push(threat.threatId)
                    }
                });
                this.threatCategoryArray = [
					...new Set(
						Object.values(a).map((x: any) => {
							x["threatCategory"];
							return x["threatCategory"];
						})
					)
				]
                this.threatSourceArray = [
					...new Set(
						Object.values(a).map((x: any) => {
							x["threatSource"];
							return x["threatSource"];
						})
					)
				]
            });
            // this.$store.dispatch('updateDotsLoading', false);
        },
        async getOrgThreatList() {
            this.threatList = [];
            this.dummyList = [];
            this.allThreatIds = [];
            await this.$http.get(`${process.env.VUE_APP_RISK_API_URL}/org/threats/getall`).then((result: any) => {
                let a = result.data.filter((obj:any)=>{
                    return obj._id != ''
                })
                a.forEach((threat: any) => {
                    if (threat.threatSource) {
                        this.threatList.push({ threatId: threat.threatId, threatSource: threat.threatSource, threatCategory: threat.threatCategory, threatEvent: threat.threatEvent, _id: threat._id });
                        this.dummyList.push({ threatId: threat.threatId, threatSource: threat.threatSource, threatCategory: threat.threatCategory, threatEvent: threat.threatEvent, _id: threat._id });
                        this.allThreatIds.push(threat.threatId);this.allThreatSourcesArray.push(threat.threatSource);this.allThreatCategoryArray.push(threat.threatCategory)
                    }
                }); 
                this.threatCategoryArray = [
					...new Set(
						Object.values(a).map((x: any) => {
							x["threatCategory"];
							return x["threatCategory"];
						})
					)
				]
                this.threatSourceArray = [
					...new Set(
						Object.values(a).map((x: any) => {
							x["threatSource"];
							return x["threatSource"];
						})
					)
				]
            });
            // this.$store.dispatch('updateDotsLoading', false);
        },
        async showDeletePopUp(data: any) {
            let payloadThreatId = data._id;
            await this.$http.delete(`${process.env.VUE_APP_RISK_API_URL}/org/threat/${payloadThreatId}/delete`).then((result: any) => {
                toast.info(`Deleted succesfully`, {
                    closeOnClick: true,
                    closeButton: 'button'
                });
            });
            await this.getOrgThreatList();
        },
        toolTip(type: any, e: any) {
            if (type == 'text') {
                if (e.offsetX > 1 && e.offsetX < e.target.offsetWidth && e.offsetY > 1 && e.offsetY < e.target.offsetHeight) {
                    this.pop = true;
                    this.xPos = e.offsetX + 38 + 'px';
                    this.yPos = -15 + 'px';
                }
            }
            if (type == 'button') {
                if (e.offsetX < e.target.offsetWidth && e.offsetY < e.target.offsetHeight) {
                    this.pop = true;
                    this.xPos = e.offsetX + e.target.offsetLeft + 13 + 'px';
                    this.yPos = -15 + 'px';
                }
            }
        },
        sort: function (s: any) {
            this.className = s;
            if (s === this.currentSort) {
                this.currentSortDir = this.currentSortDir === 'asc' ? 'desc' : 'asc';
            }
            this.currentSort = s;
            this.classSort = this.currentSortDir === 'asc' ? 'sort_asc' : 'sort_desc';
        },
        filterOpen() {
            this.showFilters = !this.showFilters;
            for (const key in this.filterDummy) {
                this.filterDummy[key] = '';
            }
            if (this.showFilters == true) {
                this.threatList = this.dummyList;
            } else {
                this.threatList = this.dummyList;
            }
            emitter.emit('visible', this.showFilters);
        },
        showPopup() {
            this.editPopup = false
            this.b = false;
            this.isShow = true;
            this.v$.$reset();
            Object.keys(this.selectedThreat).forEach((prop: any) => {
                this.selectedThreat[prop] = '';
            });
            this.objCopy = this.selectedThreat;
            if(Object.keys(this.selectedThreat).includes('_id')){
                delete this.selectedThreat._id
            }
        },
        showPopupThreat(threat: any, index: any) {
            this.isShow = true;
            this.obj = threat;
            this.b = threat;
            this.selectedThreat = { ...threat };
            this.objCopy = this.selectedThreat;
        },
        cancelBtn(status: any) {
            if (status === 'close') {
                this.isShow = false
                this.displayErrorMessage = false
                this.displayErrorMessageOfThreatId = false
                this.uniqueThreatId = false
                this.displayThreatCategoryErrorMessage = false
                this.isDuplicateSource = false
                this.uniqueThreat = false
                this.isDuplicateEvent = false;
                this.isDuplicateSource = false;
                this.isDuplicateCategory = false;
                this.isDuplicateCombination = false;
            }
            else if (status) {
                this.selectedThreat = { ...this.obj };
                this.objCopy = this.selectedThreat;
                this.displayErrorMessage = false
                this.displayErrorMessageOfThreatId = false
                this.uniqueThreatId = false
                this.displayThreatCategoryErrorMessage = false
                this.isDuplicateSource = false
                
                this.isDuplicateEvent = false;
                this.isDuplicateSource = false;
                this.isDuplicateCategory = false;
                this.isDuplicateCombination = false;
                
            } else if (!undefined) {
                this.displayErrorMessage = false
                this.displayErrorMessageOfThreatId = false
                this.uniqueThreatId = false
                this.displayThreatCategoryErrorMessage = false
                this.isDuplicateSource = false
                this.uniqueThreat = false
                this.isDuplicateEvent = false;
                this.isDuplicateSource = false;
                this.isDuplicateCategory = false;
                this.isDuplicateCombination = false;
                this.showPopup();
            }
        },
        async addOrUpdatethreat(selectThreat: any) {
            console.log('selectThreat', selectThreat);
            this.v$.$touch();
            this.validateThreat();
            // this.restrictCharacters('Threat');
            if (!this.v$.selectedThreat.$invalid && this.displayErrorMessage == false && !this.isDuplicateEvent && !this.isDuplicateSource && !this.isDuplicateCategory &&!this.isDuplicateCombination) {
                this.clickOnButton = true
                this.threatList.push(this.selectedThreat);
                if (this.selectedThreat._id) {
                    await this.updateThreatList();
                } else {
                    await this.addThreatList();
                }
                this.threatList = [];
                if (this.$route.path.includes('root')) {
                    //this.$store.dispatch('updateDotsLoading', true);
                    await this.getRootThreatList();
                } else if (this.$route.path.includes('org')) {
                    //this.$store.dispatch('updateDotsLoading', true);
                    await this.getOrgThreatList();
                }
                this.v$.$reset();
            } else {
                // toast.error(`error`, {
                //     timeout: 1000,
                //     closeOnClick: true,
                //     closeButton: 'button',
                //     icon: true
                // });
                console.log("error")
            }
        },
        async addThreatList() {
            let copyOfselectThreat:any = {...this.selectedThreat}
            try {
                console.log('selectedThreat', this.selectedThreat);
                if (this.$route.path.includes('root')) {
                    await this.$http.post(`${process.env.VUE_APP_RISK_API_URL}/api/root/risk/create`, [copyOfselectThreat]).then((result: any) => {
                        toast.info(`Created succesfully`, {
                            closeOnClick: true,
                            closeButton: 'button'
                        });
                    });
                    await this.getRootThreatList();
                } else if (this.$route.path.includes('org')) {
                    await this.$http.post(`${process.env.VUE_APP_RISK_API_URL}/org/threat/create`, [copyOfselectThreat]).then((result: any) => {
                        toast.info(`Created succesfully`, {
                            closeOnClick: true,
                            closeButton: 'button'
                        });
                    });
                    await this.getOrgThreatList();
                }
            } catch (e) {
                toast.error('error', {
                    timeout: 1000,
                    closeOnClick: true,
                    closeButton: 'button',
                    icon: true
                });
            } finally {
                this.clickOnButton = false
            }
            this.isShow = false;
        },
        async updateThreatList() {
            let copyOfselectThreat:any = {...this.selectedThreat}
            try {
                let payloadThreatId = copyOfselectThreat._id;
                delete copyOfselectThreat._id;
                if (this.$route.path.includes('root')) {
                    try{
                        await this.$http.post(`${process.env.VUE_APP_RISK_API_URL}/api/root/risk/${payloadThreatId}/update`, copyOfselectThreat).then((result: any) => {
                            toast.info(`Updated succesfully`, {
                                closeOnClick: true,
                                closeButton: 'button'
                            });
                        });
                        await this.getRootThreatList();
                    }catch(err){
                        toast.error(`Error`, {
                            closeOnClick: true,
                            closeButton: 'button'
                        });
                    } finally{
                        this.clickOnButton = false
                    }
                } else if (this.$route.path.includes('org')) {
                    try{
                        await this.$http.post(`${process.env.VUE_APP_RISK_API_URL}/org/threat/${payloadThreatId}/update`, copyOfselectThreat).then((result: any) => {
                            toast.info(`Updated succesfully`, {
                                closeOnClick: true,
                                closeButton: 'button'
                            });
                        });
                        await this.getOrgThreatList();
                    }catch (err){
                        toast.error(`Error`, {
                            closeOnClick: true,
                            closeButton: 'button'
                        });
                    }finally{
                        this.clickOnButton = false
                    }
                }
            } catch (e) {
                toast.error('error', {
                    timeout: 1000,
                    closeOnClick: true,
                    closeButton: 'button',
                    icon: true
                });
            }finally{
                this.clickOnButton = false
            }
            this.isShow = false;
        }
    }
});
</script>