<template>
    <div class="flexcontainer pt-3 pl-5 pr-5">
        <div class="row w-full flex justify-between items-center">
            <h1>Vulnerabilities</h1>
            <div class="flex">
                <!-- <button class="float-right btnprimary"  v-if="pageType == false" @click="importDoc()">Import</button> -->
                <button class="float-right btnprimary ml-3" v-if="pageType == false" @click="showPopupVulnerability()">Add</button>
                <input type="file" class="hidden" accept=".xls,.xlsx" ref="file" @change="fileReader($event)" />
                <button class="ml-3 float-right flex items-center" :class="vulnerabilities.length == 0 ? 'btndisabled pointer-events-none' : 'filterButton'" style="height: 33px; width: 84px" @click="filterOpen">
                    Filters
                    <img src="@/assets/filter.svg" class="h-3 ml-2.5" />
                </button>
            </div>
        </div>
        <div class="mt-3 text-xs">
            <div>
            Vulnerability is a weak point, flaw, error, or gap in the security environment of an asset. The weakness is exploited by the possible object to force its way into getting unauthorized access to the system.Threats will exist but if there are no vulnerabilities, there are limited or zero
            chances of risk. Hence, knowing about common vulnerabilities and regular tracking of those is critical to identifying potential risks to assets.
            </div>
        </div>
        <div class="row">
            <div>
                <newTable :tableRowData="vulnerabilities" :headersData="headers" :openPopup="handlePopUp" :loadData="dataLoading"></newTable>
                <div v-if="toolTipActive">
                    <tool-tip :tipText="toolTipText"></tool-tip>
                </div>
            </div>
        </div>
        <popup v-if="showPopup">
            <template v-slot:header>
                <div class="flex items-center justify-between popupheadingcontainer rounded">
                    <h1>Vulnerability</h1>
                    <button class="float-right text-3xl" @click="cancelBtn('close')">
                        <img src="@/assets/cancel.svg" class="h-5 w-5 cursor-pointer" />
                    </button>
                </div>
            </template>
            <div class="popup-business popupbodycontainer">
                <div class="row flex">
                    <div class="upperspacing w-full">
                        <label class="controllabel font-normal text-sm mb-1" for="vulnerability">Vulnerability ID</label> 
                        <input
                            v-model.trim="newVulnerability.vulnerabilityId"
                            placeholder="ID"
                            @input ="restrictCharacters('VulnerabilityId')"
                            id="id"
                            class="inputboxstyling"
                            :class="{
                                'is-invalid': (v$.newVulnerability.vulnerabilityId.$error||displayErrorMessageOfVulnerabilityId ||uniqueVulnerabilityId == true)
                            }"
                        />
                        <div v-if="v$.newVulnerability.vulnerabilityId.$error" class="text-red-500">
                            <div class="error-text text-xs" v-if="v$.newVulnerability.vulnerabilityId.required.$invalid">ID is required</div>
                        </div>
                        <div v-else-if="displayErrorMessageOfVulnerabilityId == true">
                            <div class="text-red-500 text-xs">Character Limit is exceeded</div>
                        </div>
                        <div v-else-if="uniqueVulnerabilityId == true">
                            <div class="text-red-500 text-xs">Vulnerability Id already Exists</div>
                        </div>
                    </div>
                </div>

                <div class="row flex">
                    <div class="upperspacing w-full">
                        <label class="controllabel font-normal text-sm mb-1" for="vulnerability">Category</label> 
                        <input
                            v-model.trim="newVulnerability.category"
                            @input ="restrictCharacters('VulnerabilityCategory')"
                            @change="resetEventError"
                            placeholder="Category"
                            id="category"
                            class="inputboxstyling"
                            :class="{
                                'is-invalid': v$.newVulnerability.category.$error||displayErrorMessageOfVulnerabilityCategory||uniqueVulnerabilityCategory
                            }"
                        />
                        <div v-if="v$.newVulnerability.category.$error" class="text-red-500">
                            <div class="error-text text-xs" v-if="v$.newVulnerability.category.required.$invalid">Category is required</div>
                        </div>
                        <div v-else-if="displayErrorMessageOfVulnerabilityCategory == true">
                            <div class="text-red-500 text-xs">Character Limit is exceeded</div>
                        </div>
                        <div v-else-if="uniqueVulnerabilityCategory == true">
                            <div class="text-red-500 text-xs">This vulnerability category already exists</div>
                        </div>
                    </div>
                </div>

                <div class="row flex">
                    <div class="upperspacing w-full">
                        <label class="controllabel font-normal text-sm mb-1" for="vulnerability">Vulnerability</label> 
                        <textarea
                            v-model.trim="newVulnerability.vulnerability"
                            placeholder="Vulnerability"
                            id="vulnerability"
                            @input="resetEventError"
                            class="inputboxstyling"
                            :class="{
                                'is-invalid': v$.newVulnerability.vulnerability.$error||displayErrorMessageOfVulnerability||uniqueVulnerability
                            }"
                        />
                        <div v-if="v$.newVulnerability.vulnerability.$error" class="text-red-500">
                            <div class="error-text text-xs" v-if="v$.newVulnerability.vulnerability.required.$invalid">Vulnerability is required</div>
                        </div>
                        <div v-else-if="displayErrorMessageOfVulnerability == true">
                            <div class="text-red-500 text-xs">Character Limit is exceeded</div>
                        </div>
                        <div v-else-if="uniqueVulnerability == true">
                            <div class="text-red-500 text-xs">This vulnerability already exists</div>
                        </div>
                    </div>
                </div>

                <div class="flex justify-center items-center mt-2">
                    <button :disabled="clickOnButton" class="btnprimary mr-1.5" :class="disableStatus ? 'btnprimary' : 'btndisabled pointer-events-none'" @click="cancelBtn(newVulnerability._id)">Cancel</button>
                    <button :disabled="clickOnButton" @click="addOrUpdatevulnerability()" v-if="!editVulnerability" :class="disableStatus ? 'btnprimary' : 'btndisabled pointer-events-none'">Save</button>
                    <button :disabled="clickOnButton" class="btnprimary mr-1.5" v-else @click="addOrUpdatevulnerability()" :class="disableStatus ? 'btnprimary' : 'btndisabled pointer-events-none'">Update</button>
                </div>
            </div>
        </popup>
    </div>
</template>
<style scoped>
.popup-business {
    overflow-y: scroll;
}
::-webkit-scrollbar {
    width: 0px;
}
</style>
<script lang="ts">
import { defineComponent } from 'vue';
import _ from 'lodash';
import * as XLSX from 'xlsx';
import { mapGetters } from 'vuex';
import useVuelidate from '@vuelidate/core';
import { required, helpers, email, maxLength } from '@vuelidate/validators';
import popup from '@/components/popup.vue';
import { emitter, toast } from '@/main';
import newTable from '@/components/newTable.vue';
import toolTip from '@/components/toolTip.vue';

export default defineComponent({
    data(): any {
        return {
            v$: useVuelidate(),
            mandatoryKeys: ['vulnerability','category'],
            fullKeys: ['vulnerability','category'],
            pop: false,
            vulnerabilities: [],
            dummyList: [],
            showFilters: false,
            showPopup: false,
            b: false,
            result: false,
            tableIndex: '',
            selected: '',
            searchList: [],
            notDisable: false,
            clickOnButton: false,
            classSort: '',
            className: '',
            riskList: [],
            dupList: [],
            objectList: [],
            objCopy: {},
            disabled: 1,
            headers: ['vulnerabilityId', 'category', 'vulnerability', '_id'],
            currentSort: 'name',
            currentSortDir: 'asc',
            newVulnerability: {
                vulnerabilityId: '',
                category: '',
                vulnerability: ''
            },
            handlePopUp: true,
            showPriorityPopUp: false,
            toolTipActive : false,
            toolTipText : '',
            dataLoading:true,
            editVulnerability:false,
			displayErrorMessageOfVulnerabilityId : false,
			displayErrorMessageOfVulnerabilityCategory : false,
			displayErrorMessageOfVulnerability : false,
            allVulnerabilityIds:[],
            vulnerabilityIdsExcludingSelectedVulnerabilityId:[],
            vulnerabilityExcludingSelectedVulnerability:[],
            uniqueVulnerabilityId:false,
            pageType:true,
            uniqueVulnerability:false,
            uniqueVulnerabilityCategory:false,
        };
    },
    validations: {
        newVulnerability: {
            vulnerabilityId: { required },
            category: { required },
            vulnerability: { required }
        }
    },
    components: {
        popup,
        newTable,
        toolTip
    },
    async mounted() {
        
        this.dataLoading = true;

        this.handleActionHeaders();
        
        if (this.$route.path.includes('root/vulnerabilities')) {
            this.pageType = true;
            await this.getRootVulnerabilitiesList();
        } else if (this.$route.path.includes('org/vulnerabilities')) {
            this.pageType = false
            await this.getOrgVulnerabilitiesList();
        }

         this.dataLoading = false;
         emitter.off('rowData')

        
        emitter.off('showData123');
        emitter.on('showData123', (isData: any) => {
            this.vulnerabilityIdsExcludingSelectedVulnerabilityId = []
            this.vulnerabilityExcludingSelectedVulnerability = []
            this.showEditPopUp(isData.entityData);
            this.vulnerabilities.forEach((vulnerability: any) => {
                if(isData.entityData._id != vulnerability._id && vulnerability._id != null && vulnerability._id != undefined){
                    this.vulnerabilityIdsExcludingSelectedVulnerabilityId.push(vulnerability.vulnerabilityId)
                    this.vulnerabilityExcludingSelectedVulnerability.push(vulnerability)
                }
            });
        });

        emitter.off('deleteData');
        emitter.on('deleteData', (isData: any) => {
            this.showDeletePopUp(isData.entityData);
        });

        emitter.off('showPopupData');
        emitter.on('showPopupData', (isData: any) => {
            this.vulnerabilityIdsExcludingSelectedVulnerabilityId = []
            this.vulnerabilityExcludingSelectedVulnerability = []
            this.showEditPopUp(isData.entityData);
            this.vulnerabilities.forEach((vulnerability: any) => {
                if(isData.entityData._id != vulnerability._id && vulnerability._id != '' && vulnerability._id != undefined){
                    this.vulnerabilityIdsExcludingSelectedVulnerabilityId.push(vulnerability.vulnerabilityId)
                    this.vulnerabilityExcludingSelectedVulnerability.push(vulnerability)
                }
            });
        });

        emitter.off('activiteToolTip');
        emitter.on('activiteToolTip', (data: any) => {
            this.toolTipActive = true;
            this.toolTipText = data.tipText
        });
 
        emitter.off('deActiviteToolTip');
        emitter.on('deActiviteToolTip', (data: any) => {
            this.toolTipActive = false;
            this.toolTipText = ''
        });
    },
    computed: {
        disableStatus: function (): any {
            let disableStatusCondition;
            if (this.b) {
                let objCopy = Object.assign({}, this.newVulnerability);
                if (JSON.stringify(objCopy) == JSON.stringify(this.obj)) {
                    disableStatusCondition = false;
                } else {
                    disableStatusCondition = true;
                }
            } else {
                let val: any = [];
                Object.values(this.newVulnerability).forEach((value: any) => {
                    val.push(value);
                });
                for (let n of val) {
                    if (n != '') {
                        disableStatusCondition = true;
                    }
                }
            }
            return disableStatusCondition;
        },
        e: function (): any {
            return false;
        }
    },
    methods: {
        showEditPopUp(vulnerability: any, index: any) {
            this.editVulnerability = true
            this.showPopup = true;
            this.obj = vulnerability;
            console.log(vulnerability);
            this.b = vulnerability;
            this.newVulnerability = { ...vulnerability };
            this.objCopy = this.newVulnerability;
        },
        
        resetEventError() {
            this.uniqueVulnerability = false
            this.uniqueVulnerabilityCategory = false
        },
        restrictCharacters(code: any){
            if(code === 'VulnerabilityId'){
                if(this.newVulnerability.vulnerabilityId.length > 8){
                this.displayErrorMessageOfVulnerabilityId = true;
                }else if(this.newVulnerability.vulnerabilityId.length < 8 || this.newVulnerability.vulnerabilityId.length == 8){
                    this.displayErrorMessageOfVulnerabilityId = false;
                    if ((this.editVulnerability === false && this.allVulnerabilityIds.includes(this.newVulnerability.vulnerabilityId.toLowerCase())) ||
                        (this.editVulnerability === true && this.vulnerabilityIdsExcludingSelectedVulnerabilityId.includes(this.newVulnerability.vulnerabilityId.toLowerCase()))
                    ) {
                        this.uniqueVulnerabilityId = true;
                    } else {
                        this.uniqueVulnerabilityId = false;
                    }

                }
            } else if(code === 'VulnerabilityCategory'){
                if(this.newVulnerability.category.length > 300){
                    this.displayErrorMessageOfVulnerabilityCategory = true;
                }else if(this.newVulnerability.category.length < 300 || this.newVulnerability.category.length == 300){
                    this.displayErrorMessageOfVulnerabilityCategory = false;
                }
            } else if(code === 'Vulnerability'){
                if(this.newVulnerability.vulnerability.length > 300){
                    this.displayErrorMessageOfVulnerability = true;
                }else if(this.newVulnerability.vulnerability.length < 300 || this.newVulnerability.vulnerability.length == 300){
                    
                    this.displayErrorMessage = false;
                }
                    this.displayErrorMessageOfVulnerability = false;
                
            }
            this.uniqueVulnerability = false
            this.uniqueVulnerabilityCategory = false
            
        },
        validateVulnerability(){
            if((this.editVulnerability == true)){
                let checkVulnerability:any = this.vulnerabilityExcludingSelectedVulnerability.some((vulnerability:any) => (vulnerability.vulnerability === this.newVulnerability.vulnerability)&&(vulnerability.category === this.newVulnerability.category))
                console.log("jhgvf",checkVulnerability)
                if(checkVulnerability){
                    this.uniqueVulnerability = true
                    this.uniqueVulnerabilityCategory = true
                } 
                else{
                    this.uniqueVulnerability = false
                    this.uniqueVulnerabilityCategory = false
                }
            } 
            else if((this.editVulnerability == false)){
                let checkVulnerability:any = this.vulnerabilities.some((vulnerability:any) => (vulnerability.vulnerability === this.newVulnerability.vulnerability)&&(vulnerability.category === this.newVulnerability.category))
                // console.log("jkghjg",checkVulnerability)
                if(checkVulnerability){
                    this.uniqueVulnerability = true
                    this.uniqueVulnerabilityCategory = true
                } 
                else{
                    this.uniqueVulnerability = false
                    this.uniqueVulnerabilityCategory = false
                }
            } 
            else{
                this.uniqueVulnerability = false
                this.uniqueVulnerabilityCategory = false
            }
        },
        async addOrUpdatevulnerability() {
            this.v$.$touch();
            // this.restrictCharacters('Vulnerability')
            this.validateVulnerability();
            if (!this.v$.$invalid && this.displayErrorMessageOfVulnerability == false && this.displayErrorMessageOfVulnerabilityId == false && this.displayErrorMessageOfVulnerabilityCategory == false && this.uniqueVulnerabilityId == false && this.uniqueVulnerability == false && this.uniqueVulnerabilityCategory == false) {
                this.clickOnButton = true
                this.vulnerabilities.push(this.newVulnerability);
                if (this.newVulnerability._id) {
                    await this.updateVulnerabilitiesList();
                } else {
                    await this.addVulnerabilitiesList();
                }
                this.vulnerabilities = [];
                if (this.$route.path.includes('root/vulnerabilities')) {
                    await this.getRootVulnerabilitiesList();
                } else if (this.$route.path.includes('org/vulnerabilities')) {
                    await this.getOrgVulnerabilitiesList();
                }
                this.v$.$reset();
            } else {
                console.log("error");
                // toast.error(`error`, {
                //     timeout: 1000,
                //     closeOnClick: true,
                //     closeButton: 'button',
                //     icon: true
                // });
            }
        },
        async showDeletePopUp(data: any) {
            let payloadvunerabilitesId = data._id;
            await this.$http.delete(`${process.env.VUE_APP_RISK_API_URL}/org/vunerabilites/${payloadvunerabilitesId}/delete`).then((result: any) => {
                toast.info(`Deleted succesfully`, {
                    closeOnClick: true,
                    closeButton: 'button'
                });
            });
            await this.getOrgVulnerabilitiesList();
        },
        async addVulnerabilitiesList() {
            try {
                if (this.$route.path.includes('root')) {
                    await this.$http.post(`${process.env.VUE_APP_RISK_API_URL}/api/root/risk/create`, [this.newVulnerability]).then((result: any) => {
                        toast.info(`Created succesfully`, {
                            closeOnClick: true,
                            closeButton: 'button'
                        });
                    });
                    this.showPopup = false;
                } else if (this.$route.path.includes('org')) {
                    await this.$http.post(`${process.env.VUE_APP_RISK_API_URL}/org/vunerabilites/create`, [this.newVulnerability]).then((result: any) => {
                        toast.info(`Created succesfully`, {
                            closeOnClick: true,
                            closeButton: 'button'
                        });
                    });
                    this.showPopup = false;
                }
            } catch (e) {
                console.log('add else');
                toast.error('error', {
                    timeout: 1000,
                    closeOnClick: true,
                    closeButton: 'button',
                    icon: true
                });
            } finally {
                this.clickOnButton=false
            }
        },
        async updateVulnerabilitiesList() {
            try {
                let objectId = this.newVulnerability._id;
                delete this.newVulnerability._id;
                console.log('before sending data:', this.newVulnerability);
                if (this.$route.path.includes('root')) {
                    await this.$http.post(`${process.env.VUE_APP_RISK_API_URL}/api/root/risk/${objectId}/update`, this.newVulnerability).then((result: any) => {
                        toast.info(`Updated succesfully`, {
                            closeOnClick: true,
                            closeButton: 'button'
                        });
                    });
                    this.showPopup = false;
                } else if (this.$route.path.includes('org')) {
                    await this.$http.post(`${process.env.VUE_APP_RISK_API_URL}/org/vunerabilites/${objectId}/update`, this.newVulnerability).then((result: any) => {
                        toast.info(`Updated succesfully`, {
                            closeOnClick: true,
                            closeButton: 'button'
                        });
                    });
                    this.showPopup = false;
                }
            } catch (e) {
                toast.error('error', {
                    timeout: 1000,
                    closeOnClick: true,
                    closeButton: 'button',
                    icon: true
                });
            } finally {
                this.clickOnButton=false
            }
        },
        filteredFunction(filterColumn: any, columnName: any) {
            this.vulnerabilities = this.dummyList.filter((itemOfArray: any) => {
                for (var property in itemOfArray) {
                    if (property == columnName) {
                        return itemOfArray[property].toLowerCase().includes(filterColumn.toLowerCase());
                    }
                }
            });
        },
        cancelBtn(status: any) {
            if (status === 'close') {
                this.displayErrorMessageOfVulnerability = false
                this.displayErrorMessageOfVulnerabilityId = false
                this.displayErrorMessageOfVulnerabilityCategory = false
                this.uniqueVulnerabilityId = false
                this.uniqueVulnerability = false
                this.uniqueVulnerabilityCategory = false
                this.showPopup = false
            }
            else if (status) {
                this.newVulnerability = { ...this.obj };
                this.objCopy = this.newVulnerability;
                this.displayErrorMessageOfVulnerability = false
                this.displayErrorMessageOfVulnerabilityId = false
                this.displayErrorMessageOfVulnerabilityCategory = false
                this.uniqueVulnerabilityId = false
                this.uniqueVulnerability = false
                this.uniqueVulnerabilityCategory = false
            } else if (!undefined) {
                this.showPopupVulnerability();
                this.displayErrorMessageOfVulnerability = false
                this.displayErrorMessageOfVulnerabilityId = false
                this.displayErrorMessageOfVulnerabilityCategory = false
                this.uniqueVulnerabilityId = false
                this.uniqueVulnerability = false
                this.uniqueVulnerabilityCategory = false
            }
        },
        filterOpen() {
            this.showFilters = !this.showFilters;
            if (this.showFilters == true) {
                this.dummyList = this.vulnerabilities;
            } else {
                this.vulnerabilities = this.dummyList;
            }
            emitter.emit('visible', this.showFilters);
        },
        showPopupVulnerability() {
            (this.editVulnerability = false),
            (this.b = false), (this.showPopup = true);
            this.v$.$reset();
            Object.keys(this.newVulnerability).forEach((prop: any) => {
                this.newVulnerability[prop] = '';
            });
            this.objCopy = this.newVulnerability;
            if(Object.keys(this.newVulnerability).includes('_id')){
                delete this.newVulnerability._id
            }
        },
        fileReader(oEvent: any) {
            var oFile = oEvent.target.files[0];
            var reader = new FileReader();
            reader.onload = async (e: any) => {
                var data = e.target.result;
                data = new Uint8Array(data);
                var workbook = XLSX.read(data, {
                    type: 'array'
                });
                var first_sheet_name = workbook.SheetNames[0];
                var worksheet = workbook.Sheets[first_sheet_name];
                var jsonObj:any = XLSX.utils.sheet_to_json(worksheet, {
                    raw: true,
                    header: this.fullKeys,
                    range: 1
                });
                let total = jsonObj.length;

                let addList:any = [];
                
                jsonObj.forEach((element: any) => {
                    let mandatoryCondition = this.mandatoryKeys.every((key: any) => {
                        return jsonObj[key]!='';
                    });

                    if (mandatoryCondition) {
                        let findObj = this.vulnerabilities.find((obj:any)=> {return (element.vulnerability == obj.vulnerability &&  element.category == obj.category)})
                        if(findObj == undefined){
                            addList.push(element);
                        }
                    }
                });

                if (addList.length > 0) {
                    toast.info(`Imported ${total} rows, Valid rows: ${addList.length}, Ignored rows: ${total - addList.length}`, {
                        timeout: 3000,
                        closeOnClick: true,
                        closeButton: 'button',
                        icon: true
                    });


                    try{
                        await this.$http.post(`${process.env.VUE_APP_RISK_API_URL}/org/vunerabilites/create`, addList).then(async (result: any) => {
                            console.log('added')
                            await this.getOrgVulnerabilitiesList();
                        });
                    }catch{
                        console.log('error')
                    }
                } else {
                    toast.error('No Rows Imported', {
                        timeout: 1000,
                        closeOnClick: true,
                        closeButton: 'button',
                        icon: true
                    });
                }
            };
            reader.readAsArrayBuffer(oFile);
        },
        importDoc() {
            this.$refs.file.click();
        },
        sort: function (s: any) {
            this.notDisable = true;
            this.className = s;
            if (s === this.currentSort) {
                this.currentSortDir = this.currentSortDir === 'asc' ? 'desc' : 'asc';
            }
            this.currentSort = s;
            this.classSort = this.currentSortDir === 'asc' ? 'sort_asc' : 'sort_desc';
        },
        async getRootVulnerabilitiesList() {
            await this.$http.get(`${process.env.VUE_APP_RISK_API_URL}/api/root/risks/getall`).then((result: any) => {
                this.vulnerabilities = [];
                this.allVulnerabilityIds = [];
                 let a = result.data.filter((obj:any)=>{
                    return obj._id != ''
                })
                a.forEach((object: any) => {
                    if (object.vulnerability) {
                        this.vulnerabilities.push({ vulnerabilityId: object.vulnerabilityId ? object.vulnerabilityId : "N/A", 
                        category: object.category ? object.category :'N/A',  
                        vulnerability: object.vulnerability,
                         _id: object._id });
                        this.dupList.push({ vulnerabilityId: object.vulnerabilityId ? object.vulnerabilityId : "N/A", category: object.category, vulnerability: object.vulnerability, _id: object._id });
                        this.allVulnerabilityIds.push(object.vulnerabilityId)
                    }
                });
                // this.$store.dispatch('updateDotsLoading', false);
                this.dummyList = this.vulnerabilities;
            });
        },
        async getOrgVulnerabilitiesList() {
            await this.$http.get(`${process.env.VUE_APP_RISK_API_URL}/org/vunerabilites/getall`).then((result: any) => {
                this.vulnerabilities = [];
                this.allVulnerabilityIds = [];
                 let a = result.data.filter((obj:any)=>{
                    return obj._id != ''
                })
                a.forEach((object: any) => {
                    if (object.vulnerability) {
                        this.vulnerabilities.push({ vulnerabilityId: object?.vulnerabilityId || 'N/A', category: object?.category || 'N/A', vulnerability: object?.vulnerability || 'N/A', _id: object._id });
                        this.dupList.push({ vulnerabilityId: object?.vulnerabilityId || 'N/A', category: object?.category || 'N/A', vulnerability: object?.vulnerability || 'N/A', _id: object._id });
                        this.allVulnerabilityIds.push(object.vulnerabilityId)
                    }
                });
                // this.$store.dispatch('updateDotsLoading', false);
                this.dummyList = this.vulnerabilities;
            });
        },
        handleActionHeaders() {
            if (this.$route.name == 'orgvulnerabilities') {
                this.headers.push('tableDataActions');
                this.handlePopUp = false;
                console.log('check routes .......', this.headers);
            } else {
                if (this.headers['tableDataActions']) {
                    this.headers.pop('tableDataActions');
                    this.handlePopUp = true;
                }
            }
        }
    }
});
</script>
