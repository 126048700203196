import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    id: "tooltipContainer",
    style: _normalizeStyle(_ctx.styling),
    class: "bg-primary rounded text-white manualStyle absolute text-sm z-50 pointer-events-none"
  }, [
    _createElementVNode("div", {
      class: _normalizeClass(["py-1 px-1.5 fullText", _ctx.styleBold == true ? 'font-bold':''])
    }, _toDisplayString(_ctx.tipText), 3)
  ], 4))
}