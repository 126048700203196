<template>
    <div class="tableCornerRadius bg-white">

        <div class="table-container">
            <table>
                <thead>
                    <th scope="col" v-for="presentColumn in columnNames" :key="presentColumn" :class="getColumnHeadWidth(presentColumn)" class="bg-white capitalize" @click="sort(presentColumn)">
                        <div class="flex" :class="getHeaderAlignment(presentColumn)">
                            <div>{{ getColumnLabel(presentColumn) }}</div>
                            <div class="sort cursor-pointer" :class="className == presentColumn ? classSort : 'opacity-50'" v-if="getColumnLabel(presentColumn) != ''"></div>
                        </div>
                    </th>
                    
                    <tr v-if="visibility">
                        <td v-for="(presentColumn, index) in columnNames" class="bg-white" :key="index">
                        
                            <div :class="getFilterAlignment(presentColumn)">
                                <select v-if="isColumnArrayPresent(presentColumn)" class="filter-font-size " v-model="filters[presentColumn]" :class="filters[presentColumn] == null ? 'inputboxstylingForSelect' : 'stylingForInputboxstylingForSelect'">
                                    <option :value="filters.presentColumn" selected hidden disabled>Select</option>
                                    <option class="optionColors" v-for="option in getDropdownOptionsForFilter(presentColumn)" :key="option.id" :value="option.id">{{ option.value }}</option>
                                </select>

                                <select v-else-if="isColumnArrayPresentibDB(presentColumn)" class="filter-font-size" v-model="filters[presentColumn]" :class="filters[presentColumn] == null ? 'inputboxstylingForSelect' : 'stylingForInputboxstylingForSelect'">
                                    <option :value="filters.presentColumn" selected hidden disabled>Select</option>
                                    <option class="optionColors" v-for="option in getDbDropdownOptionsForFilter(presentColumn)" :key="option" :value="option.value">{{ option.key }}</option>
                                </select>
                                
                                <div v-else-if="isFilterNotNecessary(presentColumn)"></div>
                                <input v-else-if="shouldShowDisabled(presentColumn)" type="text" class="disabledinputboxstyling" v-model="filters[columnName]" disabled placeholder=""  />
                                
                                <input v-else type="text" class="searchForInputBoxStyling filter-font-size" v-model="filters[presentColumn]" placeholder="Search" />
                            </div>
                        </td>
                    </tr>
                </thead>

                <tbody v-if="loadData == true">
                    <tr class="cursor-pointer rowHeight">
                        <td class="relative h-5" :colspan="columnNames.size">
                            <div class="flex items-center justify-center w-full h-16 skeleton">
                                <dots-loader/>
                            </div>
                        </td>
                    </tr>
                </tbody>

                <tbody v-else-if="sortedData.length == 0">
                    <tr class="cursor-pointer rowHeight">
                        <td class="relative h-10" :colspan="columnNames.size">
                            <div class="flex justify-center"><p class="text-xs font-normal">No Records Found</p></div>
                        </td>
                    </tr>
                </tbody>

                <tbody class="nonEmptyTable" v-else>
                    <tr v-for="(row, index) in sortedData" :key="row" class="cursor-pointer rowHeight" >
                        <td v-for="presentColumn in columnNames" :key="presentColumn" @mouseout="doFalse()" @click="actionOnClickingTableRow(row, index, presentColumn)" class="cursor-pointer relative">
                            <div class="rounded h-5 w-26" v-if="row[presentColumn] == ''" :class="{'w-24 text-center':presentColumn == 'securityCategory'}">{{ 'N/A' }}</div>
                            
                            <div v-else-if="checkDateFormat(presentColumn)">
                                <p class="cursor-pointer text-xs w-26">{{ getDateFormat(row[presentColumn]) }}</p>
                            </div>

                            <div v-else-if="checkuserData(presentColumn)">
                                <div class="cursor-pointer text-xs">
                                    {{Object.keys(row[presentColumn]).includes('label') ? `${row[presentColumn].label}`:`${getOwnerName(row[presentColumn])}`}}
                                </div>
                            </div>

                            <div v-else-if="presentColumn == 'systemCount'">
                                <div class="flex items-center justify-center" @mousemove="toolTip(presentColumn, $event, index, row, 'text')">
                                    {{ row[presentColumn] }}
                                </div>
                            </div>

                            <div v-else-if="isColumnArrayPresent(presentColumn)">
                                <div v-if="presentColumn == 'tier' || presentColumn == 'mitigationTier'">
                                    <div class="text-xs">
                                        {{ getColumnRealtedValueFromArray(row[presentColumn], presentColumn) }}
                                    </div>
                                </div>

                                <div v-else-if="presentColumn == 'subCategory'">
                                    {{ getColumnRealtedValueFromArray(row[presentColumn], presentColumn) }}
                                </div>

                                <div v-else-if="presentColumn == 'priority' || presentColumn == 'prioritypreview'" class="flex">
                                    <div class="flex items-center justify-center rounded h-5 w-24 px-2.5  regular" :class="bgColorForPriority(row[presentColumn])" v-if="row[presentColumn]">
                                        <div :class="textColorForPriority(row[presentColumn])">
                                            {{
                                                dbPriority.find((l) => {
                                                    return l.value == row[presentColumn];
                                                }).key
                                            }}
                                        </div>
                                    </div>
                                    <div class="pl-7" v-else>
                                        -
                                    </div>
                                </div>

                                <div v-else-if="getLabelsArrayForColumnInStore(presentColumn)" class="flex">
                                    
                                    <div v-if="presentColumn == 'severitypreview'">
                                        <div class="relative flex items-center justify-center regular">
                                            <div class="flex items-center justify-center rounded-full w-5 h-5" @mousemove="toolTip(presentColumn, $event, index, row, 'circleButton')" :class="colorPropBg('border', row.severitypreview)">
                                                <div class="severityCircle rounded-full" :class="colorPropBg('circle', row.severitypreview)"></div>
                                            </div>
                                        </div>
                                    </div>
                                    <div v-else class="flex items-center justify-center rounded h-5 w-28 px-2.5 regular" @mousemove="toolTip(presentColumn, $event, index, row, 'text')" :class="colorRiskBg(row[presentColumn])">
                                        <div :class="colorRiskText(row[presentColumn])">
                                            {{getLabelDefinationName( row[presentColumn] )}}
                                        </div>
                                    </div>
                                </div>

                                <div v-else-if="presentColumn == 'severity'">
                                    <div class="relative flex items-center pl-4 regular">
                                        <div class="flex items-center justify-center rounded-full w-5 h-5" @mousemove="toolTip(presentColumn, $event, index, row, 'circleButton')" :class="colorPropBg('border', row.severity)">
                                            <div class="severityCircle rounded-full" :class="colorPropBg('circle', row.severity)"></div>
                                        </div>
                                    </div>
                                </div>
                                
                                <div v-else-if="presentColumn == 'publicFacing'">
                                    <div class="text-xs">
                                        {{ getColumnRealtedValueFromArray(row[presentColumn], presentColumn) }}
                                    </div>
                                </div>

                                <div v-else-if="presentColumn == 'type'">
                                    <div class="text-xs">
                                        {{ getColumnRealtedValueFromArray(row[presentColumn], presentColumn) }}
                                    </div>
                                </div>

                                <div v-else class="flex items-center justify-center relative rounded w-24 px-2.5 h-5 regular" :class="colorsForStatusesText(row[presentColumn])">
                                    <div class="w-full h-full absolute opacity-20 rounded" :class="bgColorsForStatuses(row[presentColumn])"></div>
                                    <div class="flex rounded w-32 items-center justify-center py-1 regular text-xs">
                                        <div class="text-xs">
                                            {{ getColumnRealtedValueFromArray(row[presentColumn], presentColumn) }}
                                        </div>
                                    </div>
                                </div>

                            </div>

                            <div v-else-if="presentColumn == 'impact'" class="flex items-center justify-center">
                                <div class="flex items-center ml-3 justify-center rounded h-5 w-24" @mousemove="toolTip(presentColumn, $event, index, row, 'text')" :class="colorRiskBg(row[presentColumn])">
                                    <div :class="colorRiskText(row[presentColumn])">
                                        {{getLabelFromColumnArrayStoreImpact(row[presentColumn])}}
                                    </div>
                                </div>
                            </div>

                            <div v-else-if="getLabelsArrayForColumnInStore(presentColumn)" class="pl-3">
                                <div class="flex items-center justify-center rounded h-5 w-24" @mousemove="toolTip(presentColumn, $event, index, row, 'text')" :class="colorRiskBg(row[presentColumn])">
                                    <div :class="colorRiskText(row[presentColumn])">
                                        {{getLabelFromColumnArrayStore(row[presentColumn])}}
                                    </div>
                                </div>
                            </div>
                        
            
                            <div v-else-if="presentColumn == 'severity'">
                                <div class="relative flex items-center justify-center regular">
                                    <div class="flex items-center justify-center rounded-full w-5 h-5" @mousemove="toolTip(presentColumn, $event, index, row, 'circleButton')" :class="colorPropBg('border', row.severity)">
                                        <div class="severityCircle rounded-full" :class="colorPropBg('circle', row.severity)"></div>
                                    </div>
                                </div>
                            </div>

                            <div v-else-if="presentColumn == 'History'">
                                <div class="flex justify-center h-5 w-5">
                                    <img src="@/assets/history.svg" class="cursor-pointer h-5 w-5" />
                                </div>
                            </div>

                            <div v-else-if="presentColumn == 'reportAction'">
                                <div class="flex justify-center h-5 w-5">
                                    <a :href="row.reportUrl"><img src="@/assets/Download.svg" class="h-5 cursor-pointer w-5 mx-2.5" /></a>
                                </div>
                            </div>

                            <div v-else-if="presentColumn == 'reportDelete' || presentColumn == 'deleteIcon' || presentColumn == 'deleteinfo'">
                                <div class="flex justify-center h-5 w-5">
                                    <img src="@/assets/deleteIcon.svg" class="h-5 cursor-pointer
                                    w-5 mx-2.5" />
                                </div>
                            </div>

                            <div v-else-if="presentColumn == 'actionButton' || presentColumn == 'tableDataActions'">
                                <div v-if="presentColumn == 'actionButton'">
                                <fieldset>
                                    <div>
                                        <div class="flex justify-start items-center h-5 w-5">
                                            <img src="@/assets/ActionIcon.svg" class="h-5 w-5 cursor-pointer"  @click.stop="toggleDropdown($event,index,presentColumn)"/>
                                        </div>
                                    </div>
                                    <ul class="dropdown-menu ml-2 shadow-sm z-30 cursor-pointer" :class="isNearBottom(index) ? 'dropdown-up' : 'dropdown-menu1'" :style="dropdownStyle(index)"  v-if="showDropDown && selectedRowIndex == index">
                                        <li class="cursor-pointer lastChild border-b border-lightgrey hover:bg-lightgrey h-7 flex items-center" v-for="(option, index) in optionsValue" :key="index" @click="changeRoute(row, option.route)">
                                            <div class="flex items-center justify-between gap-2.5 pl-3" v-if="option.value == 'Accept'">
                                                <div class="h-5 w-5">
                                                    <img src="@/assets/Accept.svg" class="h-5 w-5" />
                                                </div>
                                                {{ option.value }}
                                            </div>
                                            <div class="flex items-center justify-between gap-2.5 pl-3" v-else-if="option.value == 'Mitigate'">
                                                <div class="h-5 w-5">
                                                    <img src="@/assets/Mitigate 1.svg" class="h-5 w-5" />
                                                </div>
                                                {{ option.value }}
                                            </div>
                                            <div class="flex items-center  justify-between gap-2.5 pl-3"  v-else-if="option.value=='Avoid'">
                                                <div class="h-5 w-5">
                                                    <img src="@/assets/Avoid.svg" class="h-5 w-5" />
                                                </div>
                                                {{option.value}}
                                            </div>
                                            <div class="flex items-center  justify-between gap-2.5 pl-3"  v-else-if="option.value=='Transfer'">
                                                <div class="h-5 w-5">
                                                    <img src="@/assets/Transfer.svg" class="h-5 w-5" />
                                                </div>
                                                {{option.value}}
                                            </div>
                                            <div class="flex items-center justify-between gap-2.5 pl-3" v-else-if="option.value == 'Reset'">
                                                <div class="h-5 w-5">
                                                    <img src="@/assets/Reset.svg" class="h-5 w-5" />
                                                </div>
                                                {{ option.value }}
                                            </div>
                                            <div class="flex items-center justify-between gap-2.5 pl-3" v-else-if="option.value == 'Delete'">
                                                <div class="h-5 w-5">
                                                    <img src="@/assets/DeleteRisk.svg" class="h-5 w-5" />
                                                </div>
                                                {{ option.value }}
                                            </div>
                                            <div class="flex items-center justify-between gap-2.5 pl-3" v-else-if="option.value == 'Clone'">
                                                <div class="h-5 w-5">
                                                    <img src="@/assets/Clone.svg" class="h-5 w-5" />
                                                </div>
                                                {{ option.value }}
                                            </div>
                                            <div class="flex items-center  justify-between gap-2.5 pl-3" @click="changePriority(row)"  v-else-if="option.value=='Priority'">
                                                <div class="h-5 w-5">
                                                    <img src="@/assets/Priority 1.svg" class="h-5 w-5" />
                                                </div>
                                                {{option.value}}
                                            </div>
                                        </li>
                                    
                                    </ul>
                                </fieldset>
                                </div>
                                <div v-else-if="presentColumn == 'tableDataActions'">
                                    <fieldset>
                                    <!-- <div class=""> -->
                                        <div class="flex justify-center items-center h-5 w-5">
                                            <img src="@/assets/ActionIcon.svg" class="cursor-pointer h-5 w-5"   @click.stop="toggleDropdown($event,index,presentColumn)"/>
                                        </div>
                                    <!-- </div> -->
                                        <ul class="dropdown-menu shadow-sm z-30 absolute cursor-pointer" :class="isNearBottom(index) ? 'dropdown-up2' : ''" v-if="showDropDown && selectedRowIndex == index">
                                            <li class="cursor-pointer lastChild border-b border-lightgrey hover:bg-lightgrey h-7 flex items-center justify-between" v-if="true" @click="tableDataDeleteAction(row, index)">
                                                <div class="flex items-center w-full gap-2.5 pl-3" >
                                                    <div class="h-5 w-5">
                                                        <img src="../assets/DeleteRisk.svg" class="h-5 w-5" />
                                                    </div>
                                                {{ tableDataActionOptions[0].value }}
                                            </div>
                                        </li>
                                        <li class="cursor-pointer lastChild border-b border-lightgrey hover:bg-lightgrey h-7 flex items-center" @click="tableDataEditAction(row, index)">
                                                <div class="flex items-center w-full gap-2.5 pl-3" >
                                                    <div class="h-5 w-5">
                                                        <img src="../assets/editIcon.svg" class="h-5 w-5" />
                                                    </div>
                                                {{ tableDataActionOptions[1].value }}
                                            </div>
                                        </li>
                                    </ul>
                                </fieldset>
                                </div>
                            </div>

                            <div v-else>
                                <div class="table-ellipsis">
                                    <div @mousemove="toolTip(presentColumn, $event, index, row, 'text')">
                                        {{ row[presentColumn] }}
                                    </div>
                                </div>
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>
       </div>
        <pagination :dynamicPagination="dynamicPagination" @currentPageUpdate="paginationUpdations" :entityId="entityId" :sortedData="sortedData"></pagination>
    </div>
</template>
<style scoped>
.inputboxStylingForFilter {
    font-size:12px;
    width:112px;
}
.inputboxstylingForSelect .optionColors{
   color : #4D4D4D !important;
}
/* select{
    color:#a4a4a4;
} */
.lastChild:last-child {
    border: 0px;
}
.optionFont {
    font-size: 12px !important;
}
.w-40 {
    width: 155px;
}
.dropdown-up2 {
    transform: translateY(-135%);
}
.ellipsis-container {
    text-overflow: ellipsis;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    line-height: 1.5em;
    max-height: 3em;
}
.table-ellipsis {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
}
.displayHeatMapTable {
    height: 300px !important;
}
.displayDashboardTable {
    height: 360px !important;
}
.empty td {
    border-bottom: 1px solid #e9e9e9 !important;
}
.Blank {
    padding-left: 11% !important;
}
.actionButtonStyling {
    left: -76px;
}
.action-popup {
    bottom: -40px;
    left: -60px;
}
.bgredStyle {
    background-color: #ff0000;
}

.fontSizingForPriority {
    font-size: 10px !important;
}
.severity-filter{
    width: 80px;
}
.tier-filter{
    width: 108px;
}
.risk-category-filter{
    width: 108px;
}
.risk-category-header{
    min-width: 130px;
    max-width: 132px;
}
.tier-header{
    min-width: 145px;
    max-width: 147px;
}
.dropdown-menu {
	border: 1px solid lightgray;
	position: absolute;
	background-color: white;
	border-radius: 6px;
	z-index: 999;
	top: 70%;
	right: 35%;
	width: 120px;
}
.widthForSelect{
    width : 100px !important;
}
.dropdown-menu1 {
	border: 1px solid lightgray;
	position: fixed;
	background-color: white;
	border-radius: 6px;
	z-index: 999;
	top: 60%;
	right: 20%;
	width: 120px;
}



.veryLowOpacity{
    background-color: rgba(0, 176, 80, 0.2);
}
.LowOpacity{
    background-color: rgba(146, 208, 80, 0.2);
}
.ModerateOpacity{
    background-color: rgba(255, 153, 0, 0.2);
}
.HighOpacity{
    background-color: rgba(247, 196, 196, 0.2);
}
.veryHighOpacity{
    background-color: rgba(192, 0, 0, 0.2);
}

</style>
<script lang="ts">
import { emitter } from '@/main';
import _ from 'lodash';
import { defineComponent } from 'vue';
import pagination from '@/components/pagination.vue';
import moment from 'moment';
import { mapGetters } from 'vuex';
import dotsLoader from './dotsLoader.vue';

export default defineComponent({
    props: ['tableRowData', 'headersData', 'openPopup', 'navigate','loadData','tableProps'],
    data(): any {
        return {
            tableHead: [],
            tableRow: [],
            searchTableRow: [],
            entityId: '',
            visibility: false,
            className: '',
            classSort: '',
            currentSort: 'name',
            currentSortDir: 'asc',
            showDropDown: false,
            showBlock: false,
            checklabelsforallevents: [],

            currentPage: '',
            pageSize: '',
            dynamicPagination: '',

            pop: false,
            styling: { left: '', bottom: '' },
            tipText: '',
            tipColumn: '',
            tipIndex: '',
            filters: {},
            selectedTierMapping: [
                {
                    id: 1,
                    value: 'Organization'
                },
                {
                    id: 2,
                    value: 'Process'
                },
                {
                    id: 3,
                    value: 'System'
                }
            ],
            optionsValue: [
                {
                    id: 1,
                    value: 'Accept',
                    route: '/riskAcceptance'
                },
                {
                    id: 2,
                    value: 'Mitigate',
                    route: '/mitigateRisk'
                },
                {
                    id: 3,
                    value: 'Reset',
                    route: '/reset'
                },
                {
                    id: 4,
                    value: 'Delete',
                    route: '/deactive'
                },
                {
                    id: 5,
                    value: 'Clone',
                    route: '/clone'
                },
                {
                    id:6,
                    value:'Priority',
                    route:'/priority'
                },
                {
                    id:7,
                    value:'Avoid',
                    route:'/avoid'
                },
                {
                    id:8,
                    value:'Transfer',
                    route:'/transfer'
                }
            ],
            orgType: [
                {
                    id: 1,
                    value: 'Primary'
                },
                {
                    id: 2,
                    value: 'Subsidiary'
                },
                {
                    id: 3,
                    value: 'Branch'
                }
            ],
            type: [
                {
                    id: 1,
                    value: 'Primary'
                },
                {
                    id: 2,
                    value: 'Secondary'
                }
            ],
            myTasksStatus: [
                {
                    id: 10401,
                    value: 'Open'
                },
                {
                    id: 10402,
                    value: 'In Progress'
                },
                {
                    id: 10408,
                    value: 'Approval'
                },
                {
                    id: 10409,
                    value: 'In Review'
                },
                {
                    id: 10403,
                    value: 'Closed'
                }
            ],
            requestStatus: [
                {
                    id: 10401,
                    value: 'Open'
                },
                {
                    id: 10402,
                    value: 'In Progress'
                },
                {
                    id: 10403,
                    value: 'Approved'
                },
                {
                    id: 10404,
                    value: 'Deleted'
                },
                {
                    id: 10408,
                    value: 'Pending'
                },
                {
                    id: 10409,
                    value: 'On Hold'
                },
                {
                    id: 10410,
                    value: 'Rejected'
                }
            ],
            risksStatusValue: [
                {
                    id: 10401,
                    value: 'Open'
                },
                {
                    id: 10402,
                    value: 'In Progress'
                },
                {
                    id: 10403,
                    value: 'Accepted'
                },
                {
                    id: 10404,
                    value: 'Deleted'
                },
                {
                    id: 10405,
                    value: 'Mitigated'
                },
                {
                    id: 10406,
                    value: 'Partial'
                }
            ],
            requestedFor: [
                {
                    id: 10403,
                    value: 'Acceptance'
                },
                {
                    id: 10405,
                    value: 'Mitigate'
                },
                {
                    id: 10407,
                    value: 'Reset'
                },
                {
                    id: 10404,
                    value: 'Delete'
                }
            ],
            yesNo: [
                {
                    id: 1,
                    value: 'Yes'
                },
                {
                    id: 2,
                    value: 'No'
                }
            ],
            columns: [
                { key: 'statusCode', value: 'Status' },
                { key: 'systemName', value: 'System Name' },
                { key: 'controlName', value: 'Name' },
                { key: 'informationCategory', value: 'Category' },
                { key: 'processName', value: 'Process Name' },
                { key: 'organizationName', value: 'Organization Name' },
                { key: 'target', value: 'Target Name' },
                { key: 'History', value: 'History' },
                { key: 'tier', value: 'Tier' },
                { key: 'createdAt', value: 'First Identified' },
                { key: 'updatedAt', value: 'last Reviewed' },
                { key: 'riskCategory', value: 'Category' },
                { key: 'systemCount', value: 'Occurances' },
                { key: 'taskStatus', value: 'Status' },
                { key: 'taskId', value: 'ID' },
                { key: 'mitigationtaskId', value: 'Task Id' },
                { key: 'mitigationTier', value: 'Tier' },
                { key: 'mitigationtarget', value: 'Target Name' },
                { key: 'createdAtfirst', value: 'First Identified' },
                { key: 'riskOwnerName', value: 'Owner' },
            ],
            dropdownOptions: [
                {
                    key: 'Type',
                    value: [
                        {
                            id: 1,
                            value: 'Primary'
                        },
                        {
                            id: 2,
                            value: 'Subsidiary'
                        },
                        {
                            id: 3,
                            value: 'Branch'
                        }
                    ]
                },
                {
                  key:'hostingType',
                  value:[
                      {
                          id: 'On-premise',
                          value: 'On-premise'
                      },
                      {
                          id: 'Data center',
                          value: 'Data center'
                      },
                      {
                          id: 'Cloud',
                          value: 'Cloud'
                      },
                  ],
                },
                {
                    key: 'subCategory',
                    value: [
                        {
                            id: 10403,
                            value: 'Acceptance'
                        },
                        {
                            id: 10405,
                            value: 'Mitigate'
                        },
                        {
                            id: 10407,
                            value: 'Reset'
                        },
                        {
                            id: 10404,
                            value: 'Delete'
                        }
                    ]
                },
                {
                    key: 'status',
                    value: [
                        {
                            id: 10401,
                            value: 'Open'
                        },
                        {
                            id: 10402,
                            value: 'In Progress'
                        },
                        {
                            id: 10403,
                            value: 'Approved'
                        },
                        {
                            id: 10404,
                            value: 'Deleted'
                        },
                        {
                            id: 10408,
                            value: 'Pending'
                        },
                        {
                            id: 10409,
                            value: 'On Hold'
                        },
                        {
                            id: 10410,
                            value: 'Rejected'
                        }
                    ]
                },
                {
                    key: 'statusCode',
                    value: [
                        {
                            id: 10401,
                            value: 'Open'
                        },
                        {
                            id: 10402,
                            value: 'In Progress'
                        },
                        {
                            id: 10403,
                            value: 'Accepted'
                        },
                        
                        {
                            id: 10404,
                            value: 'Deleted'
                        },
                        {
                            id: 10405,
                            value: 'Mitigated'
                        },
                        {
                            id: 10406,
                            value: 'Partial'
                        },
                        {
                            id: 10408,
                            value: 'Pending'
                        },
                        {
                            id: 10409,
                            value: 'On Hold'
                        },
                        {
                            id: 10410,
                            value: 'Rejected'
                        }
                    ]
                },
                {
                    key: 'tier',
                    value: [
                        {
                            id: 1,
                            value: 'Organization'
                        },
                        {
                            id: 2,
                            value: 'Process'
                        },
                        {
                            id: 3,
                            value: 'System'
                        }
                    ]
                },
                {
                    key: 'mitigationTier',
                    value: [
                        {
                            id: 1,
                            value: 'Organization'
                        },
                        {
                            id: 2,
                            value: 'Process'
                        },
                        {
                            id: 3,
                            value: 'System'
                        }
                    ]
                },
                {
                    key: 'type',
                    value: [
                        {
                            id: 1,
                            value: 'Primary'
                        },
                        {
                            id: 2,
                            value: 'Secondary'
                        }
                    ]
                },
                {
                    key: 'taskStatus',
                    value: [
                        {
                            id: 10401,
                            value: 'Open'
                        },
                        {
                            id: 10402,
                            value: 'In Progress'
                        },
                        {
                            id: 10408,
                            value: 'Approval'
                        },
                        {
                            id: 10409,
                            value: 'In Review'
                        },
                        {
                            id: 10403,
                            value: 'Closed'
                        }
                    ]
                },
                {
                    key: 'publicFacing',
                    value: [
                        {
                            id: 1,
                            value: 'Yes'
                        },
                        {
                            id: 2,
                            value: 'No'
                        }
                    ]
                },
                {
                    key:'severity',
                    value:[
                        {
                            id:1,
                            value:'Very Low'
                        },
                        {
                            id:2,
                            value:'Low'
                        },
                        {
                            id:3,
                            value:'Moderate'
                        },
                        {
                            id:4,
                            value:'High'
                        },
                        {
                            id:5,
                            value:'Very High'
                        }
                       
                    ]
                },
                {
                    key:'confValue',
                    value:[
                        {
                            id:1,
                            value:'Very Low'
                        },
                        {
                            id:2,
                            value:'Low'
                        },
                        {
                            id:3,
                            value:'Moderate'
                        },
                        {
                            id:4,
                            value:'High'
                        },
                        {
                            id:5,
                            value:'Very High'
                        }
                       
                    ]
                },
                 {
                    key:'intValue',
                    value:[
                        {
                            id:1,
                            value:'Very Low'
                        },
                        {
                            id:2,
                            value:'Low'
                        },
                        {
                            id:3,
                            value:'Moderate'
                        },
                        {
                            id:4,
                            value:'High'
                        },
                        {
                            id:5,
                            value:'Very High'
                        }
                       
                    ]
                },
                {
                    key:'avaiValue',
                    value:[
                        {
                            id:1,
                            value:'Very Low'
                        },
                        {
                            id:2,
                            value:'Low'
                        },
                        {
                            id:3,
                            value:'Moderate'
                        },
                        {
                            id:4,
                            value:'High'
                        },
                        {
                            id:5,
                            value:'Very High'
                        }
                       
                    ]
                },
                {
                    key:'scValue',
                    value:[
                        {
                            id:1,
                            value:'Very Low'
                        },
                        {
                            id:2,
                            value:'Low'
                        },
                        {
                            id:3,
                            value:'Moderate'
                        },
                        {
                            id:4,
                            value:'High'
                        },
                        {
                            id:5,
                            value:'Very High'
                        }
                       
                    ]
                },
                {
                    key:'likeValue',
                    value:[
                        {
                            id:1,
                            value:'Very Low'
                        },
                        {
                            id:2,
                            value:'Low'
                        },
                        {
                            id:3,
                            value:'Moderate'
                        },
                        {
                            id:4,
                            value:'High'
                        },
                        {
                            id:5,
                            value:'Very High'
                        }
                       
                    ]
                },
                {
                    key:'opsValue',
                    value:[
                        {
                            id:1,
                            value:'Very Low'
                        },
                        {
                            id:2,
                            value:'Low'
                        },
                        {
                            id:3,
                            value:'Moderate'
                        },
                        {
                            id:4,
                            value:'High'
                        },
                        {
                            id:5,
                            value:'Very High'
                        }
                       
                    ]
                },
                {
                    key:'severitypreview',
                    value:[
                        {
                            id:1,
                            value:'Very Low'
                        },
                        {
                            id:2,
                            value:'Low'
                        },
                        {
                            id:3,
                            value:'Moderate'
                        },
                        {
                            id:4,
                            value:'High'
                        },
                        {
                            id:5,
                            value:'Very High'
                        }
                       
                    ]
                },
                {
                    key:'infoClassValue',
                    value:[
                {
                    id: 1,
                    value: 'Public'
                },
                {
                    id: 2,
                    value: 'Internal'
                },
                {
                    id: 3,
                    value: 'Confidential'
                },
                {
                    id: 4,
                    value: 'Private'
                },
                {
                    id: 5,
                    value: 'Top Secret'
                }
            ]
                },
                {
                    key:'proCValue',
                    value:[
                        {
                            id:1,
                            value:'Very Low'
                        },
                        {
                            id:2,
                            value:'Low'
                        },
                        {
                            id:3,
                            value:'Moderate'
                        },
                        {
                            id:4,
                            value:'High'
                        },
                        {
                            id:5,
                            value:'Very High'
                        }
                       
                    ]
                },
                {
                    key:'orgValue',
                    value:[
                        {
                            id:1,
                            value:'Very Low'
                        },
                        {
                            id:2,
                            value:'Low'
                        },
                        {
                            id:3,
                            value:'Moderate'
                        },
                        {
                            id:4,
                            value:'High'
                        },
                        {
                            id:5,
                            value:'Very High'
                        }
                       
                    ]
                },
                {
                    key:'businessValue',
                    value:[
                        {
                            id:1,
                            value:'Very Low'
                        },
                        {
                            id:2,
                            value:'Low'
                        },
                        {
                            id:3,
                            value:'Moderate'
                        },
                        {
                            id:4,
                            value:'High'
                        },
                        {
                            id:5,
                            value:'Very High'
                        }
                       
                    ]
                },
                {
                    key:'techValue',
                    value:[
                        {
                            id:1,
                            value:'Very Low'
                        },
                        {
                            id:2,
                            value:'Low'
                        },
                        {
                            id:3,
                            value:'Moderate'
                        },
                        {
                            id:4,
                            value:'High'
                        },
                        {
                            id:5,
                            value:'Very High'
                        }
                       
                    ]
                },
                {
                    key:'finValue',
                    value:[
                        {
                            id:1,
                            value:'Very Low'
                        },
                        {
                            id:2,
                            value:'Low'
                        },
                        {
                            id:3,
                            value:'Moderate'
                        },
                        {
                            id:4,
                            value:'High'
                        },
                        {
                            id:5,
                            value:'Very High'
                        }
                       
                    ]
                },
                {
                    key:'legalValue',
                    value:[
                        {
                            id:1,
                            value:'Very Low'
                        },
                        {
                            id:2,
                            value:'Low'
                        },
                        {
                            id:3,
                            value:'Moderate'
                        },
                        {
                            id:4,
                            value:'High'
                        },
                        {
                            id:5,
                            value:'Very High'
                        }
                       
                    ]
                },
                {
                    key:'overAllValue',
                    value:[
                        {
                            id:1,
                            value:'Very Low'
                        },
                        {
                            id:2,
                            value:'Low'
                        },
                        {
                            id:3,
                            value:'Moderate'
                        },
                        {
                            id:4,
                            value:'High'
                        },
                        {
                            id:5,
                            value:'Very High'
                        }
                       
                    ]
                },
                {
                    key:'infopreview',
                    value:[
                {
                    id: 1,
                    value: 'Public'
                },
                {
                    id: 2,
                    value: 'Internal'
                },
                {
                    id: 3,
                    value: 'Confidential'
                },
                {
                    id: 4,
                    value: 'Private'
                },
                {
                    id: 5,
                    value: 'Top Secret'
                }
            ]
                }

            ],
            classificationData: [
                {
                    id: 10403,
                    value: 'Public'
                },
                {
                    id: 10407,
                    value: 'Internal'
                },
                {
                    id: 10406,
                    value: 'Confidential'
                },
                {
                    id: 10405,
                    value: 'Private'
                },
                {
                    id: 10410,
                    value: 'Top Secret'
                }
            ],
            priorityData: [
                {
                    id: 5,
                    value: 'P5'
                },
                {
                    id: 4,
                    value: 'P4'
                },
                {
                    id: 3,
                    value: 'P3'
                },
                {
                    id: 2,
                    value: 'P2'
                },
                {
                    id: 1,
                    value: 'P1'
                }
            ],
            users: [],
            optionsUserArray: [],
            selectedRowIndex: '',
            showTableActionsDropdown: false,
            tableDataActionOptions: [
                {
                    id: 1,
                    value: 'Delete'
                },
                {
                    id: 2,
                    value: 'Edit'
                }
            ],
            priorityObject: {},
            mouseY: 0,
            dynamicLeftPosition: 0,
            dynamicTopPosition: 0,
        };
    },
    components: {
        pagination,
        dotsLoader
    },
    async mounted() {
        this.initialize();
        await this.getAllUsers();
        emitter.on('visible', (isBoolean: any) => {
            this.visibility = isBoolean;
            this.visibility == false ? (this.filters = {}) : '';
        });
        // emitter.on('*', (type, booleanValue) => {
        // 	if (type == 'apps-sidemenubar' || type == 'toggle-sidebar') {
        // 		this.showDropDown == true ? (this.showDropDown = false) : '';
        // 	} else {
        // 		if (this.showBlock === true) {
        // 			this.showDropDown == true ? (this.showDropDown = booleanValue) : '';
        // 		} else if (this.showBlock === false) {
        // 			this.showDropDown == true ? '' : (this.showDropDown = booleanValue);
        // 			this.showBlock = true;
        // 		}
        // 	}
        // });
        emitter.on('*', (type, booleanValue) => {
            if (type === 'apps-sidemenubar' || type === 'toggle-sidebar') {
                if (this.showDropDown) {
                    this.showDropDown = false;
                }
                if (this.showTableActionsDropdown) {
                    this.showTableActionsDropdown = false;
                }
            } else {
                if (this.showBlock === true && type != 'deActiviteToolTip') {
                    if (this.showDropDown) {
                        this.showDropDown = booleanValue;
                    }
                    if (this.showTableActionsDropdown) {
                        this.showTableActionsDropdown = booleanValue;
                    }
                } else {
                    if (!this.showDropDown) {
                        this.showDropDown = booleanValue;
                    }
                    if (!this.showTableActionsDropdown) {
                        this.showTableActionsDropdown = booleanValue;
                    }
                }
                this.showBlock = true;
            }
        });
        window.addEventListener('resize', this.handleResize);
    },
    beforeDestroy() {
        window.removeEventListener('resize', this.handleResize);
    },
    computed: {
        ...mapGetters({
            userInfo: 'userInfo',
            dbSeverity: 'dbSeverity',
            dbLikelihood: 'dbLikelihood',
            dbBussiness: 'dbBussiness',
            dbOrganization: 'dbOrganization',
            dbOperations: 'dbOperations',
            dbLegal: 'dbLegal',
            dbFinancial: 'dbFinancial',
            dbTechnical: 'dbTechnical',
            dbProcessCriticality: 'dbProcessCriticality',
            dbAvailability: 'dbAvailability',
            dbConfidentiality: 'dbConfidentiality',
            dbIntegrity: 'dbIntegrity',
            dbInformationClassification: 'dbInformationClassification',
            dbSecurityCategory: 'dbSecurityCategory',
            dbPriority: 'dbPriority',
            dbOverAllImpact:'dbOverAllImpact'
        }),

        sortedData: function (): any {
            return [...this.tableRow]
                .filter((row: any) => {
                    for (const key in this.filters) {
                        const inputValue = this.filters[key];
                        if (inputValue !== '' && row[key]) {
                            if (Number.isInteger(row[key])) {
                                if (String(row[key]).toLowerCase().includes(String(inputValue).toLowerCase()) === false) {
                                return false;
                    }
                            } else {
                                if (!row[key].toLowerCase().includes(inputValue.toLowerCase())) {
                                    return false;
                                }
                            }
                        }
                    }
                    return true;
                })
                .sort((a, b) => {
                    let modifier = 1;
                    if (this.currentSortDir === 'desc') modifier = -1;
                    if (a[this.currentSort] < b[this.currentSort]) return -1 * modifier;
                    if (a[this.currentSort] > b[this.currentSort]) return 1 * modifier;
                    return 0;
                })
                .filter((row, index) => {
                    this.dynamicPagination = index + 1;
                    let start = (this.currentPage - 1) * this.pageSize;
                    let end = this.currentPage * this.pageSize;
                    if (index >= start && index < end) return true;
                });
        },
        columnNames: function (): any {
            const names = new Set();
            for (const key of this.headersData) {
                if (key != 'entityId' && key != '_id') names.add(key);
            }
            return names;
        },
		isClickNearBottom: function (): any {
        // Get the height of the viewport
			const viewportHeight = window.innerHeight;

			// Set a threshold for how close to the bottom counts
			const threshold = 250; // Adjust as necessary

			// Check if the last recorded mouseY is near the bottom
			return this.mouseY > (viewportHeight - threshold);
		},
    },
    watch: {
        visibility(newValue) {
            if (newValue == false) {
                this.initialize();
            }
        },
        tableRowData: {
            handler(newVal, oldVal) {
                this.tableRow = this.tableRowData;
                this.initialize();
                console.log('this.tableRowData[0]', this.tableRowData[0]);
            },
            deep: true
        }
    },
    methods: {
        getLabelFromColumnArrayStore(cameId:any){
            let labelObj:any;
            labelObj = this.checklabelsforallevents.find((l:any) => {return l.value == cameId;})
            
            if(labelObj == undefined){
                return 'N/A'
            }else{
                return labelObj.key;
            }  
        },
        getLabelFromColumnArrayStoreImpact(cameId:any){
            let labelObj:any;

            labelObj = this.dbOverAllImpact.find((l:any) => {return l.value == cameId;})
            
            
            if(labelObj == undefined){
                return 'N/A'
            }else{
                return labelObj.key;
            }  
        },
        getPriorityLabel(cameId:any){
            let labelObj = this.dbPriority.find((l:any) => {return l.value == cameId;})
            if(labelObj == undefined){
                return 'N/A'
            }else{
                return labelObj.key;
            }
        },
        getLabelDefinationName(value:any){
            let obj =  this.checklabelsforallevents.find((l:any) => { return l.value == value; });
            if(obj!=undefined){
                return obj.key
            }else{
                return 'N/A'
            }
        },
        getLabelsArrayForColumnInStore(column: any) {
            if (column == 'securityCategory' || column=='scValue') {
                this.checklabelsforallevents = this.dbSecurityCategory;
                return true;
            }else if(column=='impact'){
                this.checklabelsforallevents = this.dbOverAllImpact;
                return true;
            }else if(column=='severitypreview'){
                this.checklabelsforallevents = this.dbSeverity;
                return true;
            }else if(column=='likelihood' || column=='likeValue'){
                this.checklabelsforallevents = this.dbLikelihood;
                return true;
            }else if(column=='confValue'){
                this.checklabelsforallevents = this.dbConfidentiality;
                return true;
            }else if(column=='intValue'){
                this.checklabelsforallevents = this.dbIntegrity;
                return true;
            }else if(column=='overAllValue'){
                this.checklabelsforallevents = this.dbOverAllImpact;
                return true;
            }else if(column=='avaiValue'){
                this.checklabelsforallevents = this.dbAvailability;
                return true;
            }else if(column=='scValue'){
                this.checklabelsforallevents = this.dbSecurityCategory;
                return true;
            }else if(column=='infoClassValue' || column=='infopreview'){
                this.checklabelsforallevents = this.dbInformationClassification;
                return true;
            }else if(column=='priority' || column=='prioritypreview'){
                this.checklabelsforallevents = this.dbPriority;
                return true;
            }else if(column=='proCValue'){
                this.checklabelsforallevents = this.dbProcessCriticality;
                return true;
            }else if(column=='opsValue'){
                this.checklabelsforallevents = this.dbOperations;
                return true;
            }else if(column=='orgValue'){
                this.checklabelsforallevents = this.dbOrganization;
                return true;
            }else if(column=='businessValue'){
                this.checklabelsforallevents = this.dbBussiness;
                return true;
            }else if(column=='finValue'){
                this.checklabelsforallevents = this.dbFinancial;
                return true;
            }else if(column=='legalValue'){
                this.checklabelsforallevents = this.dbLegal;
                return true;
            }else if(column=='techValue'){
                this.checklabelsforallevents = this.dbTechnical;
                return true;
            }else if(column == 'confidentiality'){
                this.checklabelsforallevents = this.dbConfidentiality;
                return true
            }else if(column == 'integrity'){
                this.checklabelsforallevents = this.dbIntegrity;
                return true
            } else if(column == 'availability'){
                this.checklabelsforallevents = this.dbAvailability;
                return true
            } else if(column == 'overAllValue'){
                this.checklabelsforallevents = this.dbOverAllImpact;
                return true
            }else{
                return false;
            }
        },
        getFilterAlignment(column:any){
            if(column == 'impact'|| column == "securityCategory"){
                return 'flex items-center justify-center pl-1';
            }
        },
        isNearBottom(index:any) {
			if(((index == 0)||(index == 1))&&((this.sortedData.length == 1)||(this.sortedData.length == 2))){
				return false;
			}else{
				// console.log("this.isClickNearBottom", this.isClickNearBottom);
				// console.log("this.isClickNearBottom1", this.mouseY);
				// console.log("this.isClickNearBottom2", window.innerHeight);
				// Here, you can decide whether to consider the click position
				return this.isClickNearBottom || index >= (this.sortedData.length-1); // Adjust condition based on your needs
			}
		},
		dropdownStyle(index:any) {
            const baseStyle = {
                left: this.dynamicLeftPosition + 'px',
                top: this.dynamicTopPosition + 'px',
                display: this.showDropDown ? 'block' : 'none',transform:'',position:'fixed'
            };
            let windowWidth:any = window.innerWidth
            // Only apply transform if window width is less than 1200px and certain conditions are met
            if (windowWidth < 1200 && this.isClickNearBottom && index === 0) {console.log("hjgfdgh",windowWidth < 1200);
            
                baseStyle.transform = 'translateY(-123%)';
            }

            return baseStyle;
		},
		updateMouseY(event: MouseEvent) {
            this.mouseY = event.clientY; 
        },
        initialize() {
            this.tableRow = this.tableRowData;
            this.searchTableRow = this.tableRowData;

            this.tableRowData[0] == undefined ? '' : (this.entityId = this.tableRowData[0].entityId);
            console.log('this.entityId', this.entityId);
        },
         getColumnHeadWidth(column: any) {
            if(this.tableProps != undefined){
                let a = this.tableProps.headerWidth.find((obj:any)=>{return obj.key == column});
                if(a!= undefined){
                    return a.value
                }else{
                    return ''
                }
            }
            else{
            if (column == 'infoAction' || column == 'systemName') {
                return 'w-36';
            } else if(column == 'risk'){
                return 'w-32';
            }
            else if(column == 'priority' || column == 'statusCode'){
                return 'w-28';
            } else if (column == 'Name' || column == 'Email' || column == 'entityName' || column == 'entityDisplayName' || column == 'threatSource' || column == 'threatCategory' || column == 'threatEvent') {
                return 'w-1/4';
            }
            else if (column == 'status'||column == 'taskId' || column == 'type' || column == 'Phone'|| column == 'mitigationTier'  || column == 'mitigationtaskId'|| column == 'createdAtfirst') {
                return 'w-1/12';
            }
            else if (column == 'description') {
                return 'w-48';
            }
            else if (column == 'Business Process Name'|| column == 'department' ) {
                return 'w-1/3';
            }
            else if (column == 'publicFacing' || column == 'hostingType'||column == 'securityCategory'||column == 'impact'||column == 'likelihood'||column == 'organizationName'||column == 'processName'||column == 'systemName'||column == 'vulnerabilityId'||column == 'industrySectorName') {
                return 'w-1/5';
            }
            else if (column == 'category') {
                return 'w-1/3';
            }else if (column == 'target' || column == 'controlName'|| column == 'mitigationtarget' || column=='taskStatus'|| column=='threatId') {
                return 'w-1/6';
            }
            else if (column == 'vulnerability') {
                return 'w-2/5';
            } else if (column == 'securityCategory') {
                return 'flex items-center justify-center';
            }else if( column == 'confLabel' || column == 'intLabel' || column == 'avaiLabel' || column == 'scLabel' ||
                column == 'likeLabel' || column == 'opsLabel' || 
                column == 'infoClassLabel' || column == 'proCLabel' || column == 'orgLabel' || column == 'businessLabel' || column == 'techLabel' || column == 'finLabel' || column == 'legalLabel'|| column == 'threatCategory' || column == 'threatEvent'|| column == 'vulnerability' || column == 'confValue' || column == 'intValue' || column == 'avaiValue' || column == 'scValue' ||
                column == 'likeValue' || column == 'opsValue' || column == 'severitypreview' || column == 'prioritypreview' ||
                column == 'infoClassValue' || column == 'proCValue' || column == 'orgValue' || column == 'businessValue' ||
                column == 'techValue' || column == 'finValue' || column == 'legalValue' || column=='overAllLabel' || column == 'overAllValue' || column == 'sevLabel' || column == 'severitypreview' || column == 'prioLabel' || column == 'systemName'||column=='severitypreview'){
                return 'w-1/5';
            }
            // else if ( column == 'severity') {
            //     return 'w-1/6';
            // }
            else if (column == 'actionButton' || column == 'History' || column == 'tableDataActions' ) {
                return 'w-16';
            } 
            else if (column == 'category') {
                return 'w-44';
            }
            else {
                return '';
            }
        }
        },

        // getColumnHeadWidth(column: any) {
        //     if (column == 'category' || column == 'securityCategory' || column == 'information' || column == 'risk' || column == 'threatSource') {
        //         return 'w-56';
        //     }else if (column == 'severity' || column == 'impact' || column == 'likelihood' || column == 'threatId' ||  column == 'vulnerabilityId' || column=='securityCategory' || column =='systemName' || column == 'publicFacing' || column == 'hostingType') {
        //         return 'w-36';
        //     }else if( column == 'confLabel' || column == 'intLabel' || column == 'avaiLabel' || column == 'scLabel' ||
        //         column == 'likeLabel' || column == 'opsLabel' || 
        //         column == 'infoClassLabel' || column == 'proCLabel' || column == 'orgLabel' || column == 'businessLabel' || column == 'techLabel' || column == 'finLabel' || column == 'legalLabel'|| column == 'threatCategory' || column == 'threatEvent'|| column == 'vulnerability' || column == 'confValue' || column == 'intValue' || column == 'avaiValue' || column == 'scValue' ||
        //         column == 'likeValue' || column == 'opsValue' || column == 'severitypreview' || column == 'prioritypreview' ||
        //         column == 'infoClassValue' || column == 'proCValue' || column == 'orgValue' || column == 'businessValue' ||
        //         column == 'techValue' || column == 'finValue' || column == 'legalValue' || column=='overAllLabel' || column == 'overAllValue' || column == 'sevLabel' || column == 'severitypreview' || column == 'prioLabel' || column == 'systemName'){
        //         return 'w-32'
        //     }else if(column == 'description'){
        //         return 'w-52'
        //     }else if(column=='tableDataActions') {
        //         return 'w-24'
        //     }
        //     // else if (column == 'vulnerability') {
        //     //     return 'w-2/5';
        //     // } else if (column == 'securityCategory') {
        //     //     return 'flex items-center justify-center';
        //     // } 
        //     // else if (column == 'actionButton' || column == 'History' || column == 'tableDataActions') {
        //     //     return 'w-20';
        //     // } else {
        //     //     return '';
        //     // }
        // },
         getHeaderAlignment(column: any)
        {
            //for future usage , written conditions individuallly
            if(column == 'priority')
            {
                return 'pl-4 w-28';
            }else if(column == 'securityCategory')
            {
                return '';
            }
            else if(column == 'status')
            {
                return 'w-28 pl-4';
            }
            else if(column == 'Name')
            {
                return 'w-36';
            }
            else if( column == 'Contract Start Date' || column == 'Contract Expiry Date'){
                return 'w-20'
            }
            else if(column == 'Job Title')
            {
                return 'w-36';
            }
            else if(column == 'mitigationtaskId')
            {
                return 'w-16';
            }
            else if(column == 'type' || column == 'controlName' || column == 'mitigationTier')
            {
                return 'w-28';
            }
            else if(column == 'Email' || column == 'Phone' ){
                return 'w-28';
            }
            else if(column == 'createdAt' || column == 'updatedAt'){
                return 'w-16';
            }
            else if(column == 'Display Name')
            {
                return 'w-36';
            }
            else if(column == 'statusCode')
            {
                return 'pl-4 w-28';
            }
            else if(column == 'createdAtfirst')
            {
                return 'w-36';
            }
            else if(column == 'taskStatus')
            {
                return 'w-24 pl-4';
            }
            else if(column=='impact')
            {
                return ' items-center justify-center';
            } 
            else if(column=='likelihood')
            {
                return 'pl-3 w-32';
            }  
            else if(column=='category')
            {
                return 'w-16';
            }
            // else if(column == 'severitypreview')
            // {
            //     return 'items-center justify-center';
            // } 
            else if(column == 'tier' )
            {
                return 'w-28';
            }
            else if(column=='subCategory')
            {
                return 'w-28';
            }
            else if(column == 'riskOwner' || column == 'riskOwnerName'  )
            {
                return 'w-24';
            }
            // else if(column == 'hostingType' )
            // {
            //     return 'w-36';
            // }
            else if(column == 'information' )
            {
                return 'w-32';
            }
            // else if(column == 'description' )
            // {
            //     return 'w-32';
            // }
            // else if(column == 'systemName' )
            // {
            //     return 'w-36';
            // }
            // else if(column == 'securityCategory')
            // {
            //     return 'w-full items-center justify-center';
            // }
            else if(column == 'infopreview')
            {
                return 'items-center justify-center';
            }
            else if(column == 'systemCount')
            {
                return 'items-center justify-center';
            }
            // else if(column == 'overAllValue')
            // {
            //     return 'items-center justify-center';
            // }
            else if(column == 'createdAt' )
            {
                return 'w-32';
            }
            else if(column == 'updatedAt' )
            {
                return 'w-32';
            }
            else if(column == 'mitigationtarget' )
            {
                return 'w-40';
            }
            else if(column == 'target' )
            {
                return 'w-28';
            }
            else if(column == 'risk' )
            {
                return 'w-32';
            }
            else if(column == 'severity' )
            {
                return 'w-20';
            }
            else if(column == 'organizationName' )
            {
                return 'w-36';
            }
            else if(column == 'riskCategory' )
            {
                return 'w-32';
            }
            else if(column == 'confValue' || column == 'intValue' || column == 'avaiValue' || column == 'scValue' ||
            column == 'likeValue' || column == 'opsValue' || column == 'prioritypreview' ||
            column == 'infoClassValue' || column == 'proCValue' || column == 'orgValue' || column == 'businessValue' ||
            column == 'techValue' || column == 'finValue' || column == 'legalValue' || column == 'overAllValue'){
                return 'pl-6';
            }
            else{
                return '';
            }
        },
        getFilterPosition(column:any){
            if(column == 'priority')
            {
                return '';
            }
            else if(column == 'statusCode')
            {
                return 'flex items-center justify-center';
            }
            else if(column == 'severity'  || column == 'severitypreview' || column == 'impact' || column == 'likelihood')
            {
                return 'flex items-center justify-center';
            } 
            else if(column == 'securityCategory')
            {
                return 'flex items-center justify-center';
            }
            else if(column == 'infopreview')
            {
                return 'flex items-center justify-center';
            }
            else if(column == 'overAllValue')
            {
                return 'flex items-center justify-center';
            }
           else if(column == 'confValue' || column == 'intValue' || column == 'avaiValue' || column == 'scValue' ||
            column == 'likeValue' || column == 'opsValue' || column == 'prioritypreview' ||
            column == 'infoClassValue' || column == 'proCValue' || column == 'orgValue' || column == 'businessValue' ||
            column == 'techValue' || column == 'finValue' || column == 'legalValue'){
                return 'flex';
            }
            else{
                return '';
            }
        },
        getFilterSize(column: any)
        {
            //for future usage , written conditions individuallly
            if(column == 'priority')
            {
                return 'w-28';
            }
            else if(column == 'statusCode')
            {
                return 'w-28';
            }
            else if(column == 'severity'  || column == 'severitypreview')
            {
                return 'severity-filter';
            } 
            else if(column == 'tier')
            {
                return 'tier-filter';
            }
            else if(column == 'riskCategory')
            {
                return 'risk-category-filter';
            }
            else if(column == 'securityCategory')
            {
                return 'w-28';
            }
            else if(column == 'infopreview')
            {
                return 'w-28';
            }
            else if(column == 'systemCount')
            {
                return ' flex items-center justify-center w-20';
            }
            else if(column == 'overAllValue')
            {
                return 'w-28';
            }
            else if(column == 'confValue' || column == 'intValue' || column == 'avaiValue' || column == 'scValue' ||
            column == 'likeValue' || column == 'opsValue' || column == 'prioritypreview' ||
            column == 'infoClassValue' || column == 'proCValue' || column == 'orgValue' || column == 'businessValue' ||
            column == 'techValue' || column == 'finValue' || column == 'legalValue'){
                return 'w-28';
            }
            else{
                return '';
            }

        },
        getPaddingForEmptyColumnHead(column: any) {
            if (column == 'securityCategory') {
                return 'pl-14';
            } else if (column == 'updatedAt') {
                return 'pl-0';
            } else {
                return 'pl-6';
            }
        },
        paginationUpdations(currentPage: any, pageSize: any) {
            console.log('Pagination processing... ', currentPage, pageSize);
            this.currentPage = currentPage;
            this.pageSize = pageSize;
        },
        sort(tableHead: any) {
            this.className = tableHead;
            if (tableHead === this.currentSort) {
                this.currentSortDir = this.currentSortDir === 'asc' ? 'desc' : 'asc';
            }
            this.currentSort = tableHead;
            this.classSort = this.currentSortDir === 'asc' ? 'sort_asc' : 'sort_desc';
        },
        navigateWorkLog(rowData: any) {
            if (this.$route.path.includes('/systemInformation') && this.$route.path.includes('/org/system')) {
                this.$router.push({ name: 'worklogFromSystem', params: { riskId: rowData._id, systemId: rowData.systemId }, query: { systems: true } });
            } else if (this.$route.path.includes('/processInformation') && this.$route.path.includes('/org/process')) {
                this.$router.push({ name: 'worklogFromProcess', params: { riskId: rowData._id, processId: rowData.processId }, query: { process: true } });
            } else if (this.$route.path.includes('/organizationInformation')) {
                this.$router.push({ name: 'worklogFromOrganization', params: { riskId: rowData._id, orgId: rowData.orgId }, query: { organization: true } });
            } else if (this.$route.path.includes('/systemRisks')) {
                this.$router.push({ name: 'worklogFromSystem', params: { riskId: rowData._id, systemId: rowData.systemId }, query: { Risks: true } });
            } else if (this.$route.path.includes('/processRisks')) {
                this.$router.push({ name: 'worklogFromProcess', params: { riskId: rowData._id, processId: rowData.processId }, query: { Risks: true } });
            } else if (this.$route.path.includes('/orgRisks')) {
                this.$router.push({ name: 'worklogFromOrganization', params: { riskId: rowData._id, orgId: rowData.orgId }, query: { Risks: true } });
            }
        },
        changeInfoRisksPage(row: any, route: any) {
            emitter.emit('gotoInfoRisksPage', row);
        },
        changeRoute(row: any, route: any) {
            let selectedActionObj = {
                data: row,
                route: route
            };

            if (this.$route.path.includes('/org/system')) {
                emitter.emit('changeRouteInSystemInfo', selectedActionObj);
            } else if (this.$route.path.includes('/org/risks')) {
                emitter.emit('changeRouteInRisks', selectedActionObj);
            } else if (this.$route.path.includes('/systemRisks') || this.$route.path.includes('/processRisks') || this.$route.path.includes('/orgRisks')) {
                emitter.emit('changeRouteInTierRisks', selectedActionObj);
            } else if (this.$route.path.includes('/processInformation')) {
                emitter.emit('changeRouteInProcessInfo', selectedActionObj);
            } else if (this.$route.path.includes('/organizationInformation')) {
                emitter.emit('changeRouteInOrgInfo', selectedActionObj);
            }
        },
        toggleDropdown(event: any, index: any,column: any) {
            if (column == 'actionButton' || column == 'infoAction' || column == 'tableDataActions') {
                if (this.selectedRowIndex === index) {
                    this.showDropDown = !this.showDropDown;
                } else {
                    this.showDropDown = true;
                    this.selectedRowIndex = index;
                }
            } 

			const currentWidth = window.innerWidth;
			const { clientX, clientY } = event;

			let offset: any = 0;
			
			// Calculate available space below and above the cursor
			const spaceBelow = window.innerHeight - clientY;
			const spaceAbove = clientY;
			// console.log("fhjkhkjdalkjs",spaceBelow);
			// console.log("fhjkhkjdalkjs1",spaceAbove);

			// Position the dropdown based on available space
			if (spaceBelow > spaceAbove) {
                    if(column == 'actionButton' ){
                    // There is space below the cursor, so show the dropdown downwards
                    offset = 18; // Adjust this value if needed
                    this.dynamicTopPosition = clientY + offset; // Position the dropdown below the click
                }else{
                    // There is space above the cursor, so show the dropdown upwards
                    offset = 18; // Adjust this value if needed
                    this.dynamicTopPosition = clientY + offset;
                }
			} else if (spaceAbove > spaceBelow) {
                if(column == 'actionButton' ){
                    // There is space above the cursor, so show the dropdown upwards
                    offset = 20; // Adjust this value if needed
                    this.dynamicTopPosition = clientY+ offset; // Position the dropdown above the click
                }else{
                    // There is space above the cursor, so show the dropdown upwards
                    offset = -2; // Adjust this value if needed
                    this.dynamicTopPosition = clientY+ offset;
                }
			} else {
				// If there's no space either above or below, we can close the dropdown or do nothing
				this.showDropDown = false;
				return;
			}
            this.mouseY = event.clientY;
			// Horizontal position (keeping it based on clientX)
			this.dynamicLeftPosition = clientX - 100; // Adjust based on your design needs
			},
        actionOnClickingTableRow(rowData: any, index: any, column: any) {
            console.log("dsfhjkgjhggg")
            if (column == 'actionButton' || column == 'infoAction' || column == 'tableDataActions') {
                emitter.off('rowData', rowData);
            } else if (column == 'deleteinfo') {
                emitter.emit('deleteRow', rowData);
            } else if (column == 'History') {
                this.navigateWorkLog(rowData);
            } else if (column == 'reportAction') {
                console.log('a');
            } else if (column == 'deleteIcon') {
                let obj = {
                    entity: rowData,
                    index: index
                };
                emitter.emit('forDelete', obj);
            } else if (this.openPopup == true) {
                let obj = {
                    entityId: rowData.entityId,
                    index: index
                };
                let popupdata = {
                    entityData: rowData,
                    indexx: index
                };
                // emitter.emit('showData', obj);
                
                if(this.$route.path.includes('/org/approvals')){
                    emitter.emit('approvaldata', rowData);
                }else{
                    emitter.emit('showData123', popupdata);
                }
                
            } else if (this.entityId == 20008 && this.navigate == true && this.openPopup == false) {
                this.goToSystemRisk(rowData);
            } else if (this.entityId == 20012 && this.navigate == true && this.openPopup == false) {
                this.openTaskEditing(rowData._id);
            } else if (this.entityId == 20009 && this.navigate == true && this.openPopup == false) {
                this.gotoSystemInformation(rowData._id);
            } else if (this.entityId == 20006 && this.navigate == true && this.openPopup == false) {
                this.gotoProcessInformation(rowData._id);
            } else if (this.entityId == 20007 && this.navigate == true && this.openPopup == false) {
                emitter.emit('gotoInfoRisksPage', rowData);
            } else if (this.entityId == 20001 && this.navigate == true && this.openPopup == false) {
                this.gotoOrganizationInformation(rowData._id);
            } else {
                console.log('No action for TR', this.entityId);
                emitter.emit('rowData', rowData);
            }
            this.showBlock = false;
            this.showDropDown = false
        },
        toolTip(column: any, e: any, index: any, row: any, status: any) {
            
            this.tipColumn = column;
            this.tipIndex = index;

            switch (status) {
                case 'text':
                    this.tipText = row[column];
                    this.handleTextTooltip(e);
                    break;
                case 'circleButton':
                    let findVal = this.dbSeverity.find((obj:any)=>{return obj.value == row[column]})

                    if(findVal != undefined){
                        this.tipText = findVal.key
                    }else{
                        this.tipText = row[column]
                    }

                    let obj:any = {
                        tipText : this.tipText
                    }
                    emitter.emit('activiteToolTip',obj)
            }
        },
        handleTextTooltip(e: any) {
            const container = e.currentTarget.closest('.table-ellipsis');
            if (container && this.isTextOverflowed(container)) {
                let obj:any = {
                    tipText : this.tipText
                }

              emitter.emit('activiteToolTip',obj)
            }
        },
        isTextOverflowed(element: any) {
            return element.scrollHeight > element.clientHeight;
        },
        getDateFormat(date: any) {
            if (date == '') {
                return 'None';
            } else {
                let now = moment(date);
                return now.format('DD-MM-YYYY');
            }
        },
        openTaskEditing(taskId: any) {
            this.$router.push({ name: 'taskEditPage', params: { taskId: taskId } });
        },
        gotoSystemInformation(systemId: any) {
            this.$router.push({ name: 'systemInformation', params: { id: systemId } });
        },
        gotoProcessInformation(processId: any) {
            this.$router.push({ name: 'processInformation', params: { processId: processId } });
        },
        goToSystemRisk(risk: any) {
            console.log('TTTTT',risk)
            if (Object.keys(risk).includes('systemId')) {
                this.$router.push({ name: 'tierRisks', params: { riskId: risk.mappedRisk, type: 'systemRisks' } });
            } else if (Object.keys(risk).includes('processId')) {
                this.$router.push({ name: 'tierRisks', params: { riskId: risk.mappedRisk, type: 'processRisks' } });
            } else if (Object.keys(risk).includes('orgId')) {
                this.$router.push({ name: 'tierRisks', params: { riskId: risk.mappedRisk, type: 'orgRisks' } });
            }
        },
        gotoOrganizationInformation(orgId: any) {
            this.$router.push({ name: 'organizationInformation', params: { orgId: orgId } });
        },
        getOwnerName(ownerId: any) {
            let user;
            // if(Object.keys(ownerId).includes(ownerId)){
                user = this.optionsUserArray.find((obj: any) => {
                    return (obj.value == ownerId.value);
                });
            // }
            
            if (user == undefined) {
                let empty = 'N/A';
                return empty;
            } else {
                return user.label;
            }
        },
        colorPropBg(format: any, scaleScore: any) {
            if (format == 'circle') {
                if (scaleScore == 5) {
                    return ['bg-darkred'];
                } else if (scaleScore == 4) {
                    return ['bg-error'];
                } else if (scaleScore == 3) {
                    return ['bg-yellow'];
                } else if (scaleScore == 2) {
                    return ['bg-green'];
                } else if (scaleScore == 1) {
                    return ['bg-lightgreen'];
                }
            } else if (format == 'border') {
                if (scaleScore == 5) {
                    return ['border-darkred border-4'];
                } else if (scaleScore == 4) {
                    return ['border-error border-4'];
                } else if (scaleScore == 3) {
                    return ['border-yellow border-4'];
                } else if (scaleScore == 2) {
                    return ['border-green border-4'];
                } else if (scaleScore == 1) {
                    return ['border-lightgreen border-4'];
                }
            }
        },
        colorRiskText(value: any) {
            if (value == 1) {
                return ['text-lightgreen font-semibold'];
            } else if (value == 2) {
                return ['text-green font-semibold'];
            } else if (value == 3) {
                return ['text-yellow font-semibold'];
            } else if (value == 4) {
                return ['text-error font-semibold'];
            } else if (value == 5) {
                return ['text-darkred font-semibold'];
            }
        },
        colorRiskBg(value: any) {
            if (value == 1) {
                return ['veryLowOpacity border-solid border border-lightgreen'];
            } else if (value == 2) {
                return ['LowOpacity border-solid border border-green'];
            } else if (value == 3) {
                return ['ModerateOpacity border-solid border border-yellow'];
            } else if (value == 4) {
                return ['HighOpacity border-solid border border-error'];
            } else if (value == 5) {
                return ['veryHighOpacity border-solid border border-darkred'];
            }
        },
        colorsForStatusesText(stat: any) {
            if (stat == 10410) {
                return ['text-darkred', 'border-solid border border-darkred font-semibold'];
            } else if (stat == 10408) {
                return ['text-primary', 'border-solid border border-primary font-semibold'];
            } else if (stat == 10403) {
                return ['text-lightgreen', 'border-solid border border-lightgreen font-semibold'];
            } else if (stat == 10409) {
                return ['text-darkred', 'flex justify-center px-2.5 py-1 font-semibold border-solid border border-darkred'];
            } else if (stat == 10402) {
                return ['text-electricGreen', 'flex justify-center px-2.5 py-1 font-semibold border-solid border border-electricGreen'];
            } else if (stat == 10404) {
                return ['text-primary', 'flex justify-center px-2.5 py-1 font-semibold border-solid border border-primary'];
            } else if (stat == 10401) {
                return ['text-darkred', 'flex justify-center px-2.5 py-1 font-semibold border-solid border border-darkred'];
            } else if (stat == 10406) {
                return ['text-yellow', 'flex justify-center px-2.5 py-1 font-semibold border-solid border border-yellow'];
            } else if (stat == 10407) {
                return ['text-green', 'flex justify-center px-2.5 py-1 font-semibold border-solid border border-green'];
            } else if (stat == 10405) {
                return ['text-lightgreen', 'flex justify-center px-2.5 py-1 font-semibold border-solid border border-lightgreen'];
            }
        },
        bgColorsForStatuses(code: any) {
            if (code == 10410) {
                return ['bg-darkred'];
            } else if (code == 10403) {
                return ['bg-lightgreen'];
            } else if (code == 10401) {
                return ['bg-darkred'];
            } else if (code == 10408) {
                return ['bg-primary'];
            } else if (code == 10402) {
                return ['bg-electricGreen'];
            } else if (code == 10404) {
                return ['bg-primary'];
            } else if (code == 10409) {
                return ['bg-darkred'];
            } else if (code == 10406) {
                return ['bg-yellow'];
            } else if (code == 10405) {
                return ['bg-lightgreen'];
            } else if (code == 10407) {
                return ['bg-green'];
            }
        },
        colorRiskStatusBg(Status: any) {
            if (Status == 10401) {
                return ['bg-darkred', 'opacity-10'];
            } else if (Status == 10402) {
                return ['bg-electricGreen', 'opacity-10'];
            } else if (Status == 10403) {
                return ['bg-primary', 'opacity-10'];
            } else if (Status == 10404) {
                return ['bg-primary', 'opacity-10'];
            } else if (Status == 10405) {
                return ['bg-lightgreen', 'opacity-10'];
            } else if (Status == 10406) {
                return ['bg-yellow', 'opacity-10'];
            }
        },
        colorRiskStatusText(Status: any) {
            if (Status == 10401) {
                return ['text-darkred', 'flex justify-center px-2.5 py-1 font-semibold border-solid border border-darkred'];
            } else if (Status == 10402) {
                return ['text-electricGreen', 'flex justify-center px-2.5 py-1 font-semibold border-solid border border-electricGreen'];
            } else if (Status == 10403) {
                return ['text-primary', 'px-2.5 py-1 flex justify-center font-semibold border-solid border border-primary'];
            } else if (Status == 10404) {
                return ['text-primary', 'px-2.5 py-1 flex justify-center font-semibold border-solid border border-primary'];
            } else if (Status == 10405) {
                return ['text-lightgreen', 'flex justify-center px-2.5 py-1 font-semibold border-solid border border-lightgreen'];
            } else if (Status == 10406) {
                return ['text-yellow', 'px-2.5 py-1 flex justify-center font-semibold border-solid border border-yellow'];
            }
        },
        doFalse() {
            emitter.emit('deActiviteToolTip')
        },
        async getAllUsers() {
            await this.$http.get(`${process.env.VUE_APP_ORG_API_URL}/users/getAll`).then((res: any) => {
                this.users = res.data;
            });
            for (var i = 0; i < this.users.length; i++) {
                this.optionsUserArray.push({ label: this.users[i].name, value: this.users[i]._id });
            }
        },
        getOwnerType(input: any) {
            let type = this.orgType.find((l: any) => {
                return l.id == input;
            });
            if (type == undefined) {
                return input;
            } else {
                return type.value;
            }
        },
        // clickOnEditOrDeleteAction(data: any, index: any) {
        //     console.log('Table data', data);
        //     if (this.selectedRowIndex === index) {
        //         this.showTableActionsDropdown = !this.showTableActionsDropdown;
        //     } else {
        //         this.showTableActionsDropdown = true;
        //         this.selectedRowIndex = index;
        //         let selectedRow = {
        //             entityData: data,
        //             indexx: index
        //         };
        //         console.log('asdfghjkl;poiuytreqwertyuiop', selectedRow);
        //         this.popupDataEmittingObject = selectedRow;
        //     }
        //     console.log(data, index);
        // },
        tableDataDeleteAction(data: any, index: any) {
            // this.showTableActionsDropdown = !this.showTableActionsDropdown;
            let obj = {
                entityData: data,
                index: index
            };
            this.showDropDown=false
            emitter.emit('deleteData', obj);
        },
        tableDataEditAction(data: any, index: any) {
            // this.showTableActionsDropdown = !this.showTableActionsDropdown;
            let obj = {
                entityData: data,
                index: index
            };
            emitter.emit('showPopupData', obj);
        },
        changePriority(data: any) {
            emitter.emit('showPriorityPopup', data);
            console.log("showprioritypopup",data);
            
        },
        // textColorForPriority(value: any) {
        //     if (value == 5) {
        //         return ['text-lightgreen fontSizingForPriority font-semibold'];
        //     } else if (value == 4) {
        //         return ['text-green fontSizingForPriority font-semibold'];
        //     } else if (value == 3) {
        //         return ['text-yellow fontSizingForPriority font-semibold'];
        //     } else if (value == 2) {
        //         return ['text-error fontSizingForPriority font-semibold'];
        //     } else if (value == 1) {
        //         return ['text-darkred fontSizingForPriority font-semibold'];
        //     }
        // },
        // bgColorForPriority(value: any) {
        //     if (value == 5) {
        //         return ['border-solid border-4 border-lightgreen'];
        //     } else if (value == 4) {
        //         return ['border-solid border-4 border-green'];
        //     } else if (value == 3) {
        //         return ['border-solid border-4 border-yellow'];
        //     } else if (value == 2) {
        //         return ['border-solid border-4 border-error'];
        //     } else if (value == 1) {
        //         return ['border-solid border-4 border-darkred'];
        //     }
        // },
 
        // textColorForPriority(value: any) {
        //     if (value == 5) {
        //         return ['text-lightgreen font-semibold'];
        //     } else if (value == 4) {
        //         return ['text-green font-semibold'];
        //     } else if (value == 3) {
        //         return ['text-yellow font-semibold'];
        //     } else if (value == 2) {
        //         return ['text-error font-semibold'];
        //     } else if (value == 1) {
        //         return ['text-darkred font-semibold'];
        //     }
        // },
        // bgColorForPriority(value: any) {
        //     if (value == 5) {
        //         return ['bg-veryLowColor border-solid border border-lightgreen'];
        //     } else if (value == 4) {
        //         return ['bg-lowColor border-solid border border-green'];
        //     } else if (value == 3) {
        //         return ['bg-moderateColor border-solid border border-yellow'];
        //     } else if (value == 2) {
        //         return ['bg-highColor border-solid border border-error'];
        //     } else if (value == 1) {
        //         return ['bg-veryHighColor border-solid border border-darkred'];
        //     }
        // },
 
        textColorForPriority(value: any) {
            if (value == 5) {
                return ['text-lightgreen font-semibold'];
            } else if (value == 4) {
                return ['text-green font-semibold'];
            } else if (value == 3) {
                return ['text-yellow font-semibold'];
            } else if (value == 2) {
                return ['text-error font-semibold'];
            } else if (value == 1) {
                return ['text-darkred font-semibold'];
            }
        },
        bgColorForPriority(value: any) {
            if (value == 5) {
                return ['veryLowOpacity border-solid border border-lightgreen'];
            } else if (value == 4) {
                return ['LowOpacity border-solid border border-green'];
            } else if (value == 3) {
                return ['ModerateOpacity border-solid border border-yellow'];
            } else if (value == 2) {
                return ['HighOpacity border-solid border border-error'];
            } else if (value == 1) {
                return ['veryHighOpacity border-solid border border-darkred'];
            }
        },

        getColumnLabel(columnName: any) {
            let labelKeys:any = ['confLabel','intLabel','avaiLabel','scLabel','likeLabel','opsLabel','orgLabel','businessLabel','techLabel','finLabel','legalLabel','sevLabel','prioLabel','infoClassLabel','proCLabel','overAllLabel']
            let ValueKeys:any = ['confValue','intValue','avaiValue','scValue','likeValue','opsValue','orgValue','businessValue','techValue','finValue','legalValue','severitypreview','prioritypreview','infoClassValue','proCValue','overAllValue']

            if (columnName == 'actionButton' || columnName == 'deleteinfo' || columnName == 'infoAction' || columnName == 'deleteIcon' || columnName == 'reportDelete' || columnName == 'reportAction' || columnName == 'tableDataActions' || columnName == 'History') {
                return '';
            }else if(labelKeys.includes(columnName)){
                return 'Label';
            }else if(ValueKeys.includes(columnName)){
                return 'Value';
            }
            
            const column = this.columns.find((col: { key: any }) => col.key === columnName);
            return column ? column.value : columnName;
        },
        getColumnRealtedValueFromArray(columnName: any, type: any) {
            // console.log('sazxsadsad', columnName);
            if(columnName != undefined){
                const option = this.dropdownOptions.find((option: { key: any }) => option.key === type);
                const option1 = option.value.find((soption: { id: any }) => soption.id === columnName);
                return option ? option1.value : [];
            }
        },
        isColumnArrayPresentibDB(column:any){
            let ValueKeys:any = ['impact','likelihood','overAllValue','priority','infopreview','severity','confValue','intValue','avaiValue','scValue','likeValue','opsValue','orgValue','businessValue','techValue','finValue','legalValue','severitypreview','prioritypreview','infoClassValue','proCValue','securityCategory']
            if(ValueKeys.includes(column)){
                return true
            }else{
                return false
            }
        },
        getDbDropdownOptionsForFilter(column:any){
            if(column=='priority' || column=='prioritypreview'){
                return this.dbPriority
            }else if(column=='severity' || column=='severitypreview'){
                return this.dbSeverity
            }else if(column=='impact' || column=='overAllValue'){
                return this.dbOverAllImpact
            }else if(column=='confValue'){
                return this.dbConfidentiality
            }else if(column=='intValue'){
                return this.dbIntegrity
            }else if(column=='avaiValue'){
                return this.dbAvailability
            }else if(column=='scValue' || column=='securityCategory'){
                return this.dbSecurityCategory
            }else if(column=='likeValue' || column=='likelihood'){
                return this.dbLikelihood
            }else if(column=='opsValue'){
                return this.dbOperations
            }else if(column=='orgValue'){
                return this.dbOrganization
            }else if(column=='businessValue'){
                return this.dbBussiness
            }else if(column=='techValue'){
                return this.dbTechnical
            }else if(column=='finValue'){
                return this.dbFinancial
            }else if(column=='legalValue'){
                return this.dbLegal
            }else if(column=='infoClassValue' || column=='infopreview'){
                return this.dbInformationClassification
            }else if(column=='proCValue'){
                return this.dbProcessCriticality
            }
        },
        isColumnArrayPresent(columnName: any) {
            if(columnName == 'priority'){
                return true
            }
            return this.dropdownOptions.some((option: { key: any }) => option.key === columnName);
        },
        getDropdownOptionsForFilter(columnName: any) {
            console.log('sazxsadsad', columnName);
            const option = this.dropdownOptions.find((option: { key: any }) => option.key === columnName);
            return option ? option.value : [];
        },

        checkDateFormat(column: any) {
            if (column == 'createdAt' || column == 'updatedAt' || column=='createdAtfirst') {
                return true;
            }
        },

        checkuserData(column: any) {
            if (column == 'riskOwner') {
                return true;
            }
        },
        shouldShowDisabled(columnName:any)
        {
            
                return false;  
        },
        isFilterNotNecessary(columnName:any)
        {
            if(columnName == 'actionButton' || columnName == 'tableDataActions' || columnName == 'History' || columnName == 'systemCount' || columnName == 'createdAt' || columnName == 'updatedAt' || columnName == 'Phone'||columnName == 'Contract Start Date' || columnName == 'Contract Expiry Date' || columnName == 'first Identified' || columnName == 'last Reviewed' || columnName == 'createdAtfirst')
            {
                return true;
            }
            
                return false;
        },
        handleResize() {
            this.showDropDown = false;
        }
    }
});
</script>

