import store from "@/store";

// export function adminNavigationGuard(to:any, from:any, next:any){
//     console.log("store",store.getters.userInfo.organisationType)
//     if(store.getters.userInfo.organisationType  == 10702){
//         console.log("admin Type if")
//         next('/bussiness')
//     } else{
//         console.log("admin Type else")
//         next() //navigate to admin as he was of type admin
//     }
// }

// export function organisationManagementNavigationGuard(to:any, from:any, next:any){
//     console.log("store",store.getters.userInfo.organisationType)
//     if(store.getters.userInfo.organisationType  == 10702){
//         console.log("organasation Type")
//         if(store.getters.userInfo.currentRole == 10208){
//             console.log("organasation user Role")
//             next('/bussiness')
//         } else{
//             console.log("organasation Not user Role")
//             next();
//         }
//     } else{
//         console.log("root Admin")
//         next('/information') //navigate to admin as he was of type admin
//     }
// }

// export function informationNavigationGuard(to:any, from:any, next:any){
//     console.log("store",store.getters.userInfo.organisationType)
//     if(store.getters.userInfo.organisationType  == 10702){
//         console.log("organasation Type")
//         if(store.getters.userInfo.currentRole == 10209 || store.getters.userInfo.currentRole == 10207){
//             next()
//         } else{
//             next('/mapping')
//         }
//     } else{
//         console.log("root Admin")
//         next('/information') //navigate to admin as he was of type admin
//     }
// }

export function securityOfficerNavigationGuard(to:any, from:any, next:any){
    if(store.getters.userInfo.organisationType  == 10702){
        console.log("organasation Type")
        if(store.getters.userInfo.currentRole == 10207 ){
            console.log("organasation Ciso Role")
            next()
        } else{
            console.log("organasation Not Ciso Role")
            next('/org/business');
        }
       
    } else{
        console.log("root Admin")
        next('/information') //navigate to admin as he was of type admin
    }
}
export function RiskAndSystemOwnersNavigationGuard(to:any, from:any, next:any){   
    if(store.getters.userInfo.organisationType  == 10702){
        console.log("organasation Type")
        if(store.getters.userInfo.currentRole == 10207 || store.getters.userInfo.currentRole == 10211 || store.getters.userInfo.currentRole == 10210){
            console.log("organasation above riskowner Role")
            next()
        } else{
            console.log("organasation info owner Role")
            next('/org/business');
        }
       
    } else{
        console.log("root Admin")
        next('/information') //navigate to admin as he was of type admin
    }
}
